import { Container } from './CardItemStyles';
import Icon_Coin from '../../assets/icons/coin.png';
import toast from 'react-hot-toast';
import { t } from 'i18next';

export interface CardItemProps {
  image: string;
  name: string;
  creator?: string;
  productId: string;
  rating?: string;
  hidden?: string;
}

export function CardItem({
  image,
  name,
  creator,
  rating,
  productId,
}: CardItemProps) {
  if (!productId) return;

  async function handleCopyPid() {
    try {
      await navigator.clipboard.writeText(productId);
      toast.success(t('service_copy_card_product'));
    } catch (error) {
      // //console.log(error);
    }
  }

  return (
    <Container>
      <img src={image} alt={name} />
      <div className="productInfo">
        {name && (
          <h3>
            {name.trim().length >= 17
              ? name.trim().substr(0, 18) + '...'
              : name.trim()}
          </h3>
        )}
        {/* {productId && (
          <div className="title-line">
            <svg
              onClick={handleCopyPid}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75"
              />
            </svg>
            <h3>{productId}</h3>
          </div>
        )} */}
        {/*  */}
        {productId && <p>{productId}</p>}
        {/* {creator && (
          <p>
            {creator.trim().length >= 17
              ? creator.trim().substr(0, 18) + '...'
              : creator.trim()}
          </p>
        )} */}
        <div className="productBtn">
          <a
            href={`https://pt.imvu.com/shop/product.php?products_id=${productId}`}
            target="_blank"
            rel="noreferrer"
          >
            Classic
          </a>
          <a
            href={`https://go.imvu.com/shop/product-${productId}`}
            target="_blank"
            rel="noreferrer"
          >
            Next
          </a>
          {/*  */}
          <svg
            onClick={handleCopyPid}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="size-6"
            style={{ marginTop: 7 }}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75"
            />
          </svg>
          {/*  */}
        </div>
        {/* <span className="price">
          <img src={Icon_Coin} alt="Coin" />
          {rating}
        </span> */}
      </div>
    </Container>
  );
}
