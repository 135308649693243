import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiChevronDown, FiMoon, FiSun } from 'react-icons/fi';

import { Container } from './HeaderStyles';

import LOGO from '../../assets/logos/logo_v1_ORIGIN.png';
import BRAZIL from '../../assets/flags/brazil.png';
import ENGLISH from '../../assets/flags/english.png';
import SPANISH from '../../assets/flags/spanish.png';
import INSTA from '../../assets/icons/instagram.png';

import WALLET_IMG from '../../assets/icons/wallet.png';

import { Link } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContexts';
import { ThemeContext } from '../../contexts/ThemeContexts';
import { LanguageContext } from '../../contexts/LanguageContext';

const FLAGS_ENABLED = [
  {
    language: 'en',
    img: ENGLISH,
  },
  {
    language: 'es',
    img: SPANISH,
  },
  {
    language: 'pt',
    img: BRAZIL,
  },
];

export function Header() {
  const { signed, user } = useContext(AuthContext);
  const { isDarkMode, toggleTheme } = useContext(ThemeContext);
  const { t } = useTranslation();
  const { language, changeLanguage } = useContext(LanguageContext);

  const [showFlags, setShowFlags] = useState(false);

  if (!language || !changeLanguage) {
    throw new Error(
      'useContext(LanguageContext) must be used within a LanguageProvider'
    );
  }

  return (
    <Container>
      <ul className="menuResponsive">
        <li>
          <Link to="/">
            <img id="logo" src={LOGO} alt="ExploreVu" />
          </Link>
        </li>
        {signed && (
          <li>
            <Link to="/account" id="tokenHeader">
              <div id="tokenBuyHeader">
                <img src={WALLET_IMG} alt="Wallet" />
                Tokens: {user.purchase_tokens}
              </div>
              <p id="premiumToken">
                * {user.subscription_tokens} Premium Tokens
              </p>
            </Link>
          </li>
        )}
      </ul>
      <ul className="menuResponsive">
        <li>
          <Link to="/">{t('header_home')}</Link>
        </li>
        <li>
          <Link to="/contact">{t('header_contact')}</Link>
        </li>
        {!signed ? (
          <li>
            <Link to="/sign-in">{t('header_vip')}</Link>
          </li>
        ) : (
          <li>
            <Link to="/account">{t('header_account')}</Link>
          </li>
        )}
        <li>
          <Link to="/plans">{t('plans_title')}</Link>
        </li>
      </ul>
      <ul className="flags menuResponsive">
        {!showFlags ? (
          <button className="flagDropDown" onClick={() => setShowFlags(true)}>
            {t('current_language') === 'en' && (
              <img
                src={ENGLISH}
                alt={t('current_language')}
                style={{ borderColor: '#ededed' }}
              />
            )}
            {t('current_language') === 'es' && (
              <img
                src={SPANISH}
                alt={t('current_language')}
                style={{ borderColor: '#ededed' }}
              />
            )}
            {t('current_language') === 'pt' && (
              <img
                src={BRAZIL}
                alt={t('current_language')}
                style={{ borderColor: '#ededed' }}
              />
            )}
            <p>{t('current_language')}</p>
            <FiChevronDown size={24} />
          </button>
        ) : (
          <>
            {FLAGS_ENABLED.map((flag, index) => (
              <img
                key={index}
                onClick={() => {
                  changeLanguage(flag.language as 'en' | 'es' | 'pt');
                  setShowFlags(false);
                }}
                src={flag.img}
                alt={flag.language}
                style={{
                  opacity: language === flag.language ? 1 : 0.7,
                  borderColor:
                    language === flag.language ? '#f49c14' : '#ededed',
                }}
              />
            ))}
          </>
        )}
        <a
          href="http://www.instagram.com/explore.vu"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={INSTA} alt="explore.vu" className="btnInsta" />
        </a>
        <span className="btnTheme" onClick={toggleTheme}>
          {isDarkMode ? <FiSun /> : <FiMoon color="#999" />}
        </span>
      </ul>
    </Container>
  );
}
