import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 30px 10px;
  margin: 0 auto;
  background: ${({ theme }) => theme.backgroundColor};

  div.title-area {
    display: flex;
    align-items: center;
    gap: 7px;
    margin-bottom: 30px;

    h2 {
      font-size: 1.75rem;
      font-weight: 500;
    }

    span {
      background: ${({ theme }) => theme.primary};
      height: 4px;
      width: 125px;
      border-radius: 2px;
    }
  }

  .inputArea {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    input {
      max-width: 86%;
      width: 100%;
      background: ${({ theme }) => theme.backgroundGray};
      border: 0;
      border-radius: 3px;
      height: 40px;
      padding: 0 10px;
      font-size: 1rem;
      font-weight: bold;
      color: ${({ theme }) => theme.textColor};
      outline: none;
    }

    button {
      height: 40px;
      max-width: 12%;
      width: 100%;
      border: 0;
      background: ${({ theme }) => theme.primary};
      border-radius: 3px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  div.loadArea {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .areaRooms {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .roomItem {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
    background: ${({ theme }) => theme.backgroundGray};
    border-radius: 5px;
    padding: 10px;

    .imgRoom {
      max-width: 300px;
      width: 100%;
      img {
        border-radius: 5px;
        max-width: 300px;
        width: 100%;
        height: 250px;
      }
      .photo {
        max-width: 200px;
        height: 200px;
        border: 3px solid #fff;
        margin-top: 10px;
        box-shadow: 1px 1px 15px solid ${({ theme }) => theme.backgroundGray};
      }
      p.lastDate {
        font-size: 0.75rem;
        font-weight: 500;
      }
    }

    .roomContent {
      max-width: 720px;
      width: 100%;

      .roomTitle {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        gap: 30px;
        border-bottom: 1px solid ${({ theme }) => theme.primary};
        padding-bottom: 10px;
        margin-bottom: 15px;

        .btnHeaderArea {
          display: flex;
          flex-direction: row;
          align-items: center;
          flex-wrap: wrap;
          gap: 10px;
          max-width: 275px;
          width: 100%;
        }

        p.totalParticipants {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 5px;
          font-size: 0.9rem;
          font-weight: bold;
          svg {
            width: 24px;
          }
        }

        button.btnOutfits {
          height: 40px;
          max-width: 90px;
          width: 100%;
          border: 0;
          background: ${({ theme }) => theme.backgroundColor};
          border-radius: 3px;
          color: ${({ theme }) => theme.textColor};
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
        }
      }

      .usersContent {
        /* display: flex;
        flex-direction: row;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 30px; */
        display: flex;
        flex-direction: column;
        height: 300px;
        overflow-y: scroll;

        .userRow:nth-child(odd) {
          background-color: ${({ theme }) => theme.backgroundColor};
        }

        .userRow:nth-child(even) {
          background-color: ${({ theme }) => theme.backgroundGray};
        }

        .userRow {
          display: flex;
          flex-direction: row;
          align-items: center;
          flex-wrap: wrap;
          justify-content: space-between;
          padding: 10px;
          border-radius: 4px;

          .avInfo {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 15px;

            img {
              width: 40px;
              height: 40px;
              border-radius: 20px;
              border: 2px solid ${({ theme }) => theme.primary};
            }
          }

          p {
            font-weight: bold;
            font-size: 1rem;
          }

          .btnsUser {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;

            a {
              background: none;
              padding: none;
              margin: none;
              color: ${({ theme }) => theme.textColor};
              text-decoration: underline;
              font-size: 0.75rem;
              font-weight: 400;
              cursor: pointer;
              transition: all ease 0.5s;
            }

            a:hover {
              color: ${({ theme }) => theme.primary};
              transition: all ease 0.5s;
            }
          }
        }
      }
    }
  }

  div.lineArea {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  h3 {
    font-size: 1.3rem;
    font-weight: 500;
  }

  a {
    background: ${({ theme }) => theme.primary};
    color: #fff;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 3px;
    text-decoration: none;
  }

  .areaPages {
    margin: 30px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 15px;
    width: 100%;

    p {
      font-size: 1rem;
    }

    button {
      width: 32px;
      height: 32px;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: ${({ theme }) => theme.primary};
      border: none;

      svg {
        color: #fff;
        font-size: 24px;
      }
    }
  }
`;

export const ModalOutfit = styled.div`
  width: 100%;
  height: 100%;
  padding: 30px 10px;
  margin: 0 auto;
  background: ${({ theme }) => theme.backgroundColor};
  position: relative;

  .imageAreaProfile {
    display: flex;
    flex-direction: column;
    gap: 10px;

    a {
      color: ${({ theme }) => theme.textColor};
    }
  }

  div.title-area {
    display: flex;
    align-items: center;
    gap: 7px;
    margin-bottom: 30px;

    h2 {
      font-size: 1.75rem;
      font-weight: 500;
    }

    span {
      background: ${({ theme }) => theme.primary};
      height: 4px;
      width: 125px;
      border-radius: 2px;
    }
  }

  .btnClose {
    font-size: 0.9rem;
    font-weight: bold;
    color: ${({ theme }) => theme.primary};
    border: none;
    background: ${({ theme }) => theme.backgroundGray};
    padding: 5px 10px;
    border-radius: 2px;
  }

  div.areaProfile {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 30px;

    img.profile {
      max-width: 225px;
      min-width: 225px;
      width: 100%;
      margin-top: 35px;
      box-shadow: 1px 1px 15px ${({ theme }) => theme.backgroundGray};
      border: 4px solid ${({ theme }) => theme.backgroundGray};
      border-radius: 5px;
      background: ${({ theme }) => theme.backgroundGray};
    }
  }

  div.areaButtonOutfit {
    max-width: 700px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    flex-wrap: wrap;
    margin: 30px 0 60px 0;
    //
    max-height: 600px;
    height: 100%;
    padding: 10px 10px 20px 10px;

    button {
      height: 40px;
      max-width: 100px;
      width: 100%;
      border: 0;
      background: ${({ theme }) => theme.primary};
      border-radius: 3px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
    }
  }

  div.loadArea {
    max-width: 700px;
    width: 100%;
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    margin: 30px 0 60px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div.areaCards {
    max-width: 700px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 30px;
    flex-wrap: wrap;
    margin: 30px 0 60px 0;
    //
    max-height: 600px;
    height: 100%;
    overflow-y: scroll;
    padding: 10px 10px 20px 10px;
  }
`;
