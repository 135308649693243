import { useContext, useState } from 'react';
import { FiLoader } from 'react-icons/fi';

import { useTranslation } from 'react-i18next';

import { Container } from './SignUpStyles';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContexts';
import toast from 'react-hot-toast';
import axios from 'axios';

export function SignUp() {
  const { signUp, loadingAuth } = useContext(AuthContext);
  const { t } = useTranslation();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  function isValidUsername(username) {
    const regex = /^[a-zA-Z0-9][a-zA-Z0-9._-]{0,29}$/;
    return regex.test(username);
  }

  function handleNameChange(e) {
    const newValue = e.target.value;
    if (newValue === '' || isValidUsername(newValue)) {
      setName(newValue);
    }
  }

  async function sendForm(event) {
    event.preventDefault();
    if (!name || !email || !password) {
      toast.error(t('auth_alert_required'));
      return;
    }

    if (!isValidUsername(name.trim())) return;

    await signUp({
      name: name.trim(),
      email: email.trim(),
      password,
    });
  }

  return (
    <Container>
      <div className="title-area">
        <h2>{t('signup_welcome')}</h2>
        <span></span>
      </div>

      <form onSubmit={sendForm} className="inputArea">
        <input
          type="text"
          value={name}
          onChange={handleNameChange}
          placeholder={t('signup_name')}
        />
        <p>* {t('signup_obs_username')}</p>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder={t('signin_email')}
        />
        <p>* {t('signup_obs_email')}</p>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder={t('signin_password')}
        />
        <p>* {t('signup_obs_password')}</p>
        <button
          type="submit"
          // onClick={() => sendForm()}
          disabled={loadingAuth}
        >
          {loadingAuth ? <FiLoader size={24} /> : t('signup_button_register')}
        </button>
        <p style={{ marginTop: 10, marginBottom: 10, fontSize: '0.8rem' }}>
          * {t('accept_terms')},{' '}
          <Link to="/terms-of-services">{t('view_terms')}</Link>
        </p>
        <Link to="/sign-in">{t('signup_have_account')}</Link>
      </form>
    </Container>
  );
}
