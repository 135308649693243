import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 30px 10px;
  margin: 0 auto;
  background: ${({ theme }) => theme.backgroundColor};

  div.title-area {
    display: flex;
    align-items: center;
    gap: 7px;
    margin-bottom: 30px;

    h2 {
      font-size: 1.75rem;
      font-weight: 500;
    }

    span {
      background: ${({ theme }) => theme.primary};
      height: 4px;
      width: 125px;
      border-radius: 2px;
    }
  }

  .inputArea {
    max-width: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 30px;
    gap: 10px;

    label {
      font-weight: bold;
      color: ${({ theme }) => theme.textColor};
      font-size: 0.9rem;
      margin-bottom: -5px;
    }

    input {
      max-width: 500px;
      width: 100%;
      background: ${({ theme }) => theme.backgroundGray};
      border: 0;
      border-radius: 3px;
      height: 40px;
      padding: 0 10px;
      font-size: 1rem;
      font-weight: bold;
      color: ${({ theme }) => theme.textColor};
      outline: none;
    }

    button {
      height: 40px;
      max-width: 500px;
      width: 100%;
      border: 0;
      background: ${({ theme }) => theme.primary};
      border-radius: 3px;
      color: ${({ theme }) => theme.textColor};
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 0;
      font-size: 16px;
      font-weight: bold;
    }

    button.btnSignOut {
      margin-top: -5px;
      background: transparent;
      border: 2px solid ${({ theme }) => theme.error};
      color: ${({ theme }) => theme.error};
    }

    a {
      color: ${({ theme }) => theme.primary};
    }
  }

  div.subscriptionArea {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    background: ${({ theme }) => theme.backgroundGray};
    border-radius: 3px;
    padding: 10px;
    margin-bottom: 10px;

    p {
      font-size: 0.9rem;

      span {
        font-size: 1rem;
      }
    }

    a {
      background: ${({ theme }) => theme.success};
      color: #fff;
      padding: 2px 5px;
      border-radius: 2px;
      font-size: 0.9rem;
      text-decoration: none;
    }
  }

  div.loadArea {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .areaContents {
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .purchaseArea {
    max-width: 500px;
    width: 100%;
    background: ${({ theme }) => theme.backgroundGray};
    border-radius: 5px;
    padding: 30px 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  h3 {
    color: ${({ theme }) => theme.primary};
    font-size: 1.5rem;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  p#subtitle {
    font-size: 1rem;
    max-width: 350px;
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
    color: ${({ theme }) => theme.textColor};
  }

  .btnArea {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 30px;
  }

  p.description {
    font-size: 0.75rem;
    margin-top: 30px;
    text-align: center;
  }

  select {
    max-width: 225px;
    width: 100%;
    background: ${({ theme }) => theme.backgroundGray};
    border: 0;
    border-radius: 3px;
    height: 40px;
    padding: 0 10px;
    font-size: 1rem;
    font-weight: bold;
    color: ${({ theme }) => theme.textColor};
    outline: none;
    margin: 10px 0 30px 0;
  }

  .areaAmount {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    button {
      width: 42px;
      height: 42px;
      border-radius: 21px;
      font-size: 2rem;
      background: ${({ theme }) => theme.primary};
      color: ${({ theme }) => theme.textColor};
      border: none;
    }
  }

  p#amount {
    font-size: 2rem;
  }

  button#btnPurchase {
    height: 40px;
    max-width: 500px;
    width: 100%;
    border: 0;
    background: ${({ theme }) => theme.primary};
    border-radius: 3px;
    color: ${({ theme }) => theme.textColor};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    margin-top: 30px;
  }
`;
