import styled from 'styled-components';

export const Container = styled.div`
  background: ${({ theme }) => theme.backgroundGray};
  padding: 10px;
  border: 2px;
  border-radius: 4px;
  box-shadow: 1px 1px 15px ${({ theme }) => theme.backgroundGray};
  max-width: 300px;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  gap: 15px;
  position: relative;

  .productInfo {
    width: 100%;
  }

  .title-line {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
  }

  svg {
    width: 24px;
    color: #999;
    cursor: pointer;
  }

  img {
    border-radius: 4px;
  }

  h3 {
    font-size: 0.9rem;
  }

  p {
    font-size: 0.8rem;
  }

  .productBtn {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  button {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  a {
    background: ${({ theme }) => theme.primary};
    font-size: 0.7rem;
    color: #fff;
    text-decoration: none;
    padding: 5px 10px;
    border-radius: 4px;
    margin-top: 10px;
    display: block;
    text-align: center;
  }

  span.price {
    position: absolute;
    bottom: 0;
    left: 0;
    background: ${({ theme }) => theme.backgroundColor};
    padding: 2px 5px;
    border-top-right-radius: 3px;
    font-weight: bold;
    font-size: 0.7rem;
    display: flex;
    align-items: center;

    img {
      width: 15px;
      margin-right: 3px;
    }
  }
`;
