import { useTranslation } from 'react-i18next';
import { Container } from './AboutStyles';

export function About() {
  const { t } = useTranslation();

  return (
    <Container>
      <div className="title-area">
        <h2>{t('about')}</h2>
        <span></span>
      </div>
      <p className="description">{t('about_1')}</p>
      <p className="description">* {t('about_2')}</p>
    </Container>
  );
}
