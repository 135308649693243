import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        current_language: 'en',
        language: 'Language',
        // CURRENCY
        currency_information:
          'The final amount will be automatically converted to your local currency at the time of payment, and additional exchange fees may apply. (Valid for some regions and currencies.) The final amount will be displayed at the time of payment, ensuring clarity about what is being charged.',
        // FORGOT PASSWORD
        forget_email_btn: 'Recover password',
        forgot_email_input: 'Enter your registered email',
        forgot_email_success: 'We have sent an email to reset your password',
        forgot_email_error: 'Check the email provided and try again',
        // RESET PASSWORD
        reset_password: 'Password Reset',
        reset_password_input: 'New Password',
        reset_password_confirm_input: 'Confirm your password',
        reset_password_btn: 'Confirm change',
        reset_password_success: 'Your password has been changed successfully',
        reset_password_error:
          'Invalid credentials! Try generating a new link and try again',
        // HEADER
        header_home: 'Home',
        header_about: 'About',
        header_contact: 'Contact',
        header_vip: 'Sign In',
        header_account: 'Account',
        // HOME
        alert_banner_message_title: 'Welcome to ExploreVu!',
        alert_banner_message_desc:
          'Our tools are community-driven, and we strive to bring you the best possible experience! ExploreVu is not affiliated with IMVU Inc. or Together Labs.',
        alert_banner_message_new:
          "We've added exclusive videos for Premium members. New BETA functionality available, we will do some interactions on Instagram for some feedback and contact with you, to further improve your experience on our platform. Follow us on Instagram to find out about the latest news @explore.vu",
        title_vip: 'Premium Services',
        title_public: 'Public Services',
        title_friend: 'Other Services',
        btn_access: 'Access',
        btn_shortly: 'Shortly',
        video_welcome: 'Welcome',
        video_content: 'Videos Content',
        video_content_desc: 'Access exclusive tips and tutorial content.',
        video_note:
          'Notes: All tests were conducted randomly, with the sole purpose of demonstrating the use of this feature in the tutorial.',
        video_plus:
          'New videos will be released over time, including content on new features and tips. There will always be updates as the platform evolves.',
        // ALERT MESSAGES
        service_screenshot: 'This post does not appear to be compatible',
        service_empty: 'This room is empty or not compatible',
        service_error:
          'We had an internal error, everything will be normal in a few minutes',
        alert_subscription:
          'You are trying to access a Premium feature! Subscribe to our platform now and have access to all our Premium services.',
        alert_subscription_plus:
          'You are trying to access a Premium Plus feature! Subscribe to our platform now and have access to all our Premium Plus services.',
        alert_logged: 'You need to access your account to continue',
        alert_premium: 'You must be a Premium subscriber to continue',
        // SERVICES
        service_copy_card_product: 'Product ID copied successfully',
        service_furniture_title: 'Room Furniture',
        service_furniture_description:
          'Find all items in rooms, whether live or classic',
        service_song_title: 'Current Media',
        service_song_is_mic: 'This room may/could be using voice service',
        service_song_description:
          'Find out who has a microphone, what song is playing or when it was last played',
        service_song_microfone_room: 'Microphones in the room',
        service_song_with_access: 'Active:',
        service_song_pendent: 'Pending',
        service_song_muted: 'Muted',
        service_room_history_title: 'Historical Room Viewer',
        service_room_history_description: 'View a rooms visit history',
        service_hidden_outfit_title: 'Hidden Outfit Viewer',
        service_hidden_outfit_description:
          'Get access to a users latest hidden outfits',
        service_avatarcard_title: 'Avatar Card',
        service_avatarcard_description: 'View a main users information',
        service_profileoutfit_title: 'Profile Outfit',
        service_profileoutfit_description:
          'Find out which items a user is using on their profile',
        service_wishlist_title: 'View Wishlist',
        service_wishlist_description: 'Check your friends wish list now',
        service_getroom_title: 'Show Room',
        service_getroom_description:
          'Find someones room in the store that you liked, whether live or classic',
        service_name_check_title: 'Check Name',
        service_name_check_description:
          'Quickly check if a name is available and if a user exists',
        service_posts_title: 'View Posts Interactions',
        service_posts_description:
          'Find out the most recent posts and interactions in your feed that a user has received',
        service_derivabletree_title: 'Derivable Tree',
        service_derivabletree_description:
          'View the derivation tree (history) of a product',
        service_lowercost_title: 'Lower Cost',
        service_lowercost_description:
          'Find derivative products at a lower cost',
        service_friend_title: 'Secret Santa',
        service_friend_description:
          'Create your friends list and exchange gifts anonymously',
        service_protect_title: 'Protect someone',
        service_protect_description:
          'Hide a users outfits and prevent their appearance from being accessed',
        service_protect_alert: 'This user is protected',
        service_friend_code_title: 'My Friend is...',
        service_friend_code_description:
          'Do you already have your code? Find out who your secret friend is (Secret Santa)',
        service_password_title: 'Password Generator',
        service_password_description:
          'Want a secure password? We will help you create one with what you need',
        service_symbol_title: 'Name with symbols',
        service_symbol_description:
          'Create your unique name with lots of style and special symbols',
        service_store_title: 'Vu Store',
        service_store_description:
          'Discover some of our products that are available for purchase',
        search_user_id: 'Search username',
        required_user_id: 'Enter the username',
        // ROOM FURNITURE
        search_room_id: 'Search room ID',
        required_room_id: 'Enter the room ID to continue',
        // CURRENT SONG
        btn_open_yt: 'Open on YouTube',
        status_playing: 'Playing',
        status_stopped: 'Stopped',
        date_hour: 'at',
        last_time: 'Last time',
        error_song:
          'Make sure the room ID is correct, or it doesnt have any records yet',
        error_scanner: 'We cannot find this user in any room',
        // AVATAR CARD
        age: 'Age',
        gender: 'Gender',
        country: 'Country',
        member_since: 'Member since',
        owner: 'Owner',
        join_the_room: 'Join',
        affinity: 'Affinity',
        relationship: 'Relationship',
        here_for: 'Here for',
        profile: 'Profile',
        visit_profile: 'Visit profile',
        last_online: 'Last online',
        // SANTA ROOM
        new_participant: 'New participant',
        min_participants: 'Must have at least 3 participants',
        santa_participant_exists: 'Already username, try again',
        santa_created: 'Created room successfully',
        room_name: 'Room name',
        santa_info: 'Room information',
        santa_me: 'Send to',
        santa_friend: 'took',
        santa_friend_error: 'Please check the name and code sent and try again',
        santa_copy_success: 'Information copied successfully, you can share',
        santa_copy:
          'the draw was successful! Check out your information below by accessing the link to see who your Secret Santa is',
        santa_obs:
          'Do you want to create a new room? Just create again and this room will be replaced. (*Click to copy the code)',
        santa_code: 'copy code',
        santa_code_room: 'room code',
        santa_add: 'Add',
        santa_create: 'Create room',
        // PASSWORD GENERATOR
        passgen_pass_use: 'Your password will use...',
        passgen_use_num: 'Use Numbers',
        passgen_use_uppercase: 'Use Uppercase',
        passgen_use_char_special: 'Use special characters',
        passgen_length: 'Password length:',
        passgen_generate: 'Generate now',
        passgen_subtitle: 'New Password:',
        passgen_description:
          'Each password is unique, generated exclusively for you! They are not saved in our system. (*Click on password to copy)',
        passgen_empty: 'Generate a password to copy',
        passgen_copy: 'Password copied successfully',
        // DERIVABLE TREE
        service_derivabletree_search: 'Search Product ID',
        service_derivabletree_alert: 'Product ID is required',
        // CHECK NAME
        service_name_check_message: 'This username already exists',
        service_name_check_no: 'This username does not exist',
        // Product User Hidden
        service_pid_hidden_not: 'Product is not a hidden item',
        service_pid_hidden_empty: 'No history found for this product',
        service_pid_hidden_info:
          'For an accurate analysis, you can consult the details of the users outfit in their history according to the date of use to confirm that they were equipped, the results show recently captured information, being constantly updated',
        service_pid_hidden_title: 'Hidden Product Monitoring',
        service_pid_hidden_desc:
          'Track the use of hidden products in public rooms. Ensure compliance with usage rules by monitoring only with the product ID (BETA version)',
        // History Duo Analysis
        service_duo_alert:
          'To see all participants and the full history of the room, you can search directly in the room history of one of the users',
        service_history_duo_title: 'History Duo Analysis',
        service_history_duo_desc:
          'Analyze interactions between two users and discover when and where they were together (BETA version)',
        // INSPECT ROOMS
        service_inspect_photo: 'Inspect photo',
        service_inspect_photo_desc:
          'Inspect posted photos and know all users present at that moment (Only for photos that are not screenshots)',
        service_inspect_classic_room: 'Inspect classic room',
        service_inspect_classic_room_desc:
          'See all outfit products from other users present in a classic room',
        service_inspect_live_room: 'Inspect live room',
        service_inspect_live_room_desc:
          'See all outfit products from other users present in a live room',
        // CURRENT OUTFIT
        service_current_outift_title: 'Current Outfit',
        service_current_outfit_description:
          'See what clothes an avatar is currently wearing',
        // HISTORY OUTFITS
        service_load_outfit: 'View Outfits',
        service_load_avatar_image:
          'Click on see outfits and/or wait for it to load',
        service_history_outfit_title: 'Outfit History',
        service_history_protection: 'This user is under protection',
        service_history_vip: 'This user is Premium',
        service_history_empty: 'We didnt find any history for this user',
        service_history_outfit_description:
          'See the history of clothes a user has worn',
        // LUCK POST
        service_lucky_post_title: 'Lucky post',
        service_lucky_post_description:
          'Run a comment giveaway directly from a post',
        // PRIVATE ROOM
        service_private_room_title: 'Private Room',
        service_private_room_description:
          'See where a user is! The user must be active at the moment, within their own room (does not work in live rooms)',
        // HISTORY CLASSIC ROOMS | LIVE ROOMS
        service_history_room_title: 'History Rooms',
        service_history_room_description:
          'View a users room history, whether classic or live rooms',
        serive_history_back_outfit: 'Return to history',
        serive_history_details_outfit:
          'Click the button below if you want to display all the products in this outfit',
        serive_history_more_outfit: 'See details',
        // ACTIVE CLASSIC ROOM
        service_active_classic_room_title: 'Classic Room Scanner',
        service_active_classic_room_description:
          'Automatically find for users in classic rooms',
        service_active_room_obs:
          'We will do a full scan of more than +10,000 (ten thousand) available public rooms, so this action may take a few minutes to complete! If the user is not located, this does not mean that they are not in the room, but that they could also be in a private room. If you know the users tastes, you can select a specific language for the room to reduce search time.',
        // ACTIVE CLASSIC ROOM
        service_active_live_room_title: 'Live Room Scanner',
        service_active_live_room_description:
          'Automatically find for users in live rooms',
        // SORT MESSAGES
        post_link: 'Post link',
        post_btn_empty: 'Clear all',
        post_btn_select: 'Select post',
        post_btn_view: 'View post',
        post_quant_win: 'How many winners: (5/1 token)',
        post_quant_comment: 'How many comments are on the post: (100/1 token)',
        post_quant_at: 'Up to',
        post_rule: 'Select the rule:',
        post_rule_opt_unique: 'Unique comment',
        post_rule_opt_mult: 'Each comment counts',
        post_rule_opt_unique_desc: 'Only 1 comment per user',
        post_rule_opt_mult_desc:
          'Allow all comments from a user to participate',
        post_obs_important:
          'Before starting the draw, make sure the selected post is correct! This action will use tokens for processing and is therefore irreversible.',
        post_btn_sort: 'Start draw',
        post_winners: 'Winners',
        // TOKENS
        tokens_empty: 'You dont have enough tokens',
        tokens_and_tray_again:
          'See if you have enough tokens or try again in a few minutes',
        tokens_and_tray_again_comments:
          'Check if you have enough tokens, the comment number is correct, or try again in a few minutes',
        token_buy_title: 'Buy Tokens',
        token_buy_sub:
          'Use tokens to enhance your experience and purchase extra resources.',
        token_buy_btn: 'Purchase',
        token_use: '1 token for use',
        token_use_more: '2 tokens for use',
        token_use_empty: 'token for use',
        token_buy: 'Buy 1 token for $1',
        token_buy_premium: 'Buy 2 tokens for $1',
        token_month: '10 tokens per month',
        token_month_plus: '20 tokens per month',
        token_redeem: 'Redeem Rewards',
        token_awards: 'Redeem rewards with Premium Tokens',
        token_awards_desc:
          'All subscription token (Premium Tokens) prize redemptions are final and non-refundable. Make sure you understand what you are redeeming before completing your request. Prices and availability of digital goods may change without notice.',
        token_disclaimer:
          'Tokens acquired through subscription (Premium Tokens) are cumulative and can be used exclusively to redeem prizes, in addition to their common use in features that require tokens. They are renewed monthly, according to the quantity specified in the active plan.',
        token_disclaimer_buy:
          'Tokens purchased separately are exclusive for the use of features that require the consumption of tokens.',
        // TOKEN REDEEM
        token_redeem_empty:
          'Soon, we will have new prizes and incredible values ​​available for redemption! (Premium Tokens)',
        // SPY USER PRIVATE ROOM
        create_update_spy_private:
          'When creating/updating the user, any data already registered will be removed, this is irreversible:',
        delete_spy_private:
          'Remove user? This will erase your entire history and is irreversible:',
        get_rooms_spy_private: 'View/update private room history listing?',
        start_rooms_spy_private:
          'Enable monitoring? This will resume user monitoring:',
        stop_rooms_spy_private:
          'Disable monitoring? This will pause monitoring for this user:',
        service_spy_rooms_private_obs:
          'The Private Room History feature allows you to add a user to be continuously monitored in your rooms. If the user is seen in a room accompanied by another person, their activity will be recorded, including information about the room, the participants present and their outfits. Additionally, you will receive an email notification whenever the monitored user is in the room with someone else. A unique history visible only to you will be created as user monitoring is carried out. If you delete the selected user, change your subscription to a lower plan, or cancel your subscription, the associated history will be deleted. (Does not work in live rooms)',
        service_spy_user_title: 'Private room history',
        service_spy_user_description:
          'Select a user, monitor their attendance in their private rooms and receive real-time notifications via email (does not work in live rooms)',
        service_spy_user_title_email: 'Private Room notification',
        service_apy_user_notification_obs: 'Sent if the user is with someone',
        service_apy_user_private_obs: 'Only for selected user rooms',
        // SANTA FRIEND
        santa_friend_subtitle: 'Your secret friend is...',
        santa_friend_username: 'Room code',
        santa_friend_code: 'Your secret code',
        santa_friend_message: 'your friend is',
        santa_friend_show_now: 'Show now',
        // CATALOG
        catalog_title: 'Catalog Viewer',
        catalog_description:
          'View our entire catalog of already registered products, including hidden and removed products',
        // SIGNED
        required_signed: 'Log in to your account to continue',
        // FOOTER
        privacy_policy: 'Privacy Policy',
        terms_service: 'Terms of Service',
        contact: 'Contact',
        // SIGN IN AND SIGN UP
        account_title: 'Account',
        error_login: 'Email/Password incorrect',
        success_register: 'Account created successfully',
        error_register: 'Check your credentials and try again',
        success_update: 'Updated successfully',
        error_update: 'Already username, try again',
        accept_terms: 'By registering you accept our terms of service',
        view_terms: 'see more.',
        signin_welcome: 'Access your account',
        signin_email: 'Email',
        signin_password: 'Password',
        signin_button_access: 'Access',
        signin_create_free_account: 'Create free account',
        signup_welcome: 'Create free account',
        signup_name: '@username',
        signup_have_account: 'I already have an account',
        signup_button_register: 'Create account',
        signup_obs_username: 'It will be used to personalize your experience',
        signup_obs_password: 'Attention, this is not your IMVU password',
        signup_obs_email: 'Attention, this is not your IMVU email',
        // ALERTS AUTH USERS
        auth_alert_required: 'Required fields',
        auth_alert_incorrect: 'Email/Password incorrect',
        // ACCOUNT TEXTS
        acount_subscription: 'Subscription',
        account_change_plan: 'Change plan',
        account_button_update: 'Save',
        account_button_signout: 'Sign Out',
        // PLANS TEXTS
        hidden_success_btn: 'Saved successfully',
        hidden_equals_btn: 'The user must be different from yours',
        hidden_error_btn:
          'An error occurred, please try again in a few minutes',
        hidden_btn_save: 'Save username',
        hidden_btn_delete: 'Delete username',
        hidden_title_card: 'Hide name in searches',
        hidden_title_protection:
          'When you become a VIP, your default name entered in your account will be protected in the room and outfit search system',
        hidden_description_protection:
          'Add a user who wants to add outfit protection',
        plan_premium_hidden_room: 'Hide room history',
        plan_premium_hidden_outfit: 'Hide outfit history',
        plan_premium_hidden_history_room:
          'Hide your name from the room history',
        plan_premium_hidden_history_outift:
          'Hide your outfit and that of another user',
        plan_shortly_btn: 'News soon',
        plan_free: 'Free',
        plans_no_ads: 'No ads',
        plans_all_free: 'Everything on the free plan',
        plans_all_premium: 'Everything from the Premium plan',
        plans_all_hidden: 'Stay hidden from all searches',
        plans_support: 'Exclusive support',
        plans_updates: 'Improvements and updates',
        plans_title: 'Plans',
        plans_subtitle:
          'Enjoy all Premium benefits (Functional tools for Live and Classic Rooms)',
        plans_plan_free: 'Free Plan',
        plans_plan_vip: 'Premium',
        plans_plan_vip_plus: 'Premium Plus',
        plans_plan_button: 'Go Premium',
        plans_plan_button_plus: 'Go Premium Plus',
        plans_plan_change_plan: 'Manage subscription',
        plans_plan_is_premium: 'You are already Premium',
        plans_plan_is_premium_plus: 'You are already Premium Plus',
        plans_premium_price_from: 'From ',
        plans_premium_price_from_plus: 'From ',
        // plans_premium_price_from: 'From $14.99',
        // plans_premium_price_from_plus: 'From $29.99',
        plans_premium_price_per: 'per',
        plans_premium_description:
          'When you become premium, you acquire all the features listed below according to the plan you choose, and you can cancel your subscription renewal whenever you want.',
        // TERMS OF SERVICES
        terms_tokens_premium: 'Subscription Tokens',
        terms_buy_tokens: 'Tokens Purchased Separately',
        terms_title: 'Terms of Service',
        terms_sub_01: 'Introduction',
        terms_desc_01:
          'Welcome to ExploreVu! These Terms of Service govern your use of our website, including any services and digital goods offered. By joining and participating in this website, you agree to comply with and be bound by these terms. ExploreVu is not affiliated with IMVU Inc. or Together Labs.',
        terms_sub_02: 'Eligibility',
        terms_desc_02:
          'You must be at least 18 years old to purchase digital goods from this website. By participating, you confirm that you meet this age requirement.',
        terms_sub_03: 'Account Responsibility',
        terms_desc_03:
          'You are responsible for maintaining the security of your account and for all activities that occur under your account. Please contact us immediately if you suspect any unauthorized use.',
        terms_sub_06: 'Purchasing Digital Goods',
        terms_desc_06:
          'All digital goods sales are final and non-refundable. Please make sure you understand what you are purchasing before completing a transaction. Prices and availability of digital goods may change without prior notice. We are not responsible for sales conducted outside our platform. In such cases, the buyer should contact the original seller directly to resolve any issues before reaching out to our support team.',
        terms_sub_07: 'Changing Plans (Upgrade and Downgrade)',
        terms_desc_07:
          'Upgrade: When upgrading your plan (selecting a higher plan), the additional amount will be automatically debited from the registered card, proportional to the remaining period of the current billing cycle. Changing your subscription will only be made after the charge has been successfully made.',
        terms_desc_07_1:
          'Downgrade: When you downgrade your plan (select a lower plan), Stripe will calculate the credit proportional to the unused period of your current plan. This credit will be automatically applied to future invoices. There will be no refund of the amount withheld; the amount will be used to cover future subscription costs until it is completely used up. Changing your subscription will only be made after the charge has been successfully made.',
        terms_sub_08: 'Payment',
        terms_desc_08:
          'Payments are processed through Hotmart. We do not store your payment information. Ensure your payment details are accurate to avoid transaction issues.',
        terms_sub_09: 'Prohibited Conduct',
        terms_desc_09:
          'No fraudulent activities or chargebacks are permitted. Do not share, resell, or distribute purchased digital goods without permission.',
        terms_sub_10: 'Intellectual Property',
        terms_desc_10:
          'All digital goods sold are the intellectual property of their respective creators. Unauthorized use, distribution, or reproduction is prohibited.',
        terms_sub_11: 'Disclaimer and Limitation of Liability',
        terms_desc_11:
          'Digital goods are provided "as is" without warranties of any kind. We are not liable for any damages resulting from the use or inability to use the digital goods. ExploreVu is not affiliated with IMVU Inc. or Together Labs.',
        terms_sub_12: 'Modifications to the Terms of Service',
        terms_desc_12:
          'We reserve the right to modify these terms at any time. Notice of changes will be posted through our contact means and sent via email. Continued use of the website constitutes acceptance of the modified terms.',
        terms_sub_13: 'Contact Information',
        terms_desc_13:
          'For any questions or concerns about these Terms of Service, please contact us via our contact details. When using our services, you agree to allow the recording of your information requested during registration for use purposes.',
        terms_sub_14: 'About Prices and Currency',
        terms_desc1_14:
          'The amount displayed on our platform is automatically adjusted to a locally accepted currency during the payment process. This means that, when you complete your purchase, Hotmart, our payment partner, will convert the displayed amount to the local currency associated with your payment method.',
        terms_desc2_14:
          'In this way, there may be additional exchange fees or external conversions, providing you with a more transparent and convenient payment experience. The final amount will be displayed at checkout, ensuring that you know exactly what is being charged in your local currency. (Valid for some regions and currencies)',
        // ABOUT
        about: 'About',
        about_1:
          'We are excited to offer our services to the entire community! Our platform is comprehensive and packed with features to ensure the best possible experience, whether youre using a computer or a mobile device. Add our site to your favorites and explore all the tools we have developed with dedication and care. Our team is always working to bring improvements and new features. Thank you for choosing ExploreVu! Welcome!',
        about_2:
          'Remembering that our site was created and is maintained exclusively by members of the community, making it clear that ExploreVu is not affiliated with IMVU Inc. or Together Labs.',
        copyright: '2024 ExploreVu Team. All rights reserved.',
        // CONTACT
        contact_0: 'Contact',
        contact_1:
          'Do you have any questions or would you like to speak to our team about something? Contact us now at contact@explorevu.com',
        contact_2:
          'Our response time is 1-2 business days, but we will do our best to get back to you as soon as possible.',
        // MAINTENANCE
        btn_maintenance: 'Maintenance',
        maintenance_service: '(We are optimizing this service)',
        maintenance:
          'We are working to improve our services! We are adding a new server to the system, updating payment methods, and introducing new features. Access will return to normal soon, once propagation is complete. We appreciate your support and for using our tools. For any questions, please contact us at contact@explorevu.com.',
      },
    },
    pt: {
      translation: {
        current_language: 'pt',
        language: 'Idioma',
        // CURRENCY
        currency_information:
          'O valor final será convertido automaticamente para a sua moeda local no momento do pagamento, e podem ocorrer taxas de câmbio adicionais. (Válido para algumas regiões e moedas.) O valor final será exibido no momento do pagamento, garantindo clareza sobre o que está sendo cobrado.',
        // FORGOT PASSWORD
        forget_email_btn: 'Recuperar senha',
        forgot_email_input: 'Informe seu email cadastrado',
        forgot_email_success: 'Enviamos um email para redefinição de senha',
        forgot_email_error: 'Verifique o email informado e tente novamente',
        // RESET PASSWORD
        reset_password: 'Redefinição de senha',
        reset_password_input: 'Nova senha',
        reset_password_confirm_input: 'Confirme sua senha',
        reset_password_btn: 'Confirmar alteração',
        reset_password_success: 'Sua senha foi alterada com sucesso',
        reset_password_error:
          'Credenciais inválidas! Tente gerar um novo link e tente novamente',
        // HEADER
        header_home: 'Início',
        header_about: 'Sobre',
        header_contact: 'Contato',
        header_vip: 'Login',
        header_account: 'Conta',
        // HOME
        alert_banner_message_title: 'Bem-vindo(a) ao ExploreVu!',
        alert_banner_message_desc:
          'Nossas ferramentas são mantidas pela comunidade e buscamos trazer a você a melhor experiência possível! O ExploreVu não é afiliado ao IMVU Inc. ou Together Labs.',
        alert_banner_message_new:
          'Adicionamos vídeos exclusivos para membros Premium. Nova funcionalidade BETA disponível, estaremos fazendo algumas interações no Instagram para alguns feedbacks e contato com vocês, para otimizar ainda mais sua experiência em nossa plataforma. Nos siga no Instagram para saber sobre as novidades @explore.vu',
        title_vip: 'Serviços Premium',
        title_public: 'Serviços Públicos',
        title_friend: 'Outros Serviços',
        btn_access: 'Acessar',
        btn_shortly: 'Em breve',
        video_welcome: 'Bem-vindo(a)',
        video_content: 'Vídeos Conteúdo',
        video_content_desc:
          'Acesse os conteúdos exclusivos de dicas e tutoriais.',
        video_note:
          'Observação: Todos os testes foram realizados de forma aleatória, com o único propósito de demonstrar a utilização deste recurso no tutorial.',
        video_plus:
          'Novos vídeos serão lançados ao longo do tempo, incluindo conteúdos sobre novas funcionalidades e dicas. Sempre haverá atualizações conforme a plataforma progride.',
        // ALERT MESSAGES
        service_screenshot: 'Está postagem parece não ser compatível',
        service_empty: 'Está sala está vazia ou não é compatível',
        service_error:
          'Tivemos um erro interno, tudo ficará normal em alguns minutos',
        alert_subscription:
          'Você está tentando acessar uma funcionalidade Premium! Assine já nossa plataforma e tenha acesso a todos os nossos serviços Premium.',
        alert_subscription_plus:
          'Você está tentando acessar uma funcionalidade Premium Plus! Assine já nossa plataforma e tenha acesso a todos os nossos serviços Premium Plus.',
        alert_logged: 'Você precisa acessar sua conta para continuar',
        alert_premium: 'Você deve ser um assinante Premium para continuar',
        // SERVICES
        service_copy_card_product: 'ID do produto copiado com sucesso',
        service_furniture_title: 'Items da Sala',
        service_furniture_description:
          'Encontre todos os items das salas, sejam elas ao vivo ou clássicas',
        service_song_title: 'Reprodução Atual',
        service_song_is_mic:
          'Esta sala pode/poderia estar usando serviço de voz',
        service_song_description:
          'Saiba quem esta com microfone, qual música está sendo reproduzida ou quando foi a última reprodução',
        service_song_microfone_room: 'Microfones na sala',
        service_song_with_access: 'Ativo:',
        service_song_pendent: 'Pendente',
        service_song_muted: 'Multado',
        service_room_history_title: 'Visualizador Histórico da Sala',
        service_room_history_description:
          'Veja o histórico de visitas de uma sala',
        service_hidden_outfit_title: 'Ver Roupas Ocultas',
        service_hidden_outfit_description:
          'Veja as roupas ocultas de um usuário',
        service_avatarcard_title: 'Cartão do Avatar',
        service_avatarcard_description:
          'Veja as principais informações de um usuário',
        service_profileoutfit_title: 'Roupa do Perfil',
        service_profileoutfit_description:
          'Descubra quais itens um usuário está usando em seu perfil',
        service_wishlist_title: 'Ver Lista de Desejos',
        service_wishlist_description:
          'Confira a lista de desejos dos seus amigos agora',
        service_getroom_title: 'Mostra Sala',
        service_getroom_description:
          'Encontre na loja a sala de alguém que você gostou, seja ela ao vivo ou clássica',
        service_name_check_title: 'Checar Nome',
        service_name_check_description:
          'Verifique rapidamente se um nome está disponível e se um usuário existe',
        service_posts_title: 'Postagens e Interações',
        service_posts_description:
          'Descubra as postagens e interações mais recentes em seu feed que um usuário recebeu',
        service_derivabletree_title: 'Árvore de Derivação',
        service_derivabletree_description:
          'Veja a árvore de derivação (histórico) de um produto',
        service_lowercost_title: 'Menor Custo',
        service_lowercost_description:
          'Encontre produtos derivados com menor custo',
        service_friend_title: 'Amigo Secreto',
        service_friend_description:
          'Crie sua lista de amigos e troque presentes de forma anônima',
        service_protect_title: 'Proteja alguém',
        service_protect_description:
          'Oculte os outfits de um usuário e impeça que suas aparências sejam acessadas',
        service_protect_alert: 'Este usuário está protegido',
        service_friend_code_title: 'Meu Amigo é...',
        service_friend_code_description:
          'Já tem o seu código? Descubra quem é seu amigo secreto (Amigo Secreto)',
        service_password_title: 'Gerador de Senha',
        service_password_description:
          'Quer uma senha segura? Vamos te ajudar a criar uma com o que você precisa',
        service_symbol_title: 'Nome com símbolos',
        service_symbol_description:
          'Crie seu nome exclusivo com muito estilo e símbolos especiais',
        service_store_title: 'Loja Vu',
        service_store_description:
          'Conheça alguns de nossos produtos que estão disponíveis para compra',
        search_user_id: 'Informe um nome de usuário',
        required_user_id: 'Informe um nome de usuário para continuar',
        // ROOM FURNITURE
        search_room_id: 'Procure pelo ID da sala',
        required_room_id: 'Informe o ID da sala para continuar',
        // CURRENT SONG
        btn_open_yt: 'Abrir no YouTube',
        status_playing: 'Tocando',
        status_stopped: 'Parado',
        date_hour: 'às',
        last_time: 'Última vez',
        error_song:
          'Verifique se o ID da sala está correto, ou então ela não tem nenhum registro ainda',
        error_scanner: 'Não localizamos este usuário em nenhuma sala',
        // AVATAR CARD
        age: 'Idade',
        gender: 'Gênero',
        country: 'País',
        member_since: 'Membro desde',
        owner: 'Dono',
        join_the_room: 'Entrar',
        affinity: 'Afinidade',
        relationship: 'Relacionamento',
        here_for: 'Aqui para',
        profile: 'Perfil',
        visit_profile: 'Visitar perfil',
        last_online: 'Última vez online',
        // SANTA ROOM
        new_participant: 'Novo participante',
        min_participants: 'Deve ter pelo menos 3 participantes',
        santa_participant_exists: 'Usuário já existe, tente novamente',
        santa_created: 'Sala criada com sucesso',
        room_name: 'Nome da sala',
        santa_info: 'Informações da sala',
        santa_me: 'Enviar para',
        santa_friend: 'tirei',
        santa_friend_error:
          'Verifique o nome e o código enviado e tente novamente',
        santa_copy_success:
          'Informações copiada com sucesso, pode compartilhar',
        santa_copy:
          'o sorteio foi feito com sucesso! Confira suas informações abaixo acessando o link para ver quem é seu amigo secreto',
        santa_obs:
          'Deseja criar uma nova sala? Basta criar novamente e esta sala será substituída. (*Clique para copiar o código)',
        santa_code: 'copiar código',
        santa_code_room: 'código da sala',
        santa_add: 'Adicionar',
        santa_create: 'Criar sala',
        // PASSWORD GENERATOR
        passgen_pass_use: 'Sua senha vai usar...',
        passgen_use_num: 'Usar Números',
        passgen_use_uppercase: 'Usar Maiúsculas',
        passgen_use_char_special: 'Usar Caracteres especiais',
        passgen_length: 'Tamanho da senha:',
        passgen_generate: 'Gerar agora',
        passgen_subtitle: 'Nova senha:',
        passgen_description:
          'Cada senha é única, gerada exclusivamente para você! Elas não são salvas em nosso sistema. (*Clique na senha para copiar)',
        passgen_empty: 'Gere uma senha para copiar!',
        passgen_copy: 'Senha copiada com sucesso!',
        // DERIVABLE TREE
        service_derivabletree_search: 'Procure pelo ID do produto',
        service_derivabletree_alert: 'O ID do produto é obrigatório',
        // CHECK NAME
        service_name_check_message: 'Este nome de usuário já existe',
        service_name_check_no: 'Este nome de usuário não existe',
        // Product User Hidden
        service_pid_hidden_not: 'Produto não é um item oculto',
        service_pid_hidden_empty: 'Não encontramos histórico deste produto',
        service_pid_hidden_info:
          'Para uma análise precisa, você pode consultar os detalhes do outfit do usuário no seu histórico de acordo com a data de uso do mesmo para confirmar que ele estava equipado, os resultados mostram informações capturadas recentemente, sendo constantemente atualizados',
        service_pid_hidden_title: 'Monitoramento de Produto Oculto',
        service_pid_hidden_desc:
          'Acompanhe o uso de produtos ocultos em salas públicas. Garanta o cumprimento das regras de uso, monitorando apenas com o ID do produto (versão BETA)',
        // History Duo Analysis
        service_duo_alert:
          'Para ver todos os participantes e o histórico completo da sala, você pode realizar a busca diretamente no histórico de salas de um dos usuários',
        service_history_duo_title: 'Análise de Histórico Duplo',
        service_history_duo_desc:
          'Analise as interações entre dois usuários e saiba quando e onde estiveram juntos (versão BETA)',
        // INSPECT ROOMS
        service_inspect_photo: 'Inspecionar fotos',
        service_inspect_photo_desc:
          'Inspecione as fotos postadas e saiba todos os usuários presentes naquele momento (somente para fotos que não sejam capturas de tela)',
        service_inspect_classic_room: 'Inspecionar sala clássica',
        service_inspect_classic_room_desc:
          'Veja todos os produtos de outfits de outros usuários presentes de uma sala clássica',
        service_inspect_live_room: 'Inspecionar sala ao vivo',
        service_inspect_live_room_desc:
          'Veja todos os produtos de outfits de outros usuários presentes de uma sala ao vivo',
        // CURRENT OUTFIT
        service_current_outift_title: 'Roupa atual',
        service_current_outfit_description:
          'Veja quais roupas um avatar está vestindo atualmente',
        // HISTORY OUTFITS
        service_load_outfit: 'Ver Outfits',
        service_load_avatar_image:
          'Clique em ver outfits e/ou aguarde carregar',
        service_history_outfit_title: 'Histórico de outfits',
        service_history_protection: 'Este usuário está sob proteção',
        service_history_vip: 'Este usuário é Premium',
        service_history_empty: 'Não encontramos nenhum histórico deste usuário',
        service_history_outfit_description:
          'Veja o histórico de roupas que um usuário utilizou',
        // LUCK POST
        service_lucky_post_title: 'Postagem da sorte',
        service_lucky_post_description:
          'Realize um sorteio de comentários diretamente de uma postagem',
        // PRIVATE ROOM
        service_private_room_title: 'Sala privada',
        service_private_room_description:
          'Veja onde um usuário está! O usuário deve estar ativo no momento, dentro de uma sala própria (não funciona em salas ao vivo)',
        // HISTORY CLASSIC ROOMS | LIVE ROOMS
        service_history_room_title: 'Histórico de salas',
        service_history_room_description:
          'Veja o histórico de salas de um usuário, sejam elas clássicas ou salas ao vivo',
        serive_history_back_outfit: 'Voltar ao histórico',
        serive_history_details_outfit:
          'Clique no botão abaixo caso deseje exibir todos os produtos desse outfit',
        serive_history_more_outfit: 'Ver detalhes',
        // ACTIVE CLASSIC ROOM
        service_active_classic_room_title: 'Scanner de Salas Clássicas',
        service_active_classic_room_description:
          'Encontre usuários automaticamente em salas clássicas',
        service_active_room_obs:
          'Faremos uma varredura completa em mais de +10.000 (dez mil) salas públicas disponíveis, por isso esta ação pode levar alguns minutos para ser finalizada! Caso o usuário não seja localizado isso não quer dizer que não esteja em sala, mas que também pode estar em alguma sala privada. Caso conheça os gostos do usuário, você pode selecionar um idioma específico da sala para reduzir o tempo de busca.',
        // ACTIVE CLASSIC ROOM
        service_active_live_room_title: 'Scanner de Salas Ao Vivo',
        service_active_live_room_description:
          'Encontre usuários automaticamente em salas ao vivo',
        // SORT MESSAGES
        post_link: 'Link da postagem',
        post_btn_empty: 'Limpar tudo',
        post_btn_select: 'Selecionar postagem',
        post_btn_view: 'Ver postagem',
        post_quant_win: 'Quantos vencedores: (5/1 token)',
        post_quant_comment: 'Quantos comentários há no post: (100/1 token)',
        post_quant_at: 'Até',
        post_rule: 'Selecione a regra:',
        post_rule_opt_unique: 'Comentário único',
        post_rule_opt_mult: 'Cada comentário conta',
        post_rule_opt_unique_desc: 'Somente 1 comentário por usuário',
        post_rule_opt_mult_desc:
          'Permitir que todos os comentários de um usuário participem',
        post_obs_important:
          'Antes de iniciar o sorteio, certifique-se de que a postagem selecionada é a correta! Esta ação usará tokens para processamento e, portanto, é irreversível.',
        post_btn_sort: 'Iniciar sorteio',
        post_winners: 'Vencedores',
        // TOKENS
        tokens_empty: 'Você não tem tokens suficientes',
        tokens_and_tray_again:
          'Veja se tem tokens suficientes ou tente novamente em alguns minutos',
        tokens_and_tray_again_comments:
          'Verifique se você tem tokens suficientes, se o número do comentário está correto ou tente novamente em alguns minutos',
        token_buy_title: 'Comprar Tokens',
        token_buy_sub:
          'Use tokens para aprimorar sua experiência e adquirir recursos extras.',
        token_buy_btn: 'Comprar',
        token_use: '1 token por uso',
        token_use_more: '2 tokens por uso',
        token_use_empty: 'token por uso',
        token_buy: 'Compre 1 token por $1',
        token_buy_premium: 'Compre 2 tokens por $1',
        token_month: '10 tokens por mês',
        token_month_plus: '20 tokens por mês',
        token_redeem: 'Resgate de prêmios',
        token_awards: 'Resgate de prêmios com Premium Tokens',
        token_awards_desc:
          'Todos os resgates de prêmios com tokens de assinatura (Premium Tokens) são finais e não reembolsáveis. Certifique-se de entender o que está resgatando antes de concluir a solicitação. Os preços e a disponibilidade dos bens digitais podem mudar sem aviso prévio.',
        token_disclaimer:
          'Tokens adquiridos por meio de assinatura (Premium Tokens) são acumulativos e podem ser usados exclusivamente para resgatar prêmios, além de seu uso comum em funcionalidades que exigem tokens. Eles são renovados mensalmente, conforme a quantidade especificada no plano ativo.',
        token_disclaimer_buy:
          'Tokens comprados separadamente são exclusivos para a utilização de funcionalidades que requerem o consumo de tokens.',
        // TOKEN REDEEM
        token_redeem_empty:
          'Em breve, teremos novos prêmios e valores incríveis disponíveis para resgate! (Premium Tokens)',
        // SPY USER PRIVATE ROOM
        create_update_spy_private:
          'Ao criar/atualizar o usuário, todos os dados já cadastrados serão removidos, isso é irreversível:',
        delete_spy_private:
          'Remover usuário? Isso apagará todo o seu histórico e é irreversível:',
        get_rooms_spy_private:
          'Ver/atualizar listagem de histórico das salas privadas?',
        start_rooms_spy_private:
          'Ativar monitoramento? Isso vai retomar o monitoramento do usuário:',
        stop_rooms_spy_private:
          'Desativar monitoramento? Isso pausará o monitoramento deste usuário:',
        service_spy_rooms_private_obs:
          'O recurso de Histórico de Salas Privadas permite que você adicione um usuário para ser monitorado continuamente em suas salas. Se o usuário for visto em uma sala acompanhado por outra pessoa, sua atividade será registrada, incluindo informações sobre a sala, os participantes presentes e seus outfits. Além disso, você receberá uma notificação por email sempre que o usuário monitorado estiver na sala com outra pessoa. Um histórico exclusivo e visível apenas para você será criado à medida que o monitoramento do usuário for realizado. Se você excluir o usuário selecionado, mudar sua assinatura para um plano inferior ou cancelar sua assinatura, o histórico associado será apagado. (Não funciona em salas ao vivo)',
        service_spy_user_title: 'Histórico de sala privada',
        service_spy_user_description:
          'Selecione um usuário, monitore sua ida em suas salas privadas e receba notificações em tempo real por email (não funciona em salas ao vivo)',
        service_spy_user_title_email: 'Notificação Sala Privada',
        service_apy_user_notification_obs:
          'Enviado caso o usuário esteja com alguém',
        service_apy_user_private_obs:
          'Somente para salas do usuário selecionado',
        // SANTA FRIEND
        santa_friend_subtitle: 'Seu amigo secreto é...',
        santa_friend_username: 'Código da sala',
        santa_friend_code: 'Seu código secreto',
        santa_friend_message: 'seu amigo é',
        santa_friend_show_now: 'Mostrar agora',
        // CATALOG
        catalog_title: 'Visualizador de catálogo',
        catalog_description:
          'Veja todo o nosso catálogo de produtos já cadastrados, incluindo produtos ocultos e removidos',
        // SIGNED
        required_signed: 'Faça login em sua conta para continuar',
        // FOOTER
        terms_service: 'Termos de Serviço',
        contact: 'Contato',
        // SIGN IN AND SIGN UP
        account_title: 'Conta',
        error_login: 'E-mail/senha incorretos',
        success_register: 'Conta criada com sucesso',
        error_register: 'Verifique suas credenciais e tente novamente',
        success_update: 'Atualizado com sucesso',
        error_update: 'Nome de usuário em uso, tente novamente',
        accept_terms: 'Cadastrando-se você aceita os nossos termos de serviços',
        view_terms: 'veja mais.',
        signin_welcome: 'Acesse sua conta',
        signin_email: 'Email de acesso',
        signin_password: 'Senha de acesso',
        signin_button_access: 'Acessar',
        signin_create_free_account: 'Criar conta gratuita',
        signup_welcome: 'Criar conta gratuita',
        signup_name: '@usuario',
        signup_have_account: 'Já tenho uma conta',
        signup_button_register: 'Criar conta',
        signup_obs_username: 'Será utilizado para personalizar sua experiência',
        signup_obs_password: 'Atenção, essa não é sua senha IMVU',
        signup_obs_email: 'Atenção, esse não é seu email IMVU',
        // ALERTS AUTH USERS
        auth_alert_required: 'Campos obrigatórios',
        auth_alert_incorrect: 'Email/Senha incorreto',
        // ACCOUNT TEXTS
        acount_subscription: 'Assinatura',
        account_change_plan: 'Mudar plano',
        account_button_update: 'Salvar',
        account_button_signout: 'Sair',
        // PLANS TEXTS
        hidden_success_btn: 'Salvo com sucesso',
        hidden_equals_btn: 'O usuário deve ser diferente do seu',
        hidden_error_btn:
          'Aconteceu um erro, tente novamente em alguns minutos',
        hidden_btn_save: 'Salvar username',
        hidden_btn_delete: 'Deletar username',
        hidden_title_card: 'Ocultar nome nas buscas',
        hidden_title_protection:
          'Ao ser VIP seu nome padrão informado na sua conta passa a ser protegido no sistema de buscas de salas e outfits',
        hidden_description_protection:
          'Adicione um usuário que deseje adicionar proteção de outfit',
        plan_premium_hidden_room: 'Esconder histórico de salas',
        plan_premium_hidden_outfit: 'Esconder histórico de outfits',
        plan_premium_hidden_history_room:
          'Esconda seu nome do histórico de salas',
        plan_premium_hidden_history_outift:
          'Esconda o seu outfit e o de outro usuário',
        plan_shortly_btn: 'Novidades em breve',
        plan_free: 'Grátis',
        plans_no_ads: 'Sem anúncios',
        plans_all_free: 'Tudo do plano grátis',
        plans_all_premium: 'Tudo do plano Premium',
        plans_all_hidden: 'Fique oculto de todas as buscas',
        plans_support: 'Suporte exclusivo',
        plans_updates: 'Melhorias e atualizações',
        plans_title: 'Planos',
        plans_subtitle:
          'Aproveite todos os benefícios Premium (Ferramentas funcionais para salas ao vivo e clássicas)',
        plans_plan_free: 'Plano Grátis',
        plans_plan_vip: 'Premium',
        plans_plan_vip_plus: 'Premium Plus',
        plans_plan_button: 'Virar Premium',
        plans_plan_button_plus: 'Virar Premium Plus',
        plans_plan_change_plan: 'Gerenciar assinatura',
        plans_plan_is_premium: 'Você já é Premium',
        plans_plan_is_premium_plus: 'Você já é Premium Plus',
        plans_premium_price_from: 'De ',
        plans_premium_price_from_plus: 'De ',
        // plans_premium_price_from: 'De $14.99',
        // plans_premium_price_from_plus: 'De $29.99',
        plans_premium_price_per: 'por',
        plans_premium_description:
          'Ao tornar-se premium, você adquire todas as funcionalidades listadas abaixo de acordo com o plano escolhido, podendo cancelar a renovação da sua assinatura quando quiser.',
        // TERMS OF SERVICES
        terms_tokens_premium: 'Tokens por Assinatura',
        terms_buy_tokens: 'Tokens Comprados Separadamente',
        terms_title: 'Termos de serviços',
        terms_sub_01: 'Introdução',
        terms_desc_01:
          'Bem-vindo(a) ao ExploreVu! Estes Termos de Serviço regem o uso do nosso site, incluindo quaisquer serviços e bens digitais oferecidos. Ao se cadastrar e participar deste site, você concorda em cumprir e ser vinculado a estes termos. O ExploreVu não é afiliado ao IMVU Inc. ou Together Labs.',
        terms_sub_02: 'Elegibilidade',
        terms_desc_02:
          'Você deve ter pelo menos 18 anos para comprar bens digitais deste site. Ao participar, você confirma que atende a este requisito de idade.',
        terms_sub_03: 'Responsabilidade da Conta',
        terms_desc_03:
          'Você é responsável por manter a segurança da sua conta e por todas as atividades que ocorram sob sua conta. Entre em contato conosco imediatamente se suspeitar de qualquer uso não autorizado.',
        terms_sub_06: 'Compra de Bens Digitais',
        terms_desc_06:
          'Todas as vendas de bens digitais são finais e não reembolsáveis. Certifique-se de entender o que está comprando antes de concluir uma transação. Os preços e a disponibilidade dos bens digitais podem mudar sem aviso prévio. Não nos responsabilizamos por vendas realizadas fora da nossa plataforma. Nesses casos, o comprador deve entrar em contato diretamente com o vendedor original para resolver quaisquer problemas antes de acionar o nosso suporte.',
        terms_sub_07: 'Alteração de Planos (Upgrade e Downgrade)',
        terms_desc_07:
          'Upgrade: Ao realizar o upgrade do seu plano (selecionar um plano superior), o valor adicional será debitado automaticamente no cartão cadastrado, proporcional ao período restante do ciclo de faturamento atual. A alteração da sua assinatura será efetuada somente após a cobrança ser realizada com sucesso.',
        terms_desc_07_1:
          'Downgrade: Ao realizar o downgrade do seu plano (selecionar um plano inferior), o Stripe calculará o crédito proporcional ao período não utilizado do seu plano atual. Esse crédito será aplicado automaticamente em futuras faturas. Não haverá reembolso do valor retido; o valor será usado para cobrir custos de assinaturas futuras até ser completamente esgotado. A alteração da sua assinatura será efetuada somente após a cobrança ser realizada com sucesso.',
        terms_sub_08: 'Pagamento',
        terms_desc_08:
          'Os pagamentos são processados pela Hotmart. Não armazenamos suas informações de pagamento. Certifique-se de que os detalhes do pagamento estejam corretos para evitar problemas de transação.',
        terms_sub_09: 'Conduta Proibida',
        terms_desc_09:
          'Não são permitidas atividades fraudulentas ou estornos. Não compartilhe, revenda ou distribua bens digitais comprados sem permissão.',
        terms_sub_10: 'Propriedade Intelectual',
        terms_desc_10:
          'Todos os bens digitais vendidos são propriedade intelectual de seus respectivos criadores. O uso não autorizado, distribuição ou reprodução é proibido.',
        terms_sub_11:
          'Isenção de Responsabilidade e Limitação de Responsabilidade',
        terms_desc_11:
          'Os bens digitais são fornecidos "como estão", sem garantias de qualquer tipo. Não nos responsabilizamos por quaisquer danos resultantes do uso ou da incapacidade de usar os bens digitais. O ExploreVu não é afiliado ao IMVU Inc. ou Together Labs.',
        terms_sub_12: 'Modificações nos Termos de Serviços',
        terms_desc_12:
          'Reservamo-nos o direito de modificar estes termos a qualquer momento. Avisos sobre mudanças serão informados por nossos meios de contato e enviados por e-mail. O uso contínuo do site constitui aceitação dos termos modificados.',
        terms_sub_13: 'Informações de Contato',
        terms_desc_13:
          'Para quaisquer perguntas ou preocupações sobre estes Termos de Serviço entre em contato conosco por nossos meios de contato. Ao usar nossos serviços, você concorda em permitir o registro das suas informações pedidas no cadastro para fins de uso.',
        terms_sub_14: 'Sobre os Valores e Moeda',
        terms_desc1_14:
          'O valor exibido em nossa plataforma é automaticamente ajustado para uma moeda localmente aceita durante o processo de pagamento. Isso significa que, ao concluir sua compra, a Hotmart, nosso parceiro de pagamentos, converterá o valor exibido para a moeda local associada ao seu método de pagamento.',
        terms_desc2_14:
          'Dessa forma, pode haver taxas adicionais de câmbio ou conversões externas, proporcionando a você uma experiência de pagamento mais transparente e conveniente. O valor final será exibido no checkout, garantindo que você saiba exatamente o que está sendo cobrado em sua moeda local. (Válido para algumas regiões e moedas)',
        // ABOUT
        about: 'Sobre',
        about_1:
          'Estamos felizes em oferecer nossos serviços para toda a comunidade! Nossa plataforma é completa e repleta de funcionalidades para garantir a melhor experiência possível, seja no computador ou em dispositivos móveis. Adicione nosso site aos seus favoritos e explore todas as ferramentas que desenvolvemos com dedicação e cuidado. Nossa equipe está sempre empenhada em trazer melhorias e novidades. Agradecemos por escolher o ExploreVu! Seja bem-vindo!',
        about_2:
          'Lembrando que nosso site foi feito e é mantido exclusivamente por membros da comunidade, deixando claro que o ExploreVu não é afiliado ao IMVU Inc. ou Together Labs.',
        copyright: '2024 Equipe ExploreVu. Todos os direitos reservados.',
        // CONTACT
        contact_0: 'Contato',
        contact_1:
          'Ficou com alguma dúvida ou gostaria de falar com nosso time sobre algum assunto? Entre em contato conosco agora mesmo pelo email contact@explorevu.com',
        contact_2:
          'Nosso tempo de resposta é de 1-2 dias comerciais, mas faremos o possível para responder você o quanto antes.',
        // MAINTENANCE
        btn_maintenance: 'Manutenção',
        maintenance_service: '(Estamos otimizando este serviço)',
        maintenance:
          'Estamos trabalhando para melhorar nossos serviços! Estamos adicionando um novo servidor ao sistema, atualizando os meios de pagamento e trazendo novas funcionalidades. Em breve, o acesso será normalizado, assim que a propagação estiver completa. Agradecemos pelo seu apoio e por utilizar nossas ferramentas. Para qualquer dúvida, entre em contato conosco pelo email contact@explorevu.com.',
      },
    },
    es: {
      translation: {
        current_language: 'es',
        language: 'Idioma',
        // CURRENCY
        currency_information:
          'El monto final se convertirá automáticamente a su moneda local en el momento del pago, y pueden aplicarse tarifas adicionales de cambio. (Válido para algunas regiones y monedas). El monto final se mostrará en el momento del pago, garantizando claridad sobre lo que se está cobrando.',
        // FORGOT PASSWORD
        forget_email_btn: 'Recuperar contraseña',
        forgot_email_input: 'Ingrese su correo electrónico registrado',
        forgot_email_success:
          'Hemos enviado un correo electrónico para restablecer su contraseña',
        forgot_email_error:
          'Verifique el correo electrónico proporcionado y vuelva a intentarlo',
        // RESET PASSWORD
        reset_password: 'Restablecimiento de contraseña',
        reset_password_input: 'Nueva contraseña',
        reset_password_confirm_input: 'Confirmar la contraseña',
        reset_password_btn: 'Confirmar cambio',
        reset_password_success: 'tu contraseña ha sido cambiada exitosamente',
        reset_password_error:
          '¡Credenciales no válidas! Intenta generar un nuevo enlace y vuelve a intentarlo',
        // HEADER
        header_home: 'Inicio',
        header_about: 'Acerca de',
        header_contact: 'Contacto',
        header_vip: 'Iniciar sesión',
        header_account: 'Cuenta',
        // HOME
        alert_banner_message_title: '¡Bienvenido(a) a ExploreVu!',
        alert_banner_message_desc:
          'Nuestras herramientas son mantenidas por la comunidad y buscamos brindarle la mejor experiencia posible! ExploreVu no está afiliado a IMVU Inc. o Together Labs.',
        alert_banner_message_new:
          'Hemos agregado videos exclusivos para miembros Premium. Nueva funcionalidad BETA disponible, realizaremos algunas interacciones en Instagram para recibir comentarios y contactarnos con usted, para mejorar aún más su experiencia en nuestra plataforma. Síguenos en Instagram para enterarte de las últimas novedades @explore.vu',
        title_vip: 'Servicios Premium',
        title_public: 'Servicios Públicos',
        title_friend: 'Otros Servicios',
        btn_access: 'Acceder',
        btn_shortly: 'Próximamente',
        video_welcome: 'Bienvenido(a)',
        video_content: 'Vídeos Contenido',
        video_content_desc:
          'Accede a los contenidos exclusivos de consejos y tutoriales.',
        video_note:
          'Nota: Todas las pruebas se realizaron de forma aleatoria, con el único propósito de demostrar el uso de esta funcionalidad en el tutorial.',
        video_plus:
          'Se lanzarán nuevos videos con el tiempo, incluyendo contenido sobre nuevas funcionalidades y consejos. Siempre habrá actualizaciones a medida que la plataforma progrese.',
        // ALERT MESSAGES
        service_screenshot: 'Esta publicación no parece ser compatible',
        service_empty: 'Esta sala está vacía o no es compatible',
        service_error:
          'Tuvimos un error interno, todo será normal en unos minutos.',
        alert_subscription:
          '¡Estás intentando acceder a una función Premium! Suscríbete ahora a nuestra plataforma y ten acceso a todos nuestros servicios Premium.',
        alert_subscription_plus:
          '¡Estás intentando acceder a una función Premium Plus! Suscríbete ahora a nuestra plataforma y ten acceso a todos nuestros servicios Premium Plus.',
        alert_logged: 'Necesitas iniciar sesión para continuar',
        alert_premium: 'Debes ser un suscriptor Premium para continuar',
        // SERVICES
        service_copy_card_product: 'ID del producto copiado correctamente',
        service_furniture_title: 'Elementos de la Sala',
        service_furniture_description:
          'Encuentre todos los artículos en las habitaciones, ya sean vivos o clásicos',
        service_song_title: 'Reproducción Actual',
        service_song_is_mic:
          'Esta sala puede/podría estar usando servicio de voz',
        service_song_description:
          'Descubre quién está usando el micrófono, qué música se está reproduciendo o cuándo fue la última reproducción',
        service_song_microfone_room: 'Micrófonos en la sala',
        service_song_with_access: 'Activo:',
        service_song_pendent: 'Pendiente',
        service_song_muted: 'Silenciado',
        service_room_history_title: 'Historial de la Sala',
        service_room_history_description:
          'Ve el historial de visitas de una sala',
        service_hidden_outfit_title: 'Ver Ropas Ocultas',
        service_hidden_outfit_description: 'Ve las ropas ocultas de un usuario',
        service_avatarcard_title: 'Tarjeta del Avatar',
        service_avatarcard_description:
          'Ve la información principal de un usuario',
        service_profileoutfit_title: 'Ropa del Perfil',
        service_profileoutfit_description:
          'Descubre qué elementos está usando un usuario en su perfil',
        service_wishlist_title: 'Ver Lista de Deseos',
        service_wishlist_description:
          'Consulta la lista de deseos de tus amigos ahora',
        service_getroom_title: 'Mostrar Sala',
        service_getroom_description:
          'Encuentra en la tienda la sala de alguien que te gustó, sea en vivo o clásica',
        service_name_check_title: 'Verificar Nombre',
        service_name_check_description:
          'Verifica rápidamente si un nombre está disponible y si un usuario existe',
        service_posts_title: 'Publicaciones e Interacciones',
        service_posts_description:
          'Descubre las publicaciones e interacciones más recientes en tu feed que ha recibido un usuario',
        service_derivabletree_title: 'Árbol de Derivación',
        service_derivabletree_description:
          'Ve el árbol de derivación (historial) de un producto',
        service_lowercost_title: 'Menor Costo',
        service_lowercost_description:
          'Encuentra productos derivados con menor costo',
        service_friend_title: 'Amigo Secreto',
        service_friend_description:
          'Crea tu lista de amigos e intercambia regalos de forma anónima',
        service_protect_title: 'proteger a alguien',
        service_protect_description:
          'Ocultar la vestimenta de un usuario y evitar que se acceda a su apariencia',
        service_protect_alert: 'Este usuario está protegido',
        service_friend_code_title: 'Mi Amigo es...',
        service_friend_code_description:
          '¿Ya tienes tu código? Descubre quién es tu amigo secreto (Amigo Secreto)',
        service_password_title: 'Generador de Contraseñas',
        service_password_description:
          '¿Quieres una contraseña segura? Te ayudaremos a crear una con lo que necesitas',
        service_symbol_title: 'Nombre con Símbolos',
        service_symbol_description:
          'Crea tu nombre exclusivo con mucho estilo y símbolos especiales',
        service_store_title: 'Tienda Vu',
        service_store_description:
          'Conoce algunos de nuestros productos que están disponibles para compra',
        search_user_id: 'Proporciona un nombre de usuario',
        required_user_id: 'Proporciona un nombre de usuario para continuar',
        // ROOM FURNITURE
        search_room_id: 'Busca por el ID de la sala',
        required_room_id: 'Proporciona el ID de la sala para continuar',
        // CURRENT SONG
        btn_open_yt: 'Abrir en YouTube',
        status_playing: 'Reproduciendo',
        status_stopped: 'Detenido',
        date_hour: 'a las',
        last_time: 'Última vez',
        error_song:
          'Verifica si el ID de la sala es correcto, o bien no tiene ningún registro todavía',
        error_scanner: 'No hemos localizado a este usuario en ninguna sala',
        // AVATAR CARD
        age: 'Edad',
        gender: 'Género',
        country: 'País',
        member_since: 'Miembro desde',
        owner: 'Propietario',
        join_the_room: 'Entrar',
        affinity: 'Afinidad',
        relationship: 'Relación',
        here_for: 'Aquí para',
        profile: 'Perfil',
        visit_profile: 'Visitar perfil',
        last_online: 'Última vez en línea',
        // SANTA ROOM
        new_participant: 'Nuevo participante',
        min_participants: 'Debe haber al menos 3 participantes',
        santa_participant_exists: 'El usuario ya existe, intenta nuevamente',
        santa_created: 'Sala creada con éxito',
        santa_friend_error:
          'Por favor verifique el nombre y el código enviado y vuelva a intentarlo',
        room_name: 'Nombre de la sala',
        santa_info: 'Información de la sala',
        santa_me: 'Enviar a',
        santa_friend: 'tengo',
        santa_copy_success: 'Información copiada con éxito, puedes compartir',
        santa_copy:
          '¡El sorteo se ha realizado con éxito! Consulta tu información a continuación accediendo al enlace para ver quién es tu amigo secreto',
        santa_obs:
          '¿Deseas crear una nueva sala? Basta con crear nuevamente y esta sala será reemplazada. (*Haz clic para copiar el código)',
        santa_code: 'copiar código',
        santa_code_room: 'código de habitación',
        santa_add: 'Añadir',
        santa_create: 'Crear sala',
        // PASSWORD GENERATOR
        passgen_pass_use: 'Tu contraseña usará...',
        passgen_use_num: 'Usar Números',
        passgen_use_uppercase: 'Usar Mayúsculas',
        passgen_use_char_special: 'Usar Caracteres especiales',
        passgen_length: 'Tamaño de la contraseña:',
        passgen_generate: 'Generar ahora',
        passgen_subtitle: 'Nueva contraseña:',
        passgen_description:
          'Cada contraseña es única, generada exclusivamente para ti. No se guardan en nuestro sistema. (*Haz clic en la contraseña para copiar)',
        passgen_empty: '¡Genera una contraseña para copiar!',
        passgen_copy: '¡Contraseña copiada con éxito!',
        // DERIVABLE TREE
        service_derivabletree_search: 'Busca por el ID del producto',
        service_derivabletree_alert: 'El ID del producto es obligatorio',
        // CHECK NAME
        service_name_check_message: 'Este nombre de usuario ya existe',
        service_name_check_no: 'Este nombre de usuario no existe',
        // Product User Hidden
        service_pid_hidden_not: 'El producto no es un artículo oculto',
        service_pid_hidden_empty: 'No encontramos historial de este producto',
        service_pid_hidden_info:
          'Para un análisis preciso, se puede consultar el detalle de la vestimenta del usuario en su historial según la fecha de uso para confirmar que estaba equipado, los resultados muestran información capturada recientemente, siendo actualizada constantemente',
        service_pid_hidden_title: 'Monitoreo de Producto Oculto',
        service_pid_hidden_desc:
          'Realice un seguimiento del uso de productos ocultos en espacios públicos. Garantice el cumplimiento de las reglas de uso monitoreando solo con el ID del producto (versión BETA)',
        // History Duo Analysis
        service_duo_alert:
          'Para ver todos los participantes y el historial completo de la sala, puedes buscar directamente en el historial de salas de uno de los usuarios',
        service_history_duo_title: 'History Duo Análisis',
        service_history_duo_desc:
          'Analiza las interacciones entre dos usuarios y descubre cuándo y dónde estuvieron juntos (versión BETA)',
        // INSPECT ROOMS
        service_inspect_photo: 'Inspeccionar fotos',
        service_inspect_photo_desc:
          'Inspeccionar las fotos publicadas y conocer a todos los usuarios presentes en ese momento (solo para fotos que no son capturas de pantalla)',
        service_inspect_classic_room: 'Inspeccionar la habitación clásica.',
        service_inspect_classic_room_desc:
          'Ver todos los productos de vestimenta de otros usuarios presentes en una sala clásica',
        service_inspect_live_room: 'Inspeccionar la habitación en vivo',
        service_inspect_live_room_desc:
          'Ver todos los productos de vestimenta de otros usuarios presentes en una sala en vivo',
        // CURRENT OUTFIT
        service_current_outift_title: 'Ropa actual',
        service_current_outfit_description:
          'Ve qué ropas está usando actualmente un avatar',
        // HISTORY OUTFITS
        service_load_outfit: 'Ver Outfits',
        service_load_avatar_image:
          'Haz clic en ver outfits y/o espera a que cargue',
        service_history_outfit_title: 'Historial de outfits',
        service_history_protection: 'Este usuario está protegido',
        service_history_vip: 'Este usuario es Premium',
        service_history_empty:
          'No hemos encontrado ningún historial de este usuario',
        service_history_outfit_description:
          'Ve el historial de ropas que ha usado un usuario',
        // LUCK POST
        service_lucky_post_title: 'publicación de la suerte',
        service_lucky_post_description:
          'Realizar un sorteo de comentarios directamente desde una publicación',
        // PRIVATE ROOM
        service_private_room_title: 'Sala privada',
        service_private_room_description:
          '¡Vea dónde está un usuario! El usuario debe estar activo en este momento, dentro de su propia sala (no funciona en salas en vivo)',
        // HISTORY CLASSIC ROOMS | LIVE ROOMS
        service_history_room_title: 'Historial de salas',
        service_history_room_description:
          'Ve el historial de salas de un usuario, sean clásicas o en vivo',
        serive_history_back_outfit: 'Volver al historial',
        serive_history_details_outfit:
          'Haz clic en el botón de abajo si deseas mostrar todos los productos de este outfit',
        serive_history_more_outfit: 'Ver detalles',
        // ACTIVE CLASSIC ROOM
        service_active_classic_room_title: 'Escáner de Salas Clásicas',
        service_active_classic_room_description:
          'Encuentra usuarios automáticamente en salas clásicas',
        service_active_room_obs:
          'Haremos un escaneo completo en más de +10.000 (diez mil) salas públicas disponibles, por lo que esta acción puede llevar unos minutos para finalizarse. Si el usuario no es localizado, no significa que no esté en una sala, sino que también puede estar en una sala privada. Si conoces los gustos del usuario, puedes seleccionar un idioma específico de la sala para reducir el tiempo de búsqueda.',
        // ACTIVE LIVE ROOM
        service_active_live_room_title: 'Escáner de Salas en Vivo',
        service_active_live_room_description:
          'Encuentra usuarios automáticamente en salas en vivo',
        // SORT MESSAGES
        post_link: 'Enlace de la publicación',
        post_btn_empty: 'Limpiar todo',
        post_btn_select: 'Seleccionar publicación',
        post_btn_view: 'Ver publicación',
        post_quant_win: 'Cuántos ganadores: (5/1 token)',
        post_quant_comment: 'Cuántos comentarios hay en el post: (100/1 token)',
        post_quant_at: 'Hasta',
        post_rule: 'Selecciona la regla:',
        post_rule_opt_unique: 'Comentario único',
        post_rule_opt_mult: 'Cada comentario cuenta',
        post_rule_opt_unique_desc: 'Solo 1 comentario por usuario',
        post_rule_opt_mult_desc:
          'Permitir que todos los comentarios de un usuario participen',
        post_obs_important:
          'Antes de iniciar el sorteo, ¡asegúrate de que la publicación seleccionada sea la correcta! Esta acción usará tokens para el procesamiento y, por lo tanto, es irreversible.',
        post_btn_sort: 'Iniciar sorteo',
        post_winners: 'Ganadores',
        // TOKENS
        tokens_empty: 'No tienes suficientes tokens',
        tokens_and_tray_again:
          'Comprueba si tienes suficientes tokens o inténtalo de nuevo en unos minutos.',
        tokens_and_tray_again_comments:
          'Comprueba que tienes suficientes tokens, que el número del comentario es correcto o vuelve a intentarlo en unos minutos.',
        token_buy_title: 'Comprar Tokens',
        token_buy_sub:
          'Utilice tokens para mejorar su experiencia y comprar recursos adicionales.',
        token_buy_btn: 'Comprar',
        token_use: '1 token por uso',
        token_use_more: '2 tokens por uso',
        token_use_empty: 'token por uso',
        token_buy: 'Compra 1 token por $1',
        token_buy_premium: 'Compra 2 tokens por $1',
        token_month: '10 tokens por mes',
        token_month_plus: '20 tokens por mes',
        token_redeem: 'Canje de premios',
        token_awards: 'Canjee recompensas con tokens premium',
        token_awards_desc:
          'Todos los canjes de premios de tokens de suscripción (Premium Tokens) son definitivos y no reembolsables. Asegúrese de comprender lo que está canjeando antes de completar su solicitud. Los precios y la disponibilidad de productos digitales pueden cambiar sin previo aviso.',
        token_disclaimer:
          'Los tokens adquiridos mediante suscripción (Premium Tokens) son acumulativos y pueden usarse exclusivamente para canjear premios, además de su uso común en funciones que requieren tokens. Se renuevan mensualmente, según la cantidad especificada en el plan activo.',
        token_disclaimer_buy:
          'Los tokens comprados por separado son exclusivos para el uso de funciones que requieren el consumo de tokens.',
        // TOKEN REDEEM
        token_redeem_empty:
          '¡Pronto tendremos nuevos premios y valores increíbles disponibles para canjear! (Premium Tokens)',
        // SPY USER PRIVATE ROOM
        create_update_spy_private:
          'Al crear/actualizar el usuario, se eliminarán todos los datos ya registrados, esto es irreversible:',
        delete_spy_private:
          '¿Eliminar usuario? Esto borrará todo tu historial y es irreversible:',
        get_rooms_spy_private:
          '¿Ver/actualizar el listado del historial de habitaciones privadas?',
        start_rooms_spy_private:
          '¿Habilitar el monitoreo? Esto reanudará el monitoreo de usuarios:',
        stop_rooms_spy_private:
          '¿Desactivar el seguimiento? Esto detendrá el seguimiento de este usuario:',
        service_spy_rooms_private_obs:
          'La función Historial de sala privada le permite agregar un usuario para que sea monitoreado continuamente en sus salas. Si el usuario es visto en una sala acompañado de otra persona, se registrará su actividad, incluyendo información sobre la sala, los participantes presentes y su vestimenta. Además, recibirá una notificación por correo electrónico cada vez que el usuario monitoreado esté en la sala con otra persona. Se creará un historial único visible solo para usted a medida que se lleve a cabo el seguimiento del usuario. Si elimina el usuario seleccionado, cambia su suscripción a un plan inferior o cancela su suscripción, se eliminará el historial asociado. (No funciona en salas en vivo)',
        service_spy_user_title: 'Historia de la habitación privada',
        service_spy_user_description:
          'Selecciona un usuario, monitorea su asistencia en sus salas privadas y recibe notificaciones en tiempo real vía correo electrónico (no funciona en salas en vivo)',
        service_spy_user_title_email: 'Notificación de sala privada',
        service_apy_user_notification_obs:
          'Enviado si el usuario está con alguien',
        service_apy_user_private_obs:
          'Sólo para salas de usuarios seleccionadas',
        // SANTA FRIEND
        santa_friend_subtitle: 'Tu amigo secreto es...',
        santa_friend_username: 'Código de habitación',
        santa_friend_code: 'Tu código secreto',
        santa_friend_message: 'tu amigo es',
        santa_friend_show_now: 'Mostrar ahora',
        // CATALOG
        catalog_title: 'Visor de catálogo',
        catalog_description:
          'Vea todo nuestro catálogo de productos ya registrados, incluidos productos ocultos y eliminados.',
        // SIGNED
        required_signed: 'Inicia sesión en tu cuenta para continuar',
        // FOOTER
        terms_service: 'Términos de Servicio',
        contact: 'Contacto',
        // SIGN IN AND SIGN UP
        account_title: 'Cuenta',
        error_login: 'Correo electrónico/contraseña incorrectos',
        success_register: 'Cuenta creada con éxito',
        error_register: 'Comprueba tus credenciales y vuelve a intentarlo.',
        success_update: 'Actualizado exitosamente',
        error_update: 'Nombre de usuario en uso, inténtelo de nuevo',
        accept_terms: 'Al registrarte aceptas nuestros términos de servicio',
        view_terms: 'vea mas.',
        signin_welcome: 'Accede a tu cuenta',
        signin_email: 'Correo de acceso',
        signin_password: 'Contraseña de acceso',
        signin_button_access: 'Acceder',
        signin_create_free_account: 'Crear cuenta gratuita',
        signup_welcome: 'Crear cuenta gratuita',
        signup_name: '@usuario',
        signup_have_account: 'Ya tengo una cuenta',
        signup_button_register: 'Crear cuenta',
        signup_obs_username: 'Se utilizará para personalizar tu experiencia',
        signup_obs_password: 'Atención, esta no es tu contraseña IMVU',
        signup_obs_email: 'Atención, esto no es tu correo IMVU',
        // ALERTS AUTH USERS
        auth_alert_required: 'Campos obligatorios',
        auth_alert_incorrect: 'Correo/Contraseña incorrectos',
        // ACCOUNT TEXTS
        acount_subscription: 'Suscripción',
        account_change_plan: 'Cambiar plan',
        account_button_update: 'Guardar',
        account_button_signout: 'Cerrar sesión',
        // PLANS TEXTS
        hidden_success_btn: 'Guardado exitosamente',
        hidden_equals_btn: 'El usuario debe ser diferente al tuyo',
        hidden_error_btn:
          'Se produjo un error, inténtelo nuevamente en unos minutos',
        hidden_btn_save: 'Guardar username',
        hidden_btn_delete: 'Eliminar username',
        hidden_title_card: 'Ocultar nombre en búsquedas',
        hidden_title_protection:
          'Cuando te conviertas en VIP, tu nombre predeterminado ingresado en tu cuenta estará protegido en el sistema de búsqueda de habitaciones y vestimenta',
        hidden_description_protection:
          'Agregue un usuario que quiera agregar protección de outfit',
        plan_premium_hidden_room: 'Ocultar historial de sala',
        plan_premium_hidden_outfit: 'Ocultar historial de vestimenta',
        plan_premium_hidden_history_room:
          'Oculta tu nombre del historial de la habitación',
        plan_premium_hidden_history_outift:
          'Oculta tu outfit y el de otro usuario',
        plan_shortly_btn: 'Noticias próximamente',
        plan_free: 'Gratis',
        plans_no_ads: 'Sin anuncios',
        plans_all_free: 'Todo del plan gratis',
        plans_all_premium: 'Todo desde el plan Premium',
        plans_all_hidden: 'Mantente oculto de todas las búsquedas',
        plans_support: 'Soporte exclusivo',
        plans_updates: 'Mejoras y actualizaciones',
        plans_title: 'Planes',
        plans_subtitle:
          'Disfruta de todos los beneficios Premium (Herramientas funcionales para salas en vivo y clásicas)',
        plans_plan_free: 'Plan Gratis',
        plans_plan_vip: 'Premium',
        plans_plan_vip_plus: 'Premium Plus',
        plans_plan_button: 'Convertirse en Premium',
        plans_plan_button_plus: 'Convertirse en Premium Plus',
        plans_plan_change_plan: 'Administrar suscripción',
        plans_plan_is_premium: 'Ya eres Premium',
        plans_plan_is_premium_plus: 'Ya eres Premium Plus',
        plans_premium_price_from: 'Desde ',
        plans_premium_price_from_plus: 'Desde ',
        // plans_premium_price_from: 'Desde $14.99',
        // plans_premium_price_from_plus: 'Desde $29.99',
        plans_premium_price_per: 'por',
        plans_premium_description:
          'Cuando te vuelves premium, adquieres todas las funciones que se enumeran a continuación según el plan que elijas y puedes cancelar la renovación de tu suscripción cuando lo desees.',
        // TERMS OF SERVICES
        terms_tokens_premium: 'Tokens de suscripción',
        terms_buy_tokens: 'Tokens compradas por separado',
        terms_title: 'Términos de servicios',
        terms_sub_01: 'Introducción',
        terms_desc_01:
          '¡Bienvenido(a) a ExploreVu! Estos Términos de Servicio rigen el uso de nuestro sitio, incluyendo cualquier servicio y bienes digitales ofrecidos. Al registrarte y participar en este sitio, aceptas cumplir y estar sujeto a estos términos. ExploreVu no está afiliado a IMVU Inc. ni a Together Labs.',
        terms_sub_02: 'Elegibilidad',
        terms_desc_02:
          'Debes tener al menos 18 años para comprar bienes digitales de este sitio. Al participar, confirmas que cumples con este requisito de edad.',
        terms_sub_03: 'Responsabilidad de la Cuenta',
        terms_desc_03:
          'Eres responsable de mantener la seguridad de tu cuenta y de todas las actividades que ocurran bajo tu cuenta. Contáctanos inmediatamente si sospechas de cualquier uso no autorizado.',
        terms_sub_06: 'Compra de Bienes Digitales',
        terms_desc_06:
          'Todas las ventas de bienes digitales son finales y no reembolsables. Asegúrese de entender lo que está comprando antes de completar una transacción. Los precios y la disponibilidad de los bienes digitales pueden cambiar sin previo aviso. No nos responsabilizamos por las ventas realizadas fuera de nuestra plataforma. En esos casos, el comprador debe contactar directamente al vendedor original para resolver cualquier problema antes de ponerse en contacto con nuestro equipo de soporte.',
        terms_sub_07: 'Cambio de planes (Upgrade y Downgrade)',
        terms_desc_07:
          'Upgrade: Al actualizar su plan (seleccionando un plan superior), el monto adicional se debitará automáticamente de la tarjeta registrada, proporcional al período restante del ciclo de facturación actual. El cambio de suscripción solo se realizará después de que el cargo se haya realizado exitosamente.',
        terms_desc_07_1:
          'Downgrade: cuando bajes de categoría tu plan (selecciona un plan inferior), Stripe calculará el crédito proporcional al período no utilizado de tu plan actual. Este crédito se aplicará automáticamente a facturas futuras. No habrá devolución del importe retenido; el importe se utilizará para cubrir futuros costes de suscripción hasta que se agote por completo. El cambio de suscripción solo se realizará después de que el cargo se haya realizado exitosamente.',
        terms_sub_08: 'Pago',
        terms_desc_08:
          'Los pagos son procesados por Hotmart. No almacenamos tu información de pago. Asegúrate de que los detalles del pago sean correctos para evitar problemas de transacción.',
        terms_sub_09: 'Conducta Prohibida',
        terms_desc_09:
          'No se permiten actividades fraudulentas o contracargos. No compartas, revendas o distribuyas bienes digitales comprados sin permiso.',
        terms_sub_10: 'Propiedad Intelectual',
        terms_desc_10:
          'Todos los bienes digitales vendidos son propiedad intelectual de sus respectivos creadores. El uso no autorizado, distribución o reproducción está prohibido.',
        terms_sub_11:
          'Exención de Responsabilidad y Limitación de Responsabilidad',
        terms_desc_11:
          'Los bienes digitales se proporcionan "tal como están", sin garantías de ningún tipo. No somos responsables de ningún daño resultante del uso o la incapacidad de usar los bienes digitales. ExploreVu no está afiliado a IMVU Inc. o Together Labs.',
        terms_sub_12: 'Modificaciones en los Términos de Servicios',
        terms_desc_12:
          'Nos reservamos el derecho de modificar estos términos en cualquier momento. Los avisos sobre cambios serán informados por nuestros medios de contacto y enviados por correo electrónico. El uso continuo del sitio constituye la aceptación de los términos modificados.',
        terms_sub_13: 'Información de Contacto',
        terms_desc_13:
          'Para cualquier pregunta o inquietud sobre estos Términos de Servicio, contáctanos por nuestros medios de contacto. Al usar nuestros servicios, aceptas permitir el registro de tu información solicitada en el registro para fines de uso.',
        terms_sub_14: 'Sobre los Valores y Moneda',
        terms_desc1_14:
          'El valor mostrado en nuestra plataforma se ajusta automáticamente a una moneda aceptada localmente durante el proceso de pago. Esto significa que, al completar su compra, Hotmart, nuestro socio de pagos, convertirá el valor mostrado a la moneda local asociada con su método de pago.',
        terms_desc2_14:
          'De esta manera, puede haber tarifas adicionales de cambio o conversiones externas, brindándole una experiencia de pago más transparente y conveniente. El monto final se mostrará en el checkout, asegurando que sepa exactamente lo que se le está cobrando en su moneda local. (Válido para algunas regiones y monedas)',
        // ABOUT
        about: 'Acerca de',
        about_1:
          '¡Estamos encantados de ofrecer nuestros servicios a toda la comunidad! Nuestra plataforma es completa y está llena de funcionalidades para garantizar la mejor experiencia posible, ya sea en un ordenador o en dispositivos móviles. Añade nuestro sitio a tus favoritos y explora todas las herramientas que hemos desarrollado con dedicación y cuidado. Nuestro equipo siempre está trabajando para traer mejoras y nuevas funcionalidades. ¡Gracias por elegir ExploreVu! ¡Bienvenido!',
        about_2:
          'Recordando que nuestro sitio fue creado y es mantenido exclusivamente por miembros de la comunidad, dejando en claro que ExploreVu no está afiliado a IMVU Inc. ni Together Labs.',
        copyright: '2024 Equipo ExploreVu. Todos los derechos reservados.',
        // CONTACT
        contact_0: 'Contacto',
        contact_1:
          '¿Tienes alguna duda o te gustaría hablar con nuestro equipo sobre algún tema? Contáctanos ahora mismo por correo electrónico a contact@explorevu.com',
        contact_2:
          'Nuestro tiempo de respuesta es de 1-2 días hábiles, pero haremos lo posible para responderte lo antes posible.',
        // MAINTENANCE
        btn_maintenance: 'Mantenimiento',
        maintenance_service: '(Estamos optimizando este servicio)',
        maintenance:
          '¡Estamos trabajando para mejorar nuestros servicios! Estamos añadiendo un nuevo servidor al sistema, actualizando los métodos de pago y añadiendo nuevas funcionalidades. El acceso se normalizará pronto, una vez que la propagación esté completa. Agradecemos su apoyo y por utilizar nuestras herramientas. Para cualquier duda, por favor, contáctenos en contact@explorevu.com.',
      },
    },
  },
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
