import styled from 'styled-components';

export const Container = styled.div`
  /* max-width: 400px; */
  max-width: 500px;
  width: 100%;
  min-height: 175px;
  padding: 15px;
  border-radius: 5px;

  display: flex;
  align-items: flex-start;
  background-color: ${({ theme }) => theme.backgroundGray};
  gap: 10px;

  h3 {
    font-size: 1.1rem;
    margin-bottom: 5px;
    text-transform: capitalize;
  }

  p {
    color: ${({ theme }) => theme.textColor};
    font-size: 0.9rem;
  }

  p.token {
    background: ${({ theme }) => theme.backgroundColor};
    font-size: 0.75rem;
    font-weight: bold;
    padding: 2px 5px;
    border-radius: 2px;
    margin-top: 10px;
    color: ${({ theme }) => theme.textColor};
    max-width: 150px;
    margin-top: 5px;
  }

  .imgArea {
    max-width: 30%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    border-right: 1px solid ${({ theme }) => theme.backgroundColor};
    padding-right: 10px;

    img {
      /* max-width: 75px; */
      max-width: 125px;
      padding: 5px 30px;
      border-radius: 3px;
      background: #fff;
    }

    button {
      display: block;
      width: 125px;
      background: ${({ theme }) => theme.primary};
      font-weight: bold;
      color: #fff;
      padding: 10px;
      text-align: center;
      text-decoration: none;
      border-radius: 3px;
      outline: none;
      border: 0;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 600px) {
    .imgArea {
      max-width: 40% !important;
    }
  }
`;
