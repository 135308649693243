import { useTranslation } from 'react-i18next';
import { Container } from './ContactStyles';

export function Contact() {
  const { t } = useTranslation();

  return (
    <Container>
      <div className="title-area">
        <h2>{t('contact_0')}</h2>
        <span></span>
      </div>
      <p className="description">{t('contact_1')}</p>
      <p className="description">{t('contact_2')}</p>
    </Container>
  );
}
