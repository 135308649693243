import { useContext, useEffect, useState } from 'react';
import { FiPlay } from 'react-icons/fi';

import { Container } from './VideosStyles';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContexts';

type VideoProps = {
  id: string | null;
  title: string;
  //
  desc_en?: string;
  desc_pt?: string;
  desc_es?: string;
  //
  active: number;
};

const ALL_VIDEOS = [
  {
    id: '1020016120',
    title: 'video_welcome',
    desc_en:
      'Thank you for joining our ExploreVu community! We appreciate your trust and for choosing us to be part of this journey. Below this video, youll find new content with tips and explanatory tutorials on how to use each of the exclusive tools youve gained access to by joining our community. We are available for any questions you may have. To access them, simply go to the home screen of our platform. New videos and content will be added over time as new updates are released. Thank you for being part of the ExploreVu team!',
    desc_pt:
      'Obrigado por se juntar à nossa comunidade ExploreVu! Agradecemos sua confiança e por nos escolher para esta jornada. Abaixo deste vídeo, você encontrará novos conteúdos com dicas e tutoriais explicativos sobre como usar cada uma das ferramentas exclusivas às quais você ganhou acesso ao se juntar à nossa comunidade. Estamos à disposição para qualquer dúvida. Para acessá-las, basta ir à tela inicial da nossa plataforma. Novos vídeos e conteúdos serão adicionados com o tempo, à medida que novas atualizações forem lançadas. Obrigado por fazer parte do time ExploreVu!',
    desc_es:
      '¡Gracias por unirte a nuestra comunidad ExploreVu! Agradecemos tu confianza y por elegirnos para ser parte de esta aventura. Debajo de este video encontrarás nuevos contenidos con consejos y tutoriales explicativos sobre cómo utilizar cada una de las herramientas exclusivas a las que has tenido acceso al unirte a nuestra comunidad. Estamos a tu disposición para cualquier duda. Para acceder a ellas, solo debes ir a la pantalla principal de nuestra plataforma. Con el tiempo, se agregarán nuevos videos y contenidos a medida que se lancen nuevas actualizaciones. ¡Gracias por ser parte del equipo ExploreVu!',
    active: 0,
  },
  {
    id: '1019696819',
    title: 'service_furniture_title',
    desc_en:
      'With this feature, you can find all the items in both live and classic rooms. Quickly and easily, you can view the items of any room! All you need to do is copy the room ID. Ill show you how to do this next.',
    desc_pt:
      'Com esta funcionalidade, você pode encontrar todos os itens das salas, sejam elas ao vivo ou clássicas. De forma rápida e simples, é possível visualizar os itens de qualquer sala! Tudo o que você precisa fazer é copiar o ID da sala. A seguir, mostrarei como fazer isso.',
    desc_es:
      'Con esta funcionalidad, puedes encontrar todos los ítems de las salas, ya sean en vivo o clásicas. ¡De manera rápida y sencilla, puedes visualizar los ítems de cualquier sala! Todo lo que necesitas es copiar el ID de la sala. A continuación, te mostraré cómo hacerlo.',
    active: 1,
  },
  {
    id: '1019696770',
    title: 'service_song_title',
    desc_en:
      'With this feature, you can access a preview of the active microphones and also see which song is currently playing or when the last track was played.',
    desc_pt:
      'Com esta funcionalidade, você pode acessar uma visualização prévia dos microfones ativos e também verificar qual música está sendo reproduzida ou quando foi a última reprodução.',
    desc_es:
      'Con esta funcionalidad, puedes acceder a una vista previa de los micrófonos activos y también verificar qué canción se está reproduciendo o cuándo fue la última reproducción.',
    active: 2,
  },
  {
    id: '1020473331',
    title: 'service_history_room_title',
    desc_en:
      'This feature allows you to view the public room history of an avatar. To use it, simply enter the avatars name in the search and wait for the results. (We are currently finalizing the video tutorial for this feature.)',
    desc_pt:
      'Essa funcionalidade permite visualizar o histórico de salas públicas de um avatar. Para utilizá-la, basta adicionar o nome do avatar na busca e aguardar o resultado. (Estamos finalizando o vídeo de demonstração desta funcionalidade.)',
    desc_es:
      'Esta funcionalidad te permite visualizar el historial de salas públicas de un avatar. Para utilizarla, solo debes agregar el nombre del avatar en la búsqueda y esperar el resultado. (Estamos finalizando el video de demostración de esta funcionalidad.)',
    active: 3,
  },
  {
    id: '1020473377',
    title: 'service_history_outfit_title',
    desc_en:
      'This feature allows you to view the outfit history of an avatar. To use it, simply enter the avatars name in the search and wait for the results. (We are currently finalizing the video tutorial for this feature.)',
    desc_pt:
      'Essa funcionalidade permite visualizar o histórico de outfits de um avatar. Para utilizá-la, basta adicionar o nome do avatar na busca e aguardar o resultado. (Estamos finalizando o vídeo de demonstração desta funcionalidade.)',
    desc_es:
      'Esta funcionalidad te permite visualizar el historial de outfits de un avatar. Para utilizarla, solo debes agregar el nombre del avatar en la búsqueda y esperar el resultado. (Estamos finalizando el video de demostración de esta funcionalidad.)',
    active: 4,
  },
  {
    id: '1019696736',
    title: 'service_current_outift_title',
    desc_en:
      'Here, you can view your avatars current outfit. This is the outfit youve set before entering any room, meaning the currently saved clothing for use. This allows you to see the items and find them in the store.',
    desc_pt:
      'Aqui você poderá visualizar o outfit atual do avatar. Este outfit é aquele que você ajusta antes de entrar em alguma sala, ou seja, a roupa atual salva para uso. Com isso, é possível ver os itens e encontrá-los na loja.',
    desc_es:
      'Aquí podrás visualizar el outfit actual de tu avatar. Este es el outfit que ajustas antes de entrar en alguna sala, es decir, la ropa actual guardada para uso. Con esto, puedes ver los ítems y encontrarlos en la tienda.',
    active: 5,
  },
  {
    id: '1019696700',
    title: 'hidden_title_card',
    desc_en:
      'Hide a users outfits and prevent their appearances from being accessed. With this feature, you can add an extra layer of protection to prevent other users from viewing the desired avatars outfit. This will completely block outfit history searches.',
    desc_pt:
      'Oculte os outfits de um usuário e impeça que suas aparências sejam acessadas. Com esta funcionalidade, você pode adicionar um bloqueio adicional para impedir que outros usuários visualizem o outfit do avatar desejado. Isso vai impedir completamente a busca e o acesso ao histórico de outfits.',
    desc_es:
      'Oculta los outfits de un usuario e impide que sus apariencias sean accesibles. Con esta funcionalidad, puedes añadir una capa adicional de bloqueo para evitar que otros usuarios accedan al outfit del avatar deseado. Esto impedirá completamente la búsqueda de historial de outfits.',
    active: 6,
  },
  {
    id: '1019696626',
    title: 'service_inspect_live_room',
    desc_en:
      'View all the outfit products of users present in a live room. With this feature, you can see in detail all the users inside the room and check the outfits they are wearing at that moment.',
    desc_pt:
      'Veja todos os produtos de outfits dos usuários presentes em uma sala ao vivo. Com esta funcionalidade, você poderá ver detalhadamente todos os usuários dentro da sala, visualizando o outfit que eles estão usando naquele momento.',
    desc_es:
      'Ve todos los productos de outfits de los usuarios presentes en una sala en vivo. Con esta funcionalidad, podrás ver detalladamente a todos los usuarios dentro de la sala y observar el outfit que están usando en ese momento.',
    active: 7,
  },
  {
    id: '1019696561',
    title: 'service_inspect_photo',
    desc_en:
      'Inspect posted photos and find out all the users present at that moment (only for photos that are not screenshots). The photos must be taken inside a room. If you attempt to inspect an invalid photo, an incompatibility error will be displayed, allowing you to get another link to perform the inspection.',
    desc_pt:
      'Inspecione as fotos postadas e descubra todos os usuários presentes naquele momento (somente para fotos que não sejam capturas de tela). As fotos devem ser tiradas dentro de uma sala. Caso tente inspecionar uma foto inválida, será exibida uma mensagem de erro de incompatibilidade, permitindo que você pegue outro link para realizar a inspeção.',
    desc_es:
      'Inspecciona las fotos publicadas y descubre a todos los usuarios presentes en ese momento (solo para fotos que no sean capturas de pantalla). Las fotos deben tomarse dentro de una sala. Si intentas inspeccionar una foto no válida, se mostrará un error de incompatibilidad, permitiéndote obtener otro enlace para realizar la inspección.',
    active: 8,
  },
  {
    id: '1020473403',
    title: 'service_friend_title',
    desc_en:
      'Create your friends list and exchange gifts anonymously! Here, you can create your own room and personalized list of people you want to include in the draw. Once everyone is added, our system will perform a fully random draw to determine who picked whom. The results will be displayed, and as the administrator and room owner, youll be able to copy the generated message and send it to each participant. They can then access the "My Friend is..." feature and enter the room code and their draw code to reveal who they picked. (We are currently finalizing the video tutorial for this feature.)',
    desc_pt:
      'Crie sua lista de amigos e troque presentes de forma anônima! Aqui, você poderá criar sua própria sala e lista personalizada de pessoas que deseja adicionar ao sorteio. Uma vez que todos estejam adicionados, nosso sistema realizará um sorteio totalmente aleatório para definir quem tirou quem. Os resultados serão exibidos, e você, como administrador e dono da sala, poderá copiar a mensagem gerada e enviá-la aos participantes. Eles poderão acessar o recurso "Meu Amigo é..." e informar o código da sala e seu código de sorteio para descobrir quem tiraram. (Estamos finalizando o vídeo de demonstração desta funcionalidade.)',
    desc_es:
      '¡Crea tu lista de amigos e intercambia regalos de forma anónima! Aquí podrás crear tu propia sala y lista personalizada de personas que deseas agregar al sorteo. Una vez que todos estén añadidos, nuestro sistema realizará un sorteo completamente aleatorio para definir quién sacó a quién. Los resultados se mostrarán y, como administrador y dueño de la sala, podrás copiar el mensaje generado y enviarlo a cada participante. Luego, podrán acceder al recurso "Mi Amigo es..." e ingresar el código de la sala y su código de sorteo para descubrir a quién sacaron. (Estamos finalizando el video de demostración de esta funcionalidad.)',
    active: 9,
  },
] as VideoProps[];

export function Videos() {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();

  const [isPremium, setIsPremium] = useState(false);

  const [currentVideo, setCurrentVideo] = useState<VideoProps>(ALL_VIDEOS[0]);

  const navigate = useNavigate();

  useEffect(() => {
    verifyPremium();
  }, []);

  async function verifyPremium() {
    const isPremium = user.subscriptions?.status === 'active' ? true : false;

    if (!isPremium) {
      navigate('/plans', { state: { premiumFunctionality: true } });
    }

    setIsPremium(isPremium);
  }

  if (!isPremium) return <></>;

  return (
    <Container>
      <div className="title-area">
        <h2>{t('video_content')}</h2>
        <span></span>
      </div>

      <div className="total-area">
        <div className="videos-navigation-area">
          <div className="videos-navigation">
            {ALL_VIDEOS?.map((it, idx) => (
              <button
                key={String(idx)}
                onClick={() => setCurrentVideo(it)}
                style={{
                  backgroundColor:
                    currentVideo.active === it.active ? '#F49C14' : '#30333D',
                }}
              >
                <FiPlay size={24} />
                {t(it.title)}
              </button>
            ))}
          </div>
        </div>
        <div className="video-player">
          {currentVideo.id === null && (
            <>
              <h2>• {t(currentVideo.title)}</h2>
              {currentVideo.desc_en && (
                <p className="video-desc">• {currentVideo.desc_en}</p>
              )}
              {currentVideo.desc_pt && (
                <p className="video-desc">• {currentVideo.desc_pt}</p>
              )}
              {currentVideo.desc_es && (
                <p className="video-desc">• {currentVideo.desc_es}</p>
              )}
            </>
          )}
          {currentVideo.id && currentVideo.id !== null && (
            <>
              <h2>• {t(currentVideo.title)}</h2>
              <iframe
                src={`https://player.vimeo.com/video/${currentVideo.id}`}
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                title={t(currentVideo.title)}
              ></iframe>
              <p className="note">{t('video_note')}</p>
              <p className="divisor"></p>
              {currentVideo.desc_en && (
                <p className="video-desc">• {currentVideo.desc_en}</p>
              )}
              {currentVideo.desc_pt && (
                <p className="video-desc">• {currentVideo.desc_pt}</p>
              )}
              {currentVideo.desc_es && (
                <p className="video-desc">• {currentVideo.desc_es}</p>
              )}
            </>
          )}
        </div>
      </div>
    </Container>
  );
}
