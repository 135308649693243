import { useContext, useEffect, useState } from 'react';
import { FiClipboard, FiLoader, FiX } from 'react-icons/fi';

import { Container } from './SantaRoomStyles';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { api } from '../../services/apiClient';
import { parseCookies } from 'nookies';
import { AuthContext } from '../../contexts/AuthContexts';
import { useNavigate } from 'react-router-dom';

interface SantaRoomData {
  name: string;
  username: string;
  santaFriends: SantaFriend[];
}

interface SantaFriend {
  code: string;
  current: string;
  friend: string;
}

export function SantaRoom() {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();

  const [santaRoom, setSantaRoom] = useState<SantaRoomData | null>(null);
  const [participants, setParticipants] = useState<string[]>([]);
  const [newParticipant, setNewParticipant] = useState('');
  const [load, setLoad] = useState(false);
  const [name, setName] = useState('');
  const [isPremium, setIsPremium] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    verifyPremium();
  }, []);

  async function verifyPremium() {
    const isPremium = user.subscriptions?.status === 'active' ? true : false;

    if (!isPremium) {
      navigate('/plans', { state: { premiumFunctionality: true } });
      return;
    }

    setIsPremium(isPremium);
    if (isPremium) {
      getSantaRoom();
    }
  }

  async function getSantaRoom() {
    setLoad(true);

    try {
      const { '@searchvu.token': token } = parseCookies();

      const res = await api.post(
        '/santaRoomView',
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (res.data) {
        const friendsArr: SantaFriend[] = [];

        await res.data.santaFriends.map((it) => {
          const newPair = {
            code: it.code,
            current: it.current,
            friend: it.friend,
          } as SantaFriend;

          friendsArr.push(newPair);
        });

        const data = {
          name: res.data.name,
          username: res.data.username,
          santaFriends: friendsArr,
        } as SantaRoomData;

        setSantaRoom(data);
      }
    } catch (error) {
      // //console.log(error);
    } finally {
      setLoad(false);
    }
  }

  async function addNewParticipant() {
    if (!newParticipant) return;

    const participantsLowercase = await participants.map((user) =>
      user.toLowerCase()
    );
    const exists = await participantsLowercase.includes(
      newParticipant.toLowerCase()
    );

    if (exists) {
      toast.error(t('santa_participant_exists'));
      return;
    }

    setParticipants((prev) => [...prev, newParticipant.trim()]);
    setNewParticipant('');
  }

  async function removeParticipant(participant: string) {
    const filtered = await participants.filter((it) => it !== participant);
    setParticipants(filtered);
  }

  async function copyCode({ code, current }: SantaFriend) {
    try {
      const textCopy = `${current}, ${t('santa_copy')}: \n\n${t(
        'santa_code_room'
      )}: ${santaRoom.username} \n${t(
        'santa_code'
      )}: ${code} \n\nLink: https://explorevu.com/my-friend`;

      await navigator.clipboard.writeText(textCopy);
      toast.success(t('santa_copy_success'));
    } catch (error) {
      // //console.log(error);
    }
  }

  async function createSantaRoom() {
    if (!name || participants.length < 3) {
      toast.error(t('min_participants'));
      return;
    }

    setLoad(true);

    try {
      const { '@searchvu.token': token } = parseCookies();

      await api
        .post(
          '/santaRoom',
          {
            // username: user.name,
            name: name,
            friends: participants,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          toast.success(t('santa_created'));
          setName('');
          setNewParticipant('');
          setParticipants([]);
          getSantaRoom();
        });
    } catch (error) {
      //console.log(error);
    } finally {
      setLoad(false);
    }
  }

  if (!isPremium) return <></>;

  return (
    <Container>
      <div className="title-area">
        <h2>{t('service_friend_title')}</h2>
        <span></span>
      </div>

      <div className="columnArea">
        <div className="inputArea">
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder={t('room_name')}
          />
          <input
            type="text"
            value={newParticipant}
            onChange={(e) => setNewParticipant(e.target.value)}
            placeholder={t('new_participant')}
          />
          <div className="btnArea">
            <button onClick={() => addNewParticipant()} disabled={load}>
              {load ? <FiLoader size={24} /> : t('santa_add')}
            </button>
            <button onClick={() => createSantaRoom()} disabled={load}>
              {load ? <FiLoader size={24} /> : t('santa_create')}
            </button>
          </div>
          {participants.length >= 1 && (
            <div className="participantsArea">
              {participants.map((it, index) => (
                <div
                  key={index}
                  className="participantItem"
                  onClick={() => removeParticipant(it)}
                >
                  <FiX />
                  <p>{it}</p>
                </div>
              ))}
            </div>
          )}
        </div>
        {santaRoom !== null && (
          <div className="santaRoom">
            <h3>{santaRoom?.name}</h3>
            <p>
              {t('santa_friend_username')}: {santaRoom?.username}
            </p>
            <br />
            <p>{t('santa_obs')}</p>
            <div className="santaRoomParticipants">
              {santaRoom.santaFriends?.map((it, index) => (
                // <div
                //   className="roomParticipantItem"
                //   onClick={() => copyCode(it)}
                // >
                //   <FiClipboard /> {t('santa_me')} <b>{it.current}</b>{' '}
                //   {t('santa_friend')} <b>{it.friend}</b> | {t('santa_code')}:{' '}
                //   <b>{it.code}</b>
                // </div>
                <div
                  key={index}
                  className="roomParticipantItem"
                  onClick={() => copyCode(it)}
                >
                  <FiClipboard /> {t('santa_me')} <b>{it.current}</b> |{' '}
                  {t('santa_code')}: <b>{it.code}</b>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </Container>
  );
}
