import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 30px 10px;
  margin: 0 auto;
  background: ${({ theme }) => theme.backgroundColor};

  div.title-area {
    display: flex;
    align-items: center;
    gap: 7px;
    margin-bottom: 30px;

    h2 {
      font-size: 1.75rem;
      font-weight: 500;
    }

    span {
      background: ${({ theme }) => theme.primary};
      height: 4px;
      width: 125px;
      border-radius: 2px;
    }
  }

  .inputArea {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    input {
      max-width: 86%;
      width: 100%;
      background: ${({ theme }) => theme.backgroundGray};
      border: 0;
      border-radius: 3px;
      height: 40px;
      padding: 0 10px;
      font-size: 1rem;
      font-weight: bold;
      color: ${({ theme }) => theme.textColor};
      outline: none;
    }

    button {
      height: 40px;
      max-width: 12%;
      width: 100%;
      border: 0;
      background: ${({ theme }) => theme.primary};
      border-radius: 3px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  div.loadArea {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div.areaCards {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 30px;
    flex-wrap: wrap;
    margin: 30px 0 60px 0;
    //
    max-height: 600px;
    height: 100%;
    overflow-y: scroll;
    padding: 10px 10px 20px 10px;
  }
`;
