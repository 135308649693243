import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 30px 10px;
  margin: 0 auto;
  background: ${({ theme }) => theme.backgroundColor};

  div.title-area {
    display: flex;
    align-items: center;
    gap: 7px;
    margin-bottom: 30px;

    h2 {
      font-size: 1.75rem;
      font-weight: 500;
    }

    span {
      background: ${({ theme }) => theme.primary};
      height: 4px;
      width: 125px;
      border-radius: 2px;
    }
  }

  .inputArea {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    input {
      max-width: 86%;
      width: 100%;
      background: ${({ theme }) => theme.backgroundGray};
      border: 0;
      border-radius: 3px;
      height: 40px;
      padding: 0 10px;
      font-size: 1rem;
      font-weight: bold;
      color: ${({ theme }) => theme.textColor};
      outline: none;
    }

    button {
      height: 40px;
      max-width: 12%;
      width: 100%;
      border: 0;
      background: ${({ theme }) => theme.primary};
      border-radius: 3px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  div.loadArea {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .areaOutfits {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;

    .outfitItem {
      max-width: 275px;
      width: 100%;
      padding: 10px;
      border-radius: 2px;
      background: ${({ theme }) => theme.backgroundGray};
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 100%;
      }

      button {
        height: 40px;
        width: 100%;
        border: 0;
        background: ${({ theme }) => theme.primary};
        border-radius: 3px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  div.lineArea {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  h3 {
    font-size: 1.3rem;
    font-weight: 500;
  }

  a {
    background: ${({ theme }) => theme.primary};
    color: #fff;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 3px;
    text-decoration: none;
  }

  p.lastDate {
    font-size: 0.75rem;
    font-weight: 500;
    text-align: center;
    margin: 5px 0 10px 0;
  }

  .areaPages {
    margin: 30px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 15px;
    width: 100%;

    p {
      font-size: 1rem;
    }

    button {
      width: 32px;
      height: 32px;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: ${({ theme }) => theme.primary};
      border: none;

      svg {
        color: #fff;
        font-size: 24px;
      }
    }
  }
`;

export const ModalOutfit = styled.div`
  width: 100%;
  height: 100%;
  padding: 30px 10px;
  margin: 0 auto;
  background: ${({ theme }) => theme.backgroundColor};
  position: relative;

  .imageAreaProfile {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  div.title-area {
    display: flex;
    align-items: center;
    gap: 7px;
    margin-bottom: 30px;

    h2 {
      font-size: 1.75rem;
      font-weight: 500;
    }

    span {
      background: ${({ theme }) => theme.primary};
      height: 4px;
      width: 125px;
      border-radius: 2px;
    }
  }

  .btnClose {
    font-size: 0.9rem;
    font-weight: bold;
    color: ${({ theme }) => theme.primary};
    border: none;
    background: ${({ theme }) => theme.backgroundGray};
    padding: 5px 10px;
    border-radius: 2px;
  }

  div.areaProfile {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 30px;

    img.profile {
      max-width: 225px;
      min-width: 225px;
      width: 100%;
      margin-top: 35px;
      box-shadow: 1px 1px 15px ${({ theme }) => theme.backgroundGray};
      border: 4px solid ${({ theme }) => theme.backgroundGray};
      border-radius: 5px;
      background: ${({ theme }) => theme.backgroundGray};
    }
  }

  div.areaButtonOutfit {
    max-width: 700px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    flex-wrap: wrap;
    margin: 30px 0 60px 0;
    //
    max-height: 600px;
    height: 100%;
    padding: 10px 10px 20px 10px;

    button {
      height: 40px;
      max-width: 15%;
      width: 100%;
      border: 0;
      background: ${({ theme }) => theme.primary};
      border-radius: 3px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  div.loadArea {
    max-width: 700px;
    width: 100%;
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    margin: 30px 0 60px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div.areaCards {
    max-width: 700px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 30px;
    flex-wrap: wrap;
    margin: 30px 0 60px 0;
    //
    max-height: 600px;
    height: 100%;
    overflow-y: scroll;
    padding: 10px 10px 20px 10px;
  }
`;
