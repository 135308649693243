import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContexts';
import toast from 'react-hot-toast';
import { t } from 'i18next';

export function Private({ children }: any) {
  const { signed, loadingAuth } = useContext(AuthContext);

  if (loadingAuth) {
    return <div></div>;
  }

  if (!signed) {
    // toast.error(t('alert_logged'));
    return <Navigate to="/sign-in" />;
  }

  return children;
}
