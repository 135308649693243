import styled from 'styled-components';

export const Container = styled.header`
  width: 100%;
  margin: 0 auto 30px auto;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom: 1px solid ${({ theme }) => theme.backgroundGray};
  gap: 20px;

  img#logo {
    max-width: 150px;
    border-radius: 3px;
    background: #fff;
    padding: 5px 7px;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    list-style: none;
    gap: 30px;
  }

  ul li a {
    font-weight: bold;
    transition: all ease 0.3s;
    cursor: pointer;
    text-decoration: none;
    color: ${({ theme }) => theme.textColor};

    &:hover {
      transform: scale(1.1);
      color: ${({ theme }) => theme.primary};
      transition: all ease 0.3s;
    }
  }

  button.flagDropDown {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    background-color: ${({ theme }) => theme.backgroundGray};
    border-radius: 3px;
    padding: 5px 10px;
    border: none;
    color: ${({ theme }) => theme.textColor};

    p {
      text-transform: uppercase;
    }
  }

  ul.flags img {
    width: 32px;
    border: 3px solid ${({ theme }) => theme.backgroundGray};
    border-radius: 18px;
    opacity: 0.7;
    cursor: pointer;
  }

  .btnTheme {
    font-size: 28px;
    cursor: pointer;
    margin-top: 10px;
  }

  #tokenHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
  }

  #tokenBuyHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    padding: 2px 7px;
    border-radius: 3px;
    background: ${({ theme }) => theme.primary};
    color: #fff;

    img {
      width: 20px;
    }
  }

  p#premiumToken {
    font-weight: bold;
    font-size: 0.7rem;
  }

  img.btnInsta {
    max-width: 36px !important;
    width: 100% !important;
    border: 3px solid ${({ theme }) => theme.backgroundGray} !important;
    border-radius: 500px;
    opacity: 1 !important;
  }

  @media screen and (max-width: 700px) {
    ul.menuResponsive {
      width: 100%;
      align-items: center;
      justify-content: center;
    }
  }
`;
