import { useState, createContext, useEffect, ReactNode } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { destroyCookie, setCookie, parseCookies } from 'nookies';
import { api } from '../services/apiClient';
import { t } from 'i18next';

interface AuthContextData {
  user: UserProps;
  signed: boolean;
  loadingAuth: boolean;
  signIn: (credentials: SignInProps) => Promise<void>;
  signUp: (credentials: SignUpProps) => Promise<void>;
  updateProfile: (credentials: UpdateProps) => Promise<void>;
  getMeStatusDetail: () => Promise<any>;
  signOut: () => void;
  updateTokens: (subscriptionTokens: number, purchaseTokens: number) => void;
}

type UserProps = {
  id: string;
  name: string;
  email: string;
  subscription_tokens: number;
  purchase_tokens: number;
  subscriptions?: SubscriptionProps | null;
};

// type AllUserProtectionProps = {
//   id: string;
//   username: string;
//   userId: string;
// };

type SubscriptionProps = {
  id: string;
  status: string;
  plan: string;
};

type SignInProps = {
  email: string;
  password: string;
};

type SignUpProps = {
  name: string;
  email: string;
  password: string;
};

type UpdateProps = {
  name: string;
};

type AuthProviderProps = {
  children: ReactNode;
};

export const AuthContext = createContext({} as AuthContextData);

export function AuthProvider({ children }: AuthProviderProps) {
  const navigate = useNavigate();

  const [user, setUser] = useState<UserProps>();
  const [loadingAuth, setLoadingAuth] = useState(false);
  const signed = !!user;

  useEffect(() => {
    const { '@searchvu.token': token } = parseCookies();

    if (token) {
      api
        .get('/me')
        .then((response) => {
          const {
            id,
            name,
            email,
            subscriptions,
            subscription_tokens,
            purchase_tokens,
            allUserProtection,
          } = response.data;
          setUser({
            id,
            name,
            email,
            subscription_tokens,
            purchase_tokens,
            subscriptions,
          });
        })
        .catch((error) => {
          signOut();
        });
    }
  }, []);

  async function signIn({ email, password }: SignInProps) {
    setLoadingAuth(true);

    try {
      const response = await api.post('/session', {
        email,
        password,
      });

      const {
        id,
        name,
        subscriptions,
        subscription_tokens,
        purchase_tokens,
        allUserProtection,
        token,
      } = response.data;

      setCookie(undefined, '@searchvu.token', token, {
        maxAge: 60 * 60 * 24 * 30, // Expires in 30d
        path: '/',
      });

      setUser({
        id,
        name,
        email,
        subscription_tokens,
        purchase_tokens,
        subscriptions,
      });

      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      navigate('/');
    } catch (error) {
      toast.error(t('error_login'));
    } finally {
      setLoadingAuth(false);
    }
  }

  async function signUp({ name, email, password }: SignUpProps) {
    setLoadingAuth(true);

    try {
      const response = await api.post('/users', {
        name,
        email,
        password,
      });

      toast.success(t('success_register'));
      navigate('/sign-in');
    } catch (error) {
      toast.error(t('error_register'));
    } finally {
      setLoadingAuth(false);
    }
  }

  async function updateProfile({ name }: UpdateProps) {
    setLoadingAuth(true);

    try {
      const { '@searchvu.token': token } = parseCookies();

      await api.put(
        '/users',
        {
          name: name,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const userUpdated = {
        ...user,
        name: name,
      } as UserProps;

      setUser(userUpdated);
      toast.success(t('success_update'));
      // return response;
    } catch (error) {
      toast.error(t('error_update'));
      // //console.log('ERROR UPDATE: ', error);
    } finally {
      setLoadingAuth(false);
    }
  }

  // async function getMeStatusDetail() {
  //   try {
  //     const { '@searchvu.token': token } = parseCookies();

  //     const response = await api.get('/me', {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });

  //     //console.log('AQUI: ', response);

  //     return response;
  //   } catch (error) {
  //     //console.log('ERROR: ', error);
  //   }
  // }

  async function getMeStatusDetail() {
    try {
      const { '@searchvu.token': token } = parseCookies();

      const response = await api.get('/me', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return {
        status:
          response?.data.subscriptions?.status === 'active' ? true : false,
        plan: response?.data.subscriptions?.plan,
      };
    } catch (error) {
      // //console.log('ERROR: ', error);
    }
  }

  function updateTokens(subscriptionTokens, purchaseTokens) {
    setUser({
      ...user,
      subscription_tokens: subscriptionTokens,
      purchase_tokens: purchaseTokens,
    });
  }

  function signOut() {
    setLoadingAuth(true);
    try {
      destroyCookie(null, '@searchvu.token', { path: '/' });
      setUser(null);
      navigate('/');
      // window.location ? window?.location?.href = '/' : null;
    } catch (error) {
      // //console.log('Sign out error');
    } finally {
      setLoadingAuth(false);
    }
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        signed,
        loadingAuth,
        signIn,
        signUp,
        updateProfile,
        getMeStatusDetail,
        updateTokens,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
