import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 30px 10px;
  margin: 0 auto;
  background: ${({ theme }) => theme.backgroundColor};

  div.banner-area {
    width: 100%;
    padding: 15px 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: start;
    border-radius: 5px;
    gap: 30px;
    margin-bottom: 30px;

    background: ${({ theme }) => theme.backgroundGray};

    img {
      background: #fff;
      border-radius: 5px;
      padding: 5px;

      max-width: 75px;
      width: 100%;
    }

    div {
      max-width: 800px;
      width: 100%;
    }

    h2 {
      font-size: 1.25rem;
    }

    p {
      margin-bottom: 10px;
      font-size: 1rem;
    }

    span {
      background: ${({ theme }) => theme.alert};
      padding: 2px 5px;
      border-radius: 3px;
      font-size: 0.8rem;
      font-weight: bold;
      color: #fff;
      display: inline-block;
    }
  }

  div.area-video-content {
    max-width: 100%;
    width: 100%;
    background-color: ${({ theme }) => theme.backgroundGray};
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 30px;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    p {
      font-size: 18px;
    }

    button {
      display: block;
      width: 125px;
      background: ${({ theme }) => theme.primary};
      font-weight: bold;
      color: #fff;
      padding: 10px;
      text-align: center;
      text-decoration: none;
      border-radius: 3px;
      outline: none;
      border: 0;
      cursor: pointer;
    }
  }

  div.title-area {
    display: flex;
    align-items: center;
    gap: 7px;
    margin-bottom: 30px;

    h2 {
      font-size: 1.75rem;
      font-weight: 500;
    }

    span {
      background: ${({ theme }) => theme.primary};
      height: 4px;
      width: 125px;
      border-radius: 2px;
    }
  }

  div.areaCards {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    flex-wrap: wrap;
    margin: 30px 0 60px 0;
  }
`;
