import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiLoader, FiPlayCircle, FiSearch, FiStopCircle } from 'react-icons/fi';

import { Container } from './CurrentSongStyles';
import { useNavigate } from 'react-router-dom';
import { parseCookies } from 'nookies';
import { api } from '../../services/apiClient';
import toast from 'react-hot-toast';
import { format, formatDistanceToNow, parseISO } from 'date-fns';
import { enUS, es, ptBR } from 'date-fns/locale';
import { AuthContext } from '../../contexts/AuthContexts';

// Tipo para informações do usuário
interface UserInfo {
  username: string;
  thumbnail_url: string;
  legacy_cid: number;
}

// Tipo para YouTube Media Player
interface YouTubeMediaPlayer {
  type: 'youtube';
  media_title: string;
  media_url: string;
  command_timestamp: string;
  status: string;
}

// Tipo para Vivox Media Player
interface VivoxMediaPlayer {
  type: 'vivox';
  channel_id: string | null;
  mic_holders: UserInfo[];
  mic_pending: UserInfo[];
  muted_mic_locally: UserInfo[];
  status: string;
}

// Tipo para o retorno completo da API
interface MediaPlayerResponse {
  media: {
    youtube: YouTubeMediaPlayer;
    vivox: VivoxMediaPlayer;
  };
}

export function CurrentSong() {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);

  const [search, setSearch] = useState('');
  const [roomInfo, setRoomInfo] = useState<MediaPlayerResponse | null>(null);
  const [load, setLoad] = useState(false);
  const [isPremium, setIsPremium] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    verifyPremium();
  }, []);

  async function verifyPremium() {
    // const { '@searchvu.token': token } = parseCookies();

    // const res = await api.get('/users/check', {
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //   },
    // });

    // const isPremium = res.data?.status === 'active' ? true : false;

    const isPremium = user.subscriptions?.status === 'active' ? true : false;

    if (!isPremium) {
      navigate('/plans', { state: { premiumFunctionality: true } });
    }

    setIsPremium(isPremium);
  }

  async function getRoomCurrentSong() {
    if (!search) {
      toast.error(t('required_room_id'));
      return;
    }

    setLoad(true);
    setRoomInfo(null);
    try {
      const { '@searchvu.token': token } = parseCookies();

      const res = await api.post(
        '/roomMediaPlayer',
        { roomId: search },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (res.data) {
        if (res.data.error) {
          setLoad(false);
          toast.error(t('error_song'));
          return;
        }

        setRoomInfo(res.data);
      }
    } catch (error) {
      setLoad(false);
      toast.error(t('error_song'));
    } finally {
      setLoad(false);
    }
  }

  function convertAndAdjustDate(isoDateString) {
    const dateObject = parseISO(isoDateString);

    const currentLanguage = t('current_language');
    let currentLocale = enUS;

    switch (currentLanguage) {
      case 'pt':
        currentLocale = ptBR;
        break;
      case 'es':
        currentLocale = es;
        break;
      default:
        currentLocale = enUS;
    }

    const formattedDate =
      currentLanguage === 'pt'
        ? format(dateObject, 'dd/MM/yyyy hh:mm:ss a', {
            locale: currentLocale,
          })
        : format(dateObject, 'MM/dd/yyyy hh:mm:ss a', {
            locale: currentLocale,
          });
    const timeAgo = formatDistanceToNow(dateObject, {
      addSuffix: true,
      locale: currentLocale,
    });

    return `${formattedDate} (${timeAgo})`;
  }

  // function convertAndAdjustDate(isoDateString) {
  //   const dateObject = parseISO(isoDateString);
  //   const formattedDate = format(dateObject, 'MM/dd/yyyy hh:mm:ss a', {
  //     locale: enUS,
  //   });
  //   const timeAgo = formatDistanceToNow(dateObject, {
  //     addSuffix: true,
  //     locale: t('current_language') === 'pt' ? ptBR : enUS,
  //   });

  //   return `${formattedDate} (${timeAgo})`;
  // }

  if (!isPremium) return <></>;

  return (
    <Container>
      <div className="title-area">
        <h2>{t('service_song_title')}</h2>
        <span></span>
      </div>

      <div className="inputArea">
        <input
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder={t('search_room_id')}
        />
        <button onClick={() => getRoomCurrentSong()} disabled={load}>
          {load ? <FiLoader size={24} /> : <FiSearch size={24} />}
        </button>
      </div>

      {load && (
        <div className="loadArea">
          <FiLoader size={42} color="#f49c14" />
        </div>
      )}

      {roomInfo !== null && (
        <div className="lineCardsMedia">
          {/* MIC */}
          {roomInfo.media.vivox.channel_id && (
            <div className="cardSong">
              <h3>{t('service_song_microfone_room')}</h3>
              {/* HOLDERS */}
              {roomInfo.media.vivox.mic_holders.length > 0 && (
                <>
                  <h6>
                    {t('service_song_with_access')} (
                    {roomInfo.media.vivox.mic_holders.length})
                  </h6>
                  <div className="usersContent">
                    {roomInfo.media.vivox.mic_holders.map((user, index) => (
                      <div className="userRow" key={index}>
                        <div className="avInfo">
                          <img src={user.thumbnail_url} alt={user.username} />
                          {/* <p>{user.username}</p> */}
                          <p>
                            {user?.username?.indexOf('Guest_') === 0
                              ? user?.username?.split('Guest_')[1]
                              : user?.username}
                          </p>
                        </div>
                        <div className="btnsUser">
                          <a
                            href={`https://avatars.imvu.com/${user.username}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Classic
                          </a>
                          <a
                            href={`https://go.imvu.com/av/${user.username}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Next
                          </a>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
              {/* MUTED */}
              {roomInfo.media.vivox.muted_mic_locally.length > 0 && (
                <>
                  <h6>
                    {t('service_song_muted')} (
                    {roomInfo.media.vivox.muted_mic_locally.length})
                  </h6>
                  <div className="usersContent">
                    {roomInfo.media.vivox.muted_mic_locally.map(
                      (user, index) => (
                        <div className="userRow" key={index}>
                          <div className="avInfo">
                            <img src={user.thumbnail_url} alt={user.username} />
                            {/* <p>{user.username}</p> */}
                            <p>
                              {user?.username?.indexOf('Guest_') === 0
                                ? user?.username?.split('Guest_')[1]
                                : user?.username}
                            </p>
                          </div>
                          <div className="btnsUser">
                            <a
                              href={`https://avatars.imvu.com/${user.username}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Classic
                            </a>
                            <a
                              href={`https://go.imvu.com/av/${user.username}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Next
                            </a>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </>
              )}
              {/* PENDENT */}
              {roomInfo.media.vivox.mic_pending.length > 0 && (
                <>
                  <h6>
                    {t('service_song_pendent')} (
                    {roomInfo.media.vivox.mic_pending.length})
                  </h6>
                  <div className="usersContent">
                    {roomInfo.media.vivox.mic_pending.map((user, index) => (
                      <div className="userRow" key={index}>
                        <div className="avInfo">
                          <img src={user.thumbnail_url} alt={user.username} />
                          {/* <p>{user.username}</p> */}
                          <p>
                            {user?.username?.indexOf('Guest_') === 0
                              ? user?.username?.split('Guest_')[1]
                              : user?.username}
                          </p>
                        </div>
                        <div className="btnsUser">
                          <a
                            href={`https://avatars.imvu.com/${user.username}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Classic
                          </a>
                          <a
                            href={`https://go.imvu.com/av/${user.username}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Next
                          </a>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          )}
          {/* YOUTUBE */}
          {roomInfo.media.youtube.media_url !== '' && (
            <div className="cardSong">
              {roomInfo.media.youtube.media_title ? (
                <h3>
                  {roomInfo.media.youtube.media_title.length > 60
                    ? `${roomInfo.media.youtube.media_title.slice(0, 60)}...`
                    : roomInfo.media.youtube.media_title}
                </h3>
              ) : (
                <h3>YouTube</h3>
              )}
              {roomInfo.media.youtube.command_timestamp && (
                <p className="lastDate">
                  {t('last_time')}:{' '}
                  {convertAndAdjustDate(
                    roomInfo.media.youtube.command_timestamp
                  )}
                </p>
              )}
              <div className="lineArea">
                <div>
                  <p className="status">
                    {roomInfo.media.youtube.status !== 'stopped' ? (
                      <FiPlayCircle
                        size={16}
                        style={{ background: '#0eca6e' }}
                      />
                    ) : (
                      <FiStopCircle
                        size={16}
                        style={{ background: '#d8355d' }}
                      />
                    )}
                    {roomInfo.media.youtube.status !== 'stopped'
                      ? t('status_playing')
                      : t('status_stopped')}
                  </p>
                </div>
                <div>
                  {roomInfo.media.youtube.media_url ? (
                    <a
                      href={roomInfo.media.youtube.media_url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t('btn_open_yt')}
                    </a>
                  ) : (
                    <a style={{ opacity: 0.7, cursor: 'not-allowed' }}>
                      {t('btn_open_yt')}
                    </a>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </Container>
  );
}
