import styled from 'styled-components';

export const Container = styled.footer`
  width: 100%;
  margin: 0 auto;
  padding: 20px 10px;
  background: ${({ theme }) => theme.backgroundGray};
  border-radius: 5px 5px 0 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 30px;

  img {
    max-width: 125px;
    border-radius: 3px;
    background: #fff;
    padding: 5px 7px;
  }

  ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;
    list-style: none;
    font-size: 0.9rem;
  }
  ul li a {
    color: ${({ theme }) => theme.textColor};
    cursor: pointer;
    text-decoration: underline;
    text-transform: capitalize;
  }
`;
