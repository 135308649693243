import { useState } from 'react';
import { FiCheckCircle, FiLoader, FiSearch, FiSlash } from 'react-icons/fi';
import toast from 'react-hot-toast';

import { Container } from './AvatarCardStyles';

import { api } from '../../services/apiClient';
import { useTranslation } from 'react-i18next';

interface UserCardProps {
  age: any;
  avatar_image: any;
  country: any;
  created: any;
  display_name: any;
  gender: any;
  interests: any;
  nft: any;
  ageverified: any;
  ap: any;
  creator: any;
  greeter: any;
  host: any;
  staff: any;
  vip: any;
  legacy_cid: any;
  online: any;
  looking_for: any;
  relationship: any;
  state: any;
  tagline: any;
  thumbnail: any;
  username: any;
  vip_platform: any;
  vip_tier: any;
  last_login: any;
  current_room?: {
    join_url: any;
    image: any;
    name: any;
    owner: any;
  };
}

const RELATIONSHIP_STATUS = [
  'Prefer not to say',
  'Single',
  'Going out with someone',
  'In a relationship',
  'Married',
  'Divorced',
  'Other',
];

const HERE_STATUS = [
  'Prefer not to say',
  'Talking',
  'Relationships',
  'Amizade',
  'Other',
];

export function AvatarCard() {
  const { t } = useTranslation();

  const [search, setSearch] = useState('');
  const [load, setLoad] = useState(false);

  const [currentUser, setCurrentUser] = useState<UserCardProps | null>(null);

  async function getAvatarCard() {
    if (!search) {
      toast.error(t('required_user_id'));
      return;
    }

    setLoad(true);

    try {
      const res = await api.post('/avatarCard', { username: search });

      const info = res.data.allInfo;

      const formattedDate = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }).format(new Date(info.default.data.created));

      const data = {
        age: info.default.data.age,
        avatar_image: info.default.data.avatar_image,
        country: info.default.data.country,
        created: formattedDate,
        display_name: info.default.data.display_name,
        gender: info.default.data.gender,
        interests: info.default.data.interests,
        nft: info.default.data.has_nft,
        ageverified: info.default.data.is_ageverified,
        ap: info.default.data.is_ap,
        creator: info.default.data.is_creator,
        greeter: info.default.data.is_greeter,
        host: info.default.data.is_host,
        staff: info.default.data.is_staff,
        vip: info.default.data.is_vip,
        legacy_cid: info.default.data.legacy_cid,
        online: info.default.data.online,
        looking_for: info.default.data.looking_for,
        relationship: info.default.data.relationship_status,
        state: info.default.data.state,
        tagline: info.default.data.tagline,
        thumbnail: info.default.data.thumbnail_url,
        username: info.default.data.username,
        vip_platform: info.default.data.vip_platform,
        vip_tier: info.default.data.vip_tier,
        last_login: info.last_login,
        current_room: info.room,
      } as UserCardProps;

      setCurrentUser(data);
    } catch (error) {
      // //console.log('GET AVATAR CARD: ', error);
    } finally {
      setLoad(false);
    }
  }

  return (
    <Container>
      <div className="title-area">
        <h2>{t('service_avatarcard_title')}</h2>
        <span></span>
      </div>

      <div className="inputArea">
        <input
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder={t('search_user_id')}
        />
        <button onClick={() => getAvatarCard()} disabled={load}>
          {load ? <FiLoader size={24} /> : <FiSearch size={24} />}
        </button>
      </div>

      {load && (
        <div className="loadArea">
          <FiLoader size={42} color="#f49c14" />
        </div>
      )}

      {currentUser && !load && (
        <div className="avatarCardInfo">
          <div className="cardHeader">
            <img src={currentUser.thumbnail} alt={currentUser.username} />
            <div className="cardHeaderContent">
              <div className="headerContentFirst">
                <div className="headerContentName">
                  <h3>{currentUser.display_name}</h3>
                  <span>@{currentUser.username}</span>
                  <span className="idArea">ID: {currentUser.legacy_cid}</span>
                </div>
                <div className="headerContentBadges">
                  {currentUser.ap && <span className="ap">AP</span>}
                  {currentUser.ageverified && <span className="age">AGE</span>}
                  {currentUser.vip && <span className="vip">VIP</span>}
                  {/* {currentUser.host && <span className="host">Host</span>} */}
                  {currentUser.nft && <span className="nft">NFT</span>}
                  {currentUser.creator && (
                    <span className="creator">Creator</span>
                  )}
                  {currentUser.staff && <span className="staff">Staff</span>}
                  {currentUser.greeter && (
                    <span className="greeter">Greeter</span>
                  )}
                  {/* <span className="marriage">Marriage</span> */}
                </div>
              </div>
              <div className="headerContentSecond">
                <div className="headerContentInfo">
                  <p>
                    <b>{t('gender')}:</b>{' '}
                    {currentUser.gender === 'f' ? 'Female' : 'Male'} |{' '}
                    <b>{t('age')}:</b> {currentUser.age || 'NA'}
                  </p>
                  <p>
                    <b>{t('country')}:</b> {currentUser.country || 'NA'}
                  </p>
                </div>
                <div className="dateArea">
                  <p>
                    {t('member_since')}: {currentUser.created}
                  </p>
                  <p>
                    {t('last_online')}: {currentUser.last_login}
                  </p>
                </div>
                <div className="headerContentInfoStatus">
                  <div>
                    <p className="status">
                      {currentUser.online ? (
                        <FiCheckCircle
                          size={16}
                          style={{ background: '#0eca6e' }}
                        />
                      ) : (
                        <FiSlash size={16} style={{ background: '#d8355d' }} />
                      )}
                      {currentUser.online ? 'Online' : 'Offline'}
                    </p>
                    {currentUser.current_room && (
                      <div className="currentRoom">
                        <img
                          src={currentUser.current_room.image}
                          alt={currentUser.current_room.name}
                        />
                        <div>
                          <p>{currentUser.current_room.name}</p>
                          <div className="currentRoomLine">
                            <h5>
                              {t('owner')}: {currentUser.current_room.owner}
                            </h5>
                            <a
                              href={currentUser.current_room.join_url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {t('join_the_room')}
                            </a>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*  */}
          <div className="cardBody">
            <div className="leftCardBody">
              <h4>Bio</h4>
              <p>{currentUser.tagline}</p>
              <div className="lineDivisor"></div>
              <h4>Interests</h4>
              <p>{currentUser.interests}</p>
            </div>
            <div className="rightCardBody">
              <h4>{t('affinity')}</h4>
              <ul>
                <li>
                  <b>{t('relationship')}:</b>{' '}
                  {RELATIONSHIP_STATUS[currentUser.relationship]}
                </li>
                <li>
                  <b>{t('here_for')}:</b> {HERE_STATUS[currentUser.looking_for]}
                </li>
              </ul>
            </div>
          </div>
          <div className="footerArea">
            <p>{t('visit_profile')}:</p>
            <a
              href={`https://avatars.imvu.com/${currentUser.username}`}
              target="_blank"
              rel="noreferrer"
            >
              Classic
            </a>
            <a
              href={`https://go.imvu.com/av/${currentUser.username}`}
              target="_blank"
              rel="noreferrer"
            >
              Next
            </a>
          </div>
        </div>
      )}
    </Container>
  );
}
