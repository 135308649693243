import { useState } from 'react';
import { FiLoader } from 'react-icons/fi';

import { Container } from './MyFriendIsStyles';
import { useTranslation } from 'react-i18next';
import { api } from '../../services/apiClient';
import toast from 'react-hot-toast';

interface SantaFriend {
  current: string;
  friend: string;
}

export function MyFriendIs() {
  const { t } = useTranslation();

  const [santaFriend, setSantaFriend] = useState<SantaFriend | null>(null);
  const [load, setLoad] = useState(false);
  const [name, setName] = useState('');
  const [code, setCode] = useState('');

  async function getSantaFriend() {
    setLoad(true);

    try {
      const res = await api.post('/santaFriend', {
        username: name.trim(),
        code: code.trim(),
      });

      if (res.data) {
        const data = {
          current: res.data.current,
          friend: res.data.friend,
        } as SantaFriend;

        setSantaFriend(data);
      }
    } catch (error) {
      // //console.log(error);
      toast.error(t('santa_friend_error'));
    } finally {
      setLoad(false);
    }
  }

  return (
    <Container>
      <div className="title-area">
        <h2>{t('service_friend_code_title')}</h2>
        <span></span>
      </div>

      <div className="columnArea">
        <div className="inputArea">
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder={t('santa_friend_username')}
          />
          <input
            type="text"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            placeholder={t('santa_friend_code')}
          />
          <button onClick={() => getSantaFriend()} disabled={load}>
            {load ? <FiLoader size={24} /> : t('santa_friend_show_now')}
          </button>
        </div>
        {santaFriend !== null && (
          <div className="santaRoom">
            <h3>{t('santa_friend_subtitle')}</h3>
            <div className="santaRoomParticipants">
              <div className="roomParticipantItem">
                <b>{santaFriend.current}</b> {t('santa_friend_message')}:
                <b>{santaFriend.friend}</b>
              </div>
            </div>
          </div>
        )}
      </div>
    </Container>
  );
}
