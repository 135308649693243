import { useState } from 'react';
import { api } from '../../services/apiClient';
import toast from 'react-hot-toast';
import { FiLoader, FiSearch } from 'react-icons/fi';

import { Container } from './ProfileOutfitStyles';
import { useTranslation } from 'react-i18next';
import { CardItem, CardItemProps } from '../../components/CardItem/CardItem';

export function ProfileOutfit() {
  const { t } = useTranslation();

  const [search, setSearch] = useState('');
  const [load, setLoad] = useState(false);
  const [image, setImage] = useState('');
  const [products, setProducts] = useState<CardItemProps[]>([]);

  async function getUserProfileOutfit() {
    if (!search) {
      toast.error(t('required_user_id'));
      return;
    }

    setProducts([]);

    setLoad(true);

    try {
      const res = await api.post('/profileOutfit', { username: search });

      const products = await res.data.profile.flatMap((subArray) => subArray);
      setImage(res.data.photo);
      setProducts(products);
    } catch (error) {
      // //console.log('ROOM FURNITURE: ', error);
    } finally {
      setLoad(false);
    }
  }

  return (
    <Container>
      <div className="title-area">
        <h2>{t('service_profileoutfit_title')}</h2>
        <span></span>
      </div>

      <div className="inputArea">
        <input
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder={t('search_user_id')}
        />
        <button onClick={() => getUserProfileOutfit()} disabled={load}>
          {load ? <FiLoader size={24} /> : <FiSearch size={24} />}
        </button>
      </div>
      {load && (
        <div className="loadArea">
          <FiLoader size={42} color="#f49c14" />
        </div>
      )}
      {products.length >= 1 && (
        <div className="areaProfile">
          {image && <img src={image} alt="profile" className="profile" />}
          <div className="areaCards">
            {products?.map((item, index) => (
              <CardItem key={index} {...item} />
            ))}
          </div>
        </div>
      )}
    </Container>
  );
}
