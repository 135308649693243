import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 30px 10px;
  margin: 0 auto;
  background: ${({ theme }) => theme.backgroundColor};

  div.title-area {
    display: flex;
    align-items: center;
    gap: 7px;
    margin-bottom: 30px;

    h2 {
      font-size: 1.75rem;
      font-weight: 500;
      color: ${({ theme }) => theme.textColor};
    }

    span {
      background: ${({ theme }) => theme.primary};
      height: 4px;
      width: 125px;
      border-radius: 2px;
    }
  }

  p.description {
    max-width: 650px;
    margin-top: 5px;
    margin-bottom: 30px;
    font-size: 0.9rem;
    color: ${({ theme }) => theme.textColor};
  }
`;
