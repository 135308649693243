import styled from 'styled-components';

export const Container = styled.div`
  max-width: 820px;
  width: 100%;
  height: 100vh;
  padding: 0 30px;
  margin: 0 auto;
  background: ${({ theme }) => theme.backgroundColor};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img#logo {
    max-width: 150px;
    border-radius: 3px;
    background: #fff;
    padding: 5px 7px;
    margin-bottom: 30px;
  }

  p {
    margin-bottom: 15px;
    text-align: center;
    color: ${({ theme }) => theme.textColor};
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    list-style: none;
    gap: 30px;
  }

  ul li a {
    font-weight: bold;
    transition: all ease 0.3s;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      transform: scale(1.1);
      color: ${({ theme }) => theme.primary};
      transition: all ease 0.3s;
    }
  }

  ul.flags img {
    width: 32px;
    border: 3px solid ${({ theme }) => theme.backgroundGray};
    border-radius: 18px;
    opacity: 0.7;
    cursor: pointer;
  }

  .btnTheme {
    font-size: 28px;
    cursor: pointer;
    margin-top: 10px;
  }
`;
