import axios, { AxiosError } from 'axios';
import { parseCookies, destroyCookie } from 'nookies';

import { AuthTokenError } from './errors/authTokenError';

function setupAPIClient(ctx = undefined) {
  let cookies = parseCookies(ctx);

  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      Authorization: `Bearer ${cookies['@searchvu.token']}`,
    },
  });

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error: AxiosError) => {
      if (error.response.status === 401) {
        if (typeof window !== undefined) {
          destroyCookie(null, '@searchvu.token', { path: '/' });
          window.location.href = '/';
        } else {
          return Promise.reject(new AuthTokenError());
        }
      }

      return Promise.reject(error);
    }
  );

  return api;
}

export { setupAPIClient };
