import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  ReactNode,
} from 'react';
import {
  ThemeProvider as StyledThemeProvider,
  DefaultTheme,
} from 'styled-components';

interface Theme {
  primary: string;
  backgroundColor: string;
  textColor: string;
  backgroundGray: string;
  // ALERTS
  error: string;
  success: string;
  alert: string;
}

const lightTheme: Theme = {
  primary: '#f49c14',
  backgroundColor: '#fff',
  textColor: '#0B0C20',
  backgroundGray: '#dedede',
  // ALERTS
  error: '#dc3545',
  success: '#28a745',
  alert: '#17a2b8',
};

const darkTheme: Theme = {
  primary: '#f49c14',
  backgroundColor: '#0B0C20',
  textColor: '#fff',
  backgroundGray: '#30333D',
  // ALERTS
  error: '#dc3545',
  success: '#28a745',
  alert: '#17a2b8',
};

interface ThemeContextProps {
  isDarkMode: boolean;
  toggleTheme: () => void;
}

export const ThemeContext = createContext<ThemeContextProps | undefined>(
  undefined
);

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const [isDarkMode, setIsDarkMode] = useState(true);

  useEffect(() => {
    const storedTheme = localStorage.getItem('searchVu@VuIsDarkMode');
    if (storedTheme !== null) {
      setIsDarkMode(JSON.parse(storedTheme));
    }
  }, []);

  const toggleTheme = () => {
    const newTheme = !isDarkMode;
    setIsDarkMode(newTheme);
    localStorage.setItem('searchVu@VuIsDarkMode', JSON.stringify(newTheme));
  };

  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleTheme }}>
      <StyledThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
        {children}
      </StyledThemeProvider>
    </ThemeContext.Provider>
  );
};

declare module 'styled-components' {
  export interface DefaultTheme extends Theme {}
}
