import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  body {
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: ${({ theme }) => theme.textColor};
    background: ${({ theme }) => theme.backgroundColor};
    padding: 0 2%;
  }

  code {
    font-family: 'Poppins', sans-serif;
  }

  button {
    cursor: pointer;
  }

  a {
    color: #0c0b20;
  }

  .contentArea {
    max-width: 1100px;
    width: 100%;
    min-height: 100vh;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .contentTop {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
`;
