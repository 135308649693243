import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiArrowLeft, FiArrowRight, FiLoader, FiSearch } from 'react-icons/fi';
import { IoShirtSharp, IoPerson } from 'react-icons/io5';
import { parseISO, formatDistanceToNow, format } from 'date-fns';
import { enUS, ptBR, es } from 'date-fns/locale';
import CrowlImageBg from '../../assets/icons/crowl_bg.png';
import EmptyRoomImg from '../../assets/icons/empty_room_img.png';
import EmptyOutfitImg from '../../assets/icons/empty_outfit_img.png';

import { Container, ModalOutfit } from './HistoryDuoRoomStyles';
import { useNavigate } from 'react-router-dom';
import { parseCookies } from 'nookies';
import { api } from '../../services/apiClient';
import toast from 'react-hot-toast';
import { CardItem } from '../../components/CardItem/CardItem';
import { AuthContext } from '../../contexts/AuthContexts';

type ResponsePagesTypes = {
  currentPage: number;
  totalPages: number;
  totalRecords: number;
};

export function HistoryDuoRoom() {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);

  const [isPremium, setIsPremium] = useState(false);

  const [totalSeen, setTotalSeen] = useState(0);
  const [user1, setUser1] = useState('');
  const [user2, setUser2] = useState('');

  const [search1, setSearch1] = useState('');
  const [search2, setSearch2] = useState('');
  const [loadOutfitImg, setLoadOutfitImg] = useState<number | boolean>(false);
  const [outfitSelected, setOutfitSelected] = useState<any | null>(null);
  const [showOutfitInfo, setShowOutfitInfo] = useState(false);
  const [loadProducts, setLoadProducts] = useState(false);
  const [allProducts, setAllProducts] = useState<any[]>([]);
  const [allRooms, setAllRooms] = useState<any[]>([]);
  const [load, setLoad] = useState(false);
  const [infoPages, setInfoPages] = useState<ResponsePagesTypes>({
    currentPage: 1,
    totalPages: 0,
    totalRecords: 0,
  });
  const [roomsData, setRoomsData] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    verifyPremium();
  }, []);

  async function verifyPremium() {
    const isPremium = user.subscriptions?.status === 'active' ? true : false;

    if (!isPremium) {
      navigate('/plans', { state: { premiumFunctionality: true } });
    }

    setIsPremium(isPremium);
  }

  function convertAndAdjustDate(isoDateString) {
    const dateObject = parseISO(isoDateString);

    const currentLanguage = t('current_language');
    let currentLocale = enUS;

    switch (currentLanguage) {
      case 'pt':
        currentLocale = ptBR;
        break;
      case 'es':
        currentLocale = es;
        break;
      default:
        currentLocale = enUS;
    }

    const formattedDate =
      currentLanguage === 'pt'
        ? format(dateObject, 'dd/MM/yyyy hh:mm:ss a', {
            locale: currentLocale,
          })
        : format(dateObject, 'MM/dd/yyyy hh:mm:ss a', {
            locale: currentLocale,
          });
    const timeAgo = formatDistanceToNow(dateObject, {
      addSuffix: true,
      locale: currentLocale,
    });

    return `${formattedDate} (${timeAgo})`;
  }

  function decodeHtmlEntities(text: string) {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = text;
    return textArea.value;
  }

  async function getHistoryUserRooms(page = 1, forceUpdate = false) {
    if (!search1 || !search2) {
      toast.error(t('required_user_id'));
      return;
    }

    if (search1.toLowerCase() === search2.toLowerCase()) return;

    if (forceUpdate) {
      setTotalSeen(0);
      setRoomsData({});
      setInfoPages({ currentPage: 1, totalPages: 0, totalRecords: 0 });
    }

    if (!forceUpdate && roomsData[page]) {
      setAllRooms(roomsData[page].results);
      setInfoPages({
        currentPage: roomsData[page].currentPage,
        totalPages: roomsData[page].totalPages,
        totalRecords: roomsData[page].totalRecords,
      });
      return;
    }

    setLoad(true);
    setAllRooms([]);
    try {
      const { '@searchvu.token': token } = parseCookies();

      const res = await api.post(
        '/history-duo-room',
        { username1: search1, username2: search2, page },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (res.data) {
        // console.log(res.data);

        if (res.data?.msg) {
          toast.error(res.data?.msg);
          return;
        }

        if (res.data?.results.length <= 0) {
          toast.error(t('service_history_empty'));
          return;
        }

        setTotalSeen((prev) => res.data?.totalTimesSeenTogether || prev);
        setUser1(search1);
        setUser2(search2);

        setAllRooms(res.data.results);
        setInfoPages({
          currentPage: res.data.currentPage,
          totalPages: res.data.totalPages,
          totalRecords: res.data.totalRecords,
        });

        setRoomsData((prevData) => ({
          ...prevData,
          [page]: {
            results: res.data.results,
            currentPage: res.data.currentPage,
            totalPages: res.data.totalPages,
            totalRecords: res.data.totalRecords,
          },
        }));
      }
    } catch (error) {
      //console.log(error);
      toast.error(t('service_error'));
    } finally {
      setLoad(false);
    }
  }

  async function getAllProductsInfo() {
    if (outfitSelected === null) return;
    setLoadProducts(true);

    try {
      const productIds = outfitSelected.outfit.products.map(
        (product) => product.productId
      );

      const productInfo = await api.post('/get-products-outfit', {
        products: productIds,
      });

      // console.log(productInfo);
      setAllProducts(productInfo.data.products.flat());
    } catch (error) {
      // //console.log('ERROR: ', error);
    } finally {
      setLoadProducts(false);
    }
  }

  function closeUserOutfit() {
    setOutfitSelected(null);
    setShowOutfitInfo(false);
    setAllProducts([]);
  }

  function handlePageChange(newPage) {
    if (newPage < 1 || newPage > infoPages.totalPages) return;
    getHistoryUserRooms(newPage);
  }

  async function handleGetSessionOutfits(roomSession: any, index: number) {
    const someAvatarImagesLoaded = roomSession.participants.some((user) => {
      return user.outfit.avatarImage !== '';
    });

    if (someAvatarImagesLoaded) {
      return;
    }

    setLoadOutfitImg(index);

    const updatedParticipants = await Promise.all(
      roomSession.participants.map(async (participant) => {
        if (
          participant.outfit &&
          participant.outfit.products.length > 0 &&
          participant.outfit.products[0].productId !== 'VIP'
        ) {
          const productIds = participant.outfit.products.map(
            (product) => product.productId
          );

          try {
            const response = await api.post('/get-avatar-image', {
              products: productIds,
            });

            if (response.data && response.data.avatarImage) {
              participant.outfit = {
                products: [...participant.outfit.products],
                avatarImage: response.data.avatarImage,
              };
            } else {
              participant.outfit.avatarImage = '';
            }
          } catch (error) {
            console.error('Error fetching avatar image:', error);
            participant.outfit.avatarImage = '';
          } finally {
            // setLoadOutfitImg(false);
          }
        } else {
          participant.outfit.avatarImage = '';
        }

        return participant;
      })
    );

    setRoomsData((prevData) => {
      const updatedRoomsData = { ...prevData };

      if (updatedRoomsData[infoPages.currentPage]) {
        updatedRoomsData[infoPages.currentPage].results = updatedRoomsData[
          infoPages.currentPage
        ].results.map((room, roomIndex) => {
          if (roomIndex === index) {
            return {
              ...room,
              participants: updatedParticipants,
            };
          }
          return room;
        });
      }

      return updatedRoomsData;
    });

    setAllRooms((prevRooms) => {
      return prevRooms.map((room, roomIndex) => {
        if (roomIndex === index) {
          return {
            ...room,
            participants: updatedParticipants,
          };
        }
        return room;
      });
    });

    setLoadOutfitImg(false);
  }

  if (!isPremium) return <></>;

  return (
    <>
      {outfitSelected !== null && (
        <ModalOutfit>
          <button className="btnClose" onClick={closeUserOutfit}>
            {t('serive_history_back_outfit')}
          </button>
          <div className="areaProfile">
            <div className="imageAreaProfile">
              {outfitSelected.outfit.avatarImage ? (
                <img
                  src={outfitSelected.outfit.avatarImage}
                  alt="profile"
                  className="profile"
                />
              ) : (
                <img src={EmptyOutfitImg} alt="profile" className="profile" />
              )}
              <a
                href={`https://go.imvu.com/av/${outfitSelected.username}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                @{outfitSelected.username}
              </a>
            </div>
            {!showOutfitInfo && (
              <div className="areaButtonOutfit">
                <p>{t('serive_history_details_outfit')}</p>
                <button
                  onClick={() => {
                    setShowOutfitInfo(true);
                    getAllProductsInfo();
                  }}
                >
                  {t('serive_history_more_outfit')}
                </button>
              </div>
            )}
            {loadProducts && (
              <div className="loadArea">
                <FiLoader size={42} color="#f49c14" />
              </div>
            )}
            {!loadProducts && allProducts.length >= 1 && (
              <div className="areaCards">
                {allProducts?.map((item, index) => (
                  <CardItem key={index} {...item} />
                ))}
              </div>
            )}
          </div>
        </ModalOutfit>
      )}
      {outfitSelected === null && (
        <Container>
          <div className="title-area">
            <h2>{t('service_history_duo_title')}</h2>
            <span></span>
          </div>

          <div className="inputArea">
            <input
              type="text"
              value={search1}
              onChange={(e) => setSearch1(e.target.value)}
              placeholder={t('search_user_id')}
            />
            <input
              type="text"
              value={search2}
              onChange={(e) => setSearch2(e.target.value)}
              placeholder={t('search_user_id')}
            />
            <button
              onClick={() => getHistoryUserRooms(1, true)}
              disabled={load}
            >
              {load ? <FiLoader size={24} /> : <FiSearch size={24} />}
            </button>
          </div>

          {totalSeen > 0 && (
            <>
              <h3 id="totalSeen">
                Total: {totalSeen} | ({user1} & {user2})
              </h3>
              <p className="obsAlert">{t('service_duo_alert')}</p>
            </>
          )}

          {load && (
            <div className="loadArea">
              <FiLoader size={42} color="#f49c14" />
            </div>
          )}

          {allRooms.length > 0 && (
            <div className="areaRooms">
              {allRooms?.map((it, sessionIndex) => (
                <div className="roomItem" key={sessionIndex}>
                  <div className="imgRoom">
                    {it.room.room_image ? (
                      <img src={it.room.room_image} alt={it.room.room_name} />
                    ) : (
                      <img src={EmptyRoomImg} alt={it.room.room_name} />
                    )}
                    <p className="lastDate">
                      {convertAndAdjustDate(it.last_seen)}
                    </p>
                  </div>
                  <div className="roomContent">
                    <div className="roomTitle">
                      <h3>
                        {decodeHtmlEntities(it.room.room_name).length > 45
                          ? `${decodeHtmlEntities(it.room.room_name).slice(
                              0,
                              45
                            )}...`
                          : decodeHtmlEntities(it.room.room_name)}
                      </h3>
                      <div className="btnHeaderArea">
                        <button
                          className="btnOutfits"
                          onClick={() =>
                            handleGetSessionOutfits(it, sessionIndex)
                          }
                          disabled={loadOutfitImg !== false ? true : false}
                        >
                          {loadOutfitImg !== false ? (
                            <FiLoader size={24} />
                          ) : (
                            t('service_load_outfit')
                          )}
                        </button>
                        <a
                          href={`https://go.imvu.com/chat/room-${it.room.room_id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {t('join_the_room')}
                        </a>
                      </div>
                    </div>
                    <div className="usersContent">
                      {it.participants.map((user, index) => (
                        <div className="userRow" key={index}>
                          <div className="avInfo">
                            <img
                              src={
                                user.profile_picture === 'VIP'
                                  ? CrowlImageBg
                                  : user.profile_picture
                              }
                              alt={user.username}
                            />
                            {user.username === 'VIP' && (
                              <p>[̲̅=̲̅=̲̅=̲̅=̲̅=̲̅ρ̲̅я̲̅є̲̅м̲̅i̲̅υ̲̅м̲̅=̲̅=̲̅=̲̅=̲̅=̲̅]</p>
                            )}
                            {user.username !== 'VIP' && (
                              <p>
                                {user?.username?.indexOf('Guest_') === 0
                                  ? user?.username?.split('Guest_')[1]
                                  : user?.username}
                              </p>
                            )}
                          </div>
                          <div className="btnsUser">
                            {user.username !== 'VIP' && (
                              <>
                                {user.outfit?.products[0] === 'VIP' && (
                                  <a
                                    onClick={() => {
                                      toast.error(
                                        t('service_history_protection')
                                      );
                                    }}
                                    style={{ opacity: 0.5 }}
                                  >
                                    {/* Outfit */}
                                    <IoShirtSharp size={20} />
                                  </a>
                                )}
                                {user.outfit?.products.length > 0 &&
                                  user.outfit?.products[0] !== 'VIP' && (
                                    <>
                                      {loadOutfitImg === sessionIndex ? (
                                        <FiLoader size={24} />
                                      ) : (
                                        <a
                                          style={{
                                            opacity:
                                              user.outfit?.avatarImage === ''
                                                ? 0.5
                                                : 1,
                                          }}
                                          onClick={() => {
                                            if (
                                              user.outfit?.avatarImage === ''
                                            ) {
                                              toast.error(
                                                t('service_load_avatar_image')
                                              );
                                              return;
                                            }

                                            setOutfitSelected({
                                              username: user.username,
                                              outfit: user.outfit,
                                            });
                                          }}
                                        >
                                          <IoShirtSharp size={20} />
                                        </a>
                                      )}
                                    </>
                                  )}
                                <a
                                  href={`https://go.imvu.com/av/${user.username}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <IoPerson size={20} />
                                </a>
                              </>
                            )}
                          </div>
                        </div>
                      ))}
                      {/* <p className="lastDate">
                        {convertAndAdjustDate(it.last_seen)}
                      </p> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          {!load &&
            infoPages.totalPages >= 1 &&
            allRooms.length > 0 &&
            loadOutfitImg === false && (
              <div className="areaPages">
                {infoPages.currentPage > 1 && (
                  <button
                    onClick={() => handlePageChange(infoPages.currentPage - 1)}
                    disabled={infoPages.currentPage <= 1}
                  >
                    <FiArrowLeft />
                  </button>
                )}
                <p>
                  {infoPages.currentPage} Page of {infoPages.totalPages}
                </p>
                {infoPages.currentPage < infoPages.totalPages && (
                  <button
                    onClick={() => handlePageChange(infoPages.currentPage + 1)}
                    disabled={infoPages.currentPage >= infoPages.totalPages}
                  >
                    <FiArrowRight />
                  </button>
                )}
              </div>
            )}
        </Container>
      )}
    </>
  );
}
