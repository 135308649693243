import { useEffect, useState } from 'react';
import { FiLoader } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { api } from '../../services/apiClient';
import { Container } from './ResetPasswordStyles';
import { useNavigate } from 'react-router-dom';

export const ResetPassword = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const tokenParam = params.get('token');
    const emailParam = params.get('email');

    if (!tokenParam || !emailParam) {
      navigate('/sign-in');
    } else {
      setToken(tokenParam);
      setEmail(emailParam);
    }
  }, []);

  async function handleResetPassword(event) {
    event.preventDefault();
    if (!newPassword || !confirmPassword) {
      // toast.error(t('auth_alert_required'));
      window.alert(t('auth_alert_required'));
      navigate('/sign-in');
      return;
    }

    if (newPassword !== confirmPassword) {
      toast.error(t('auth_alert_password_mismatch'));
      return;
    }

    setLoading(true);
    try {
      await api.post('reset-password', {
        email,
        token,
        newPassword,
      });
      // toast.success(t('reset_password_success'));
      window.alert(t('reset_password_success'));
      setNewPassword('');
      setConfirmPassword('');
      navigate('/sign-in');
    } catch (error) {
      // toast.error(t('reset_password_error'));
      window.alert(t('reset_password_error'));
      navigate('/sign-in');
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container>
      <div className="title-area">
        <h2>{t('reset_password')}</h2>
        <span></span>
      </div>

      <form onSubmit={handleResetPassword} className="inputArea">
        <input
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          placeholder={t('reset_password_input')}
        />
        <input
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          placeholder={t('reset_password_confirm_input')}
        />
        {newPassword && confirmPassword === newPassword && (
          <button
            id="btn"
            // onClick={handleResetPassword}
            type="submit"
            disabled={confirmPassword !== newPassword || loading}
          >
            {loading ? <FiLoader size={24} /> : t('reset_password_btn')}
          </button>
        )}
      </form>
    </Container>
  );
};
