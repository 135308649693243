import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 30px 10px;
  margin: 0 auto;
  background: ${({ theme }) => theme.backgroundColor};

  div.title-area {
    display: flex;
    align-items: center;
    gap: 7px;
    margin-bottom: 30px;

    h2 {
      font-size: 1.75rem;
      font-weight: 500;
    }

    span {
      background: ${({ theme }) => theme.primary};
      height: 4px;
      width: 125px;
      border-radius: 2px;
    }
  }

  .columnArea {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 30px;
  }

  .inputArea {
    max-width: 400px;
    width: 100%;
    display: flex;
    margin-bottom: 30px;
    flex-direction: column;
    gap: 10px;

    input {
      width: 100%;
      background: ${({ theme }) => theme.backgroundGray};
      border: 0;
      border-radius: 3px;
      height: 40px;
      padding: 0 10px;
      font-size: 1rem;
      font-weight: bold;
      color: ${({ theme }) => theme.textColor};
      outline: none;
    }

    div.btnArea {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-top: 10px;
    }

    button {
      height: 40px;
      width: 100%;
      border: 0;
      background: ${({ theme }) => theme.primary};
      border-radius: 3px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
    }
  }

  div.participantsArea {
    border: 1px solid ${({ theme }) => theme.primary};
    border-radius: 5px;
    padding: 20px 10px;
    margin: 30px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;

    div.participantItem {
      display: flex;
      flex-direction: row;
      align-items: center;
      background: ${({ theme }) => theme.backgroundGray};
      padding: 2px 7px;
      border-radius: 2px;
      gap: 5px;
      cursor: pointer;

      svg {
        background: ${({ theme }) => theme.primary};
        width: 14px;
        height: 14px;
        border-radius: 7px;
        display: flex;
        padding: 1px;
        color: #ededed;
      }
    }
  }

  .santaRoom {
    max-width: 500px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
    flex-direction: column;
    gap: 10px;
    background: ${({ theme }) => theme.backgroundGray};
    box-shadow: 1px 1px 15px ${({ theme }) => theme.backgroundGray};
    padding: 20px 10px;
    border-radius: 5px;

    h3 {
      color: ${({ theme }) => theme.primary};
      font-size: 1.5rem;
    }

    p {
      font-size: 0.75rem;
      font-weight: bold;
      color: ${({ theme }) => theme.textColor};
      margin: -5px 0 10px 0;
    }

    div.roomParticipantItem {
      display: flex;
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 7px;
      border-bottom: 1px solid ${({ theme }) => theme.primary};
      padding-bottom: 5px;
      margin-bottom: 10px;
      cursor: pointer;
      padding: 2px 5px;
      transition: all ease 0.5s;

      &:hover {
        background-color: ${({ theme }) => theme.backgroundColor};
        border-radius: 2px;
        transition: all ease 0.5s;
      }

      svg {
        background: ${({ theme }) => theme.primary};
        width: 20px;
        height: 20px;
        border-radius: 10px;
        display: flex;
        padding: 3px;
        color: #fff;
      }
    }
  }

  div.loadArea {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div.areaCards {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 30px;
    flex-wrap: wrap;
    margin: 30px 0 60px 0;
  }
`;
