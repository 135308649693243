import { useTranslation } from 'react-i18next';
import { Container } from './TermsOfServicesStyles';

export function TermsOfServices() {
  const { t } = useTranslation();

  return (
    <Container>
      <div className="title-area">
        <h2>{t('terms_title')}</h2>
        <span></span>
      </div>
      <h3 className="subtitle">1. {t('terms_sub_01')}</h3>
      <p className="description">{t('terms_desc_01')}</p>
      <h3 className="subtitle">2. {t('terms_sub_02')}</h3>
      <p className="description">{t('terms_desc_02')}</p>
      <h3 className="subtitle">3. {t('terms_sub_03')}</h3>
      <p className="description">{t('terms_desc_03')}</p>
      <h3 className="subtitle">4. {t('terms_tokens_premium')}</h3>
      <p className="description">{t('token_disclaimer')}</p>
      <h3 className="subtitle">5. {t('terms_buy_tokens')}</h3>
      <p className="description">{t('token_disclaimer_buy')}</p>
      <h3 className="subtitle">6. {t('token_awards')}</h3>
      <p className="description">{t('token_awards_desc')}</p>
      <h3 className="subtitle">7. {t('terms_sub_06')}</h3>
      <p className="description">{t('terms_desc_06')}</p>
      {/* <h3 className="subtitle">7. {t('terms_sub_07')}</h3> */}
      {/* <p className="description">{t('terms_desc_07')}</p> */}
      {/* <p className="description">{t('terms_desc_07_1')}</p> */}
      <h3 className="subtitle">8. {t('terms_sub_08')}</h3>
      <p className="description">{t('terms_desc_08')}</p>
      <h3 className="subtitle">9. {t('terms_sub_14')}</h3>
      <p className="description">{t('terms_desc1_14')}</p>
      <p className="description">{t('terms_desc2_14')}</p>
      <h3 className="subtitle">10. {t('terms_sub_09')}</h3>
      <p className="description">{t('terms_desc_09')}</p>
      <h3 className="subtitle">11. {t('terms_sub_10')}</h3>
      <p className="description">{t('terms_desc_10')}</p>
      <h3 className="subtitle">12. {t('terms_sub_11')}</h3>
      <p className="description">{t('terms_desc_11')}</p>
      <h3 className="subtitle">13. {t('terms_sub_12')}</h3>
      <p className="description">{t('terms_desc_12')}</p>
      <h3 className="subtitle">14. {t('terms_sub_13')}</h3>
      <p className="description">{t('terms_desc_13')}</p>
    </Container>
  );
}
