import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiLoader, FiSearch } from 'react-icons/fi';
import { IoShirtSharp, IoPerson } from 'react-icons/io5';
import EmptyRoomImg from '../../assets/icons/empty_room_img.png';
import EmptyOutfitImg from '../../assets/icons/empty_outfit_img.png';

import { Container, ModalOutfit } from './InspectLiveRoomStyles';
import { api } from '../../services/apiClient';
import toast from 'react-hot-toast';
import { CardItem } from '../../components/CardItem/CardItem';
import { AuthContext } from '../../contexts/AuthContexts';
import { useNavigate } from 'react-router-dom';
import { parseCookies } from 'nookies';

export function InspectLiveRoom() {
  const { t } = useTranslation();

  const { user } = useContext(AuthContext);
  const [isPremium, setIsPremium] = useState(false);

  const [search, setSearch] = useState('');
  const [loadOutfitImg, setLoadOutfitImg] = useState<number | boolean>(false);
  const [outfitSelected, setOutfitSelected] = useState<any | null>(null);
  const [showOutfitInfo, setShowOutfitInfo] = useState(false);
  const [loadProducts, setLoadProducts] = useState(false);
  const [allProducts, setAllProducts] = useState<any[]>([]);
  const [allRooms, setAllRooms] = useState<any[]>([]);
  const [load, setLoad] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    verifyPremium();
  }, []);

  async function verifyPremium() {
    const isPremium = user.subscriptions?.status === 'active' ? true : false;

    if (!isPremium) {
      navigate('/plans', { state: { premiumFunctionality: true } });
    }

    setIsPremium(isPremium);
  }

  function decodeHtmlEntities(text: string) {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = text;
    return textArea.value;
  }

  async function getInspectRoom() {
    if (!search) {
      toast.error(t('required_room_id'));
      return;
    }

    setLoad(true);
    setAllRooms([]);
    try {
      const { '@searchvu.token': token } = parseCookies();

      const res = await api.post(
        '/inspect-live-room',
        { roomId: search },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (res.data) {
        if (!res.data.results?.room) {
          toast.error(t('service_empty'));
          return;
        }

        // console.log(res.data);
        setAllRooms([res.data.results]);
      }
    } catch (error) {
      //console.log(error);
      toast.error(t('service_error'));
    } finally {
      setLoad(false);
    }
  }

  async function getAllProductsInfo() {
    if (outfitSelected === null) return;
    setLoadProducts(true);

    try {
      const productIds = outfitSelected?.outfit?.products.map(
        (product) => product.productId
      );

      const productInfo = await api.post('/get-products-outfit', {
        products: productIds,
      });

      // console.log(productInfo);
      setAllProducts(productInfo.data.products.flat());
    } catch (error) {
      // //console.log('ERROR: ', error);
    } finally {
      setLoadProducts(false);
    }
  }

  function closeUserOutfit() {
    setOutfitSelected(null);
    setShowOutfitInfo(false);
    setAllProducts([]);
  }

  async function handleGetSessionOutfits(roomSession: any, index: number) {
    const someAvatarImagesLoaded = roomSession.participants.some((user) => {
      return user.outfit.avatarImage !== '';
    });

    if (someAvatarImagesLoaded) {
      return;
    }

    setLoadOutfitImg(index);

    const updatedParticipants = await Promise.all(
      roomSession.participants.map(async (participant) => {
        if (
          participant.outfit?.products &&
          participant.outfit.products.length > 0
        ) {
          const productIds = participant.outfit.products.map(
            (product) => product.productId
          );

          try {
            const response = await api.post('/get-avatar-image', {
              products: productIds,
            });

            if (response.data && response.data.avatarImage) {
              participant.outfit = {
                products: [...participant.outfit.products],
                avatarImage: response.data.avatarImage,
              };
            } else {
              participant.outfit.avatarImage = '';
            }
          } catch (error) {
            console.error('Error fetching avatar image:', error);
            participant.outfit.avatarImage = '';
          } finally {
            setLoadOutfitImg(false);
          }
        } else {
          participant.outfit.avatarImage = '';
        }

        return participant;
      })
    );

    setAllRooms((prevRooms) => {
      return prevRooms.map((room, roomIndex) => {
        if (roomIndex === index) {
          return {
            ...room,
            participants: updatedParticipants,
          };
        }
        return room;
      });
    });

    setLoadOutfitImg(false);
  }

  if (!isPremium) return <></>;

  return (
    <>
      {outfitSelected !== null && (
        <ModalOutfit>
          <button className="btnClose" onClick={closeUserOutfit}>
            {t('serive_history_back_outfit')}
          </button>
          <div className="areaProfile">
            <div className="imageAreaProfile">
              {outfitSelected.outfit.avatarImage ? (
                <img
                  src={outfitSelected.outfit.avatarImage}
                  alt="profile"
                  className="profile"
                />
              ) : (
                <img src={EmptyOutfitImg} alt="profile" className="profile" />
              )}
              <a
                href={`https://go.imvu.com/av/${outfitSelected.username}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                @{outfitSelected.username}
              </a>
            </div>
            {!showOutfitInfo && (
              <div className="areaButtonOutfit">
                <p>{t('serive_history_details_outfit')}</p>
                <button
                  onClick={() => {
                    setShowOutfitInfo(true);
                    getAllProductsInfo();
                  }}
                >
                  {t('serive_history_more_outfit')}
                </button>
              </div>
            )}
            {loadProducts && (
              <div className="loadArea">
                <FiLoader size={42} color="#f49c14" />
              </div>
            )}
            {!loadProducts && allProducts.length >= 1 && (
              <div className="areaCards">
                {allProducts?.map((item, index) => (
                  <CardItem key={index} {...item} />
                ))}
              </div>
            )}
          </div>
        </ModalOutfit>
      )}
      {outfitSelected === null && (
        <Container>
          <div className="title-area">
            <h2>{t('service_inspect_live_room')}</h2>
            <span></span>
          </div>

          <div className="inputArea">
            <input
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder={t('search_room_id')}
            />
            <button onClick={() => getInspectRoom()} disabled={load}>
              {load ? <FiLoader size={24} /> : <FiSearch size={24} />}
            </button>
          </div>

          {load && (
            <div className="loadArea">
              <FiLoader size={42} color="#f49c14" />
            </div>
          )}

          {allRooms.length > 0 && (
            <div className="areaRooms">
              {allRooms?.map((it, sessionIndex) => (
                <div className="roomItem" key={sessionIndex}>
                  <div className="imgRoom">
                    {it.room.room_image ? (
                      <img src={it.room.room_image} alt={it.room.room_name} />
                    ) : (
                      <img src={EmptyRoomImg} alt={it.room.room_name} />
                    )}
                  </div>
                  <div className="roomContent">
                    <div className="roomTitle">
                      <h3>
                        {decodeHtmlEntities(it.room.room_name).length > 45
                          ? `${decodeHtmlEntities(it.room.room_name).slice(
                              0,
                              45
                            )}...`
                          : decodeHtmlEntities(it.room.room_name)}
                      </h3>
                      <div className="btnHeaderArea">
                        <p className="totalParticipants">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="size-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z"
                            />
                          </svg>
                          Total: {it.participants.length}
                        </p>
                        <button
                          className="btnOutfits"
                          onClick={() =>
                            handleGetSessionOutfits(it, sessionIndex)
                          }
                          disabled={loadOutfitImg !== false ? true : false}
                        >
                          {loadOutfitImg !== false ? (
                            <FiLoader size={24} />
                          ) : (
                            t('service_load_outfit')
                          )}
                        </button>
                        <a
                          href={`https://go.imvu.com/chat/room-${it.room.room_id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {t('join_the_room')}
                        </a>
                      </div>
                    </div>
                    <div className="usersContent">
                      {it.participants.map((user, index) => (
                        <div className="userRow" key={index}>
                          <div className="avInfo">
                            <img
                              src={user.profile_picture}
                              alt={user.username}
                            />
                            <p>
                              {user?.username?.indexOf('Guest_') === 0
                                ? user?.username?.split('Guest_')[1]
                                : user?.username}
                            </p>
                          </div>
                          <div className="btnsUser">
                            {user?.outfit?.products &&
                              user.outfit?.products?.length > 0 && (
                                <>
                                  {loadOutfitImg === sessionIndex ? (
                                    <FiLoader size={24} />
                                  ) : (
                                    <a
                                      style={{
                                        opacity:
                                          user.outfit?.avatarImage === ''
                                            ? 0.5
                                            : 1,
                                      }}
                                      onClick={() => {
                                        if (user.outfit?.avatarImage === '') {
                                          toast.error(
                                            t('service_load_avatar_image')
                                          );
                                          return;
                                        }

                                        setOutfitSelected({
                                          username: user.username,
                                          outfit: user.outfit,
                                        });
                                      }}
                                    >
                                      <IoShirtSharp size={20} />
                                    </a>
                                  )}
                                </>
                              )}
                            {/* <a
                                  href={`https://avatars.imvu.com/${user.username}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Classic
                                </a> */}
                            <a
                              href={`https://go.imvu.com/av/${user.username}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {/* {t('profile')} */}
                              <IoPerson size={20} />
                            </a>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </Container>
      )}
    </>
  );
}
