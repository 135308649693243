// src/contexts/LanguageContext.tsx
import React, { createContext, useState, useEffect, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface LanguageContextProps {
  language: 'en' | 'es' | 'pt';
  changeLanguage: (language: 'en' | 'es' | 'pt') => void;
}

const LanguageContext = createContext<LanguageContextProps | undefined>(
  undefined
);

const LanguageProvider = ({ children }: { children: ReactNode }) => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState<'en' | 'es' | 'pt'>('en');

  useEffect(() => {
    const savedLanguage = localStorage.getItem('searchVu@language');
    if (savedLanguage) {
      setLanguage(savedLanguage as 'en' | 'es' | 'pt');
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  const changeLanguage = (newLanguage: 'en' | 'es' | 'pt') => {
    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
    localStorage.setItem('searchVu@language', newLanguage);
  };

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export { LanguageContext, LanguageProvider };
