import { Container } from './HomeStyles';

import {
  ServiceCard,
  ServiceCardProps,
} from '../../components/ServiceCard/ServiceCard';
import { useTranslation } from 'react-i18next';
import Icon_Speaker from '../../assets/icons/speaker.png';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { AuthContext } from '../../contexts/AuthContexts';
import { useContext } from 'react';

const VIP_SERVICES: ServiceCardProps[] = [
  {
    id: 1,
    title: 'service_furniture_title',
    description: 'service_furniture_description',
    icon: 'furniture',
    path: '/room-furniture',
  },
  {
    id: 2,
    title: 'service_song_title',
    description: 'service_song_description',
    icon: 'music',
    path: '/media-player',
  },
  // {
  //   id: 3,
  //   title: 'service_active_classic_room_title',
  //   description: 'service_active_classic_room_description',
  //   icon: 'scan',
  //   path: '/scan-classic-rooms',
  // },
  // {
  //   id: 4,
  //   title: 'service_active_live_room_title',
  //   description: 'service_active_live_room_description',
  //   icon: 'scan',
  //   path: '/scan-live-rooms',
  // },
  {
    id: 5,
    title: 'service_history_room_title',
    description: 'service_history_room_description',
    icon: 'scan',
    path: '/history-rooms',
    // enabled: false,
    // maintenance: true,
  },
  {
    id: 6,
    title: 'service_history_outfit_title',
    description: 'service_history_outfit_description',
    icon: 'outfit',
    path: '/history-outfit',
    // enabled: false,
    // maintenance: true,
  },
  {
    id: 7,
    title: 'service_current_outift_title',
    description: 'service_current_outfit_description',
    icon: 'outfit',
    path: '/hidden-current-outfit',
  },
  {
    id: 8,
    // title: 'service_protect_title',
    title: 'hidden_title_card',
    description: 'service_protect_description',
    icon: 'protection',
    path: '/user-protection',
  },
  {
    id: 9,
    title: 'service_inspect_live_room',
    description: 'service_inspect_live_room_desc',
    icon: 'spy',
    path: '/inspect-live-room',
  },
  {
    id: 10,
    title: 'service_inspect_photo',
    description: 'service_inspect_photo_desc',
    icon: 'posts',
    path: '/inspect-photo',
  },
  {
    id: 11,
    title: 'service_history_duo_title',
    description: 'service_history_duo_desc',
    icon: 'duo',
    path: '/history-duo-room',
  },
  {
    id: 12,
    title: 'service_pid_hidden_title',
    description: 'service_pid_hidden_desc',
    icon: 'catalog',
    path: '/product-user-hidden',
  },
  // CREATE PAGES AND INTEGRATION
  // {
  //   id: 13,
  //   title: 'service_spy_user_title',
  //   description: 'service_spy_user_description',
  //   icon: 'spy',
  //   path: '/private-room-history',
  // },
  // {
  //   id: 14,
  //   title: 'catalog_title',
  //   description: 'catalog_description',
  //   icon: 'catalog',
  //   path: '/catalog-viewer',
  //   enabled: false,
  // },
  {
    id: 15,
    title: 'service_friend_title',
    description: 'service_friend_description',
    icon: 'friends',
    path: '/secret-santa',
  },
  {
    id: 16,
    title: 'token_redeem',
    description: 'token_redeem_empty',
    icon: 'rewards',
    path: '/token-rewards',
    enabled: false,
  },
];

const PUBLIC_SERVICES: ServiceCardProps[] = [
  // {
  //   id: 1,
  //   title: 'service_private_room_title',
  //   description: 'service_private_room_description',
  //   icon: 'house',
  //   path: '/private-room',
  //   enabled: false,
  // },
  // {
  //   id: 2,
  //   title: 'service_lucky_post_title',
  //   description: 'service_lucky_post_description',
  //   icon: 'robot',
  //   path: '/feed-sort',
  //   enabled: false,
  // },
  {
    id: 3,
    title: 'service_inspect_classic_room',
    description: 'service_inspect_classic_room_desc',
    icon: 'spy',
    path: '/inspect-classic-room',
  },
  {
    id: 4,
    title: 'service_avatarcard_title',
    description: 'service_avatarcard_description',
    icon: 'user_card',
    path: '/avatar-card',
  },
  {
    id: 5,
    title: 'service_profileoutfit_title',
    description: 'service_profileoutfit_description',
    icon: 'outfit',
    path: '/profile-outfit',
  },
  // 3 - CORREÇÃO DE CÓDIGO
  // {
  //   id: 6,
  //   title: 'service_wishlist_title',
  //   description: 'service_wishlist_description',
  //   icon: 'favorite',
  //   path: '/avatar-wishlist',
  // },
  {
    id: 7,
    title: 'service_getroom_title',
    description: 'service_getroom_description',
    icon: 'furniture',
    path: '/show-room',
  },
  // VIEW NAME HISTORY
  // {
  //   id: 8,
  //   title: 'service_name_check_title',
  //   description: 'service_name_check_description',
  //   icon: 'checkname',
  //   path: '/check-name',
  // },
  // 6 - CORREÇÃO DE CÓDIGO
  {
    id: 9,
    title: 'service_derivabletree_title',
    description: 'service_derivabletree_description',
    icon: 'tree',
    path: '/derivable-tree',
    enabled: false,
  },
  // {
  //   id: 10,
  //   title: 'service_lowercost_title',
  //   description: 'service_lowercost_description',
  //   icon: 'cost',
  //   path: '/lower-cost',
  //   enabled: false,
  // },
  // {
  //   id: 11,
  //   title: 'service_posts_title',
  //   description: 'service_posts_description',
  //   icon: 'posts',
  //   path: '/view-posts',
  //   enabled: false,
  // },
  {
    id: 12,
    title: 'service_friend_code_title',
    description: 'service_friend_code_description',
    icon: 'friends_code',
    path: '/my-friend',
  },
];

const OTHER_SERVICES: ServiceCardProps[] = [
  // {
  //   id: 1,
  //   title: 'service_friend_code_title',
  //   description: 'service_friend_code_description',
  //   icon: 'friends_code',
  //   path: '/my-friend',
  // },
  // {
  //   id: 2,
  //   title: 'service_password_title',
  //   description: 'service_password_description',
  //   icon: 'password',
  //   path: '/password-generator',
  // },
  // 3 - ADICIONAR FUNCIONALIDADE
  // {
  //   id: 3,
  //   title: 'service_symbol_title',
  //   description: 'service_symbol_description',
  //   icon: 'symbol',
  //   path: '/symbol-creator',
  //   enabled: false,
  // },
  // {
  //   id: 3,
  //   title: 'service_store_title',
  //   description: 'service_store_description',
  //   icon: 'store',
  //   path: '/store',
  // },
];

const ALERT_MESSAGE = true;

export function Home() {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);

  const navigate = useNavigate();

  return (
    <Container>
      <div className="banner-area">
        <img src={Icon_Speaker} alt="speaker" />
        <div>
          <h2>{t('alert_banner_message_title')}</h2>
          <p>{t('alert_banner_message_desc')}</p>
          {ALERT_MESSAGE && <span>* {t('alert_banner_message_new')}</span>}
        </div>
      </div>

      <div className="title-area">
        <h2>{t('title_vip')}</h2>
        <span></span>
      </div>

      <div className="area-video-content">
        <p>
          <b>{t('video_content')}:</b> {t('video_content_desc')}
        </p>
        <button
          onClick={() => {
            if (!user) {
              toast.error(t('alert_logged'));
              navigate('/sign-in');
              return;
            }

            navigate('/videos');
          }}
        >
          {t('btn_access')}
        </button>
      </div>

      <div className="areaCards">
        {VIP_SERVICES.map((service, index) => (
          <ServiceCard {...service} key={index} />
        ))}
      </div>

      <div className="title-area">
        <h2>{t('title_public')}</h2>
        <span></span>
      </div>
      <div className="areaCards">
        {PUBLIC_SERVICES.map((service, index) => (
          <ServiceCard {...service} key={index} />
        ))}
      </div>

      {
        // <div className="title-area">
        // <h2>{t('title_friend')}</h2>
        // <span></span>
        // </div>
        // <div className="areaCards">
        // {OTHER_SERVICES.map((service, index) => (
        // <ServiceCard {...service} key={index} />
        // ))}
        // </div>
      }
    </Container>
  );
}
