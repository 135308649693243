import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 30px 10px;
  margin: 0 auto;
  background: ${({ theme }) => theme.backgroundColor};

  div.title-area {
    display: flex;
    align-items: center;
    gap: 7px;
    margin-bottom: 30px;

    h2 {
      font-size: 1.75rem;
      font-weight: 500;
    }

    span {
      background: ${({ theme }) => theme.primary};
      height: 4px;
      width: 125px;
      border-radius: 2px;
    }
  }

  .inputArea {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    input {
      max-width: 86%;
      width: 100%;
      background: ${({ theme }) => theme.backgroundGray};
      border: 0;
      border-radius: 3px;
      height: 40px;
      padding: 0 10px;
      font-size: 1rem;
      font-weight: bold;
      color: ${({ theme }) => theme.textColor};
      outline: none;
    }

    button {
      height: 40px;
      max-width: 12%;
      width: 100%;
      border: 0;
      background: ${({ theme }) => theme.primary};
      border-radius: 3px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  div.loadArea {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div.areaCards {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 30px;
    flex-wrap: wrap;
    margin: 30px 0 60px 0;
    //
    max-height: 600px;
    height: 100%;
    overflow-y: scroll;
    padding: 10px 10px 20px 10px;
  }

  .total-area {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap-reverse;
  }
  .videos-navigation-area {
    display: flex;
    max-width: 300px;
    width: 100%;
    flex-direction: column;
    gap: 10px;

    p.info {
      margin-bottom: 10px;
    }
  }
  .videos-navigation {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 10px;

    button {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #fff;
      padding: 10px 20px;
      border: none;
      border-radius: 2px;
      background-color: ${({ theme }) => theme.backgroundGray};
      gap: 10px;
      text-transform: capitalize;
      font-weight: bold;
      transition: all ease 0.5s;

      &:hover {
        transition: all ease 0.5s;
        transform: scale(1.025);
      }
    }
  }
  .video-player {
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.backgroundGray};
    max-width: 725px;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    align-items: center;

    h2 {
      margin-bottom: 10px;
      align-self: flex-start;
      max-width: 700px;
      width: 100%;
      text-transform: capitalize;
    }

    iframe {
      max-width: 700px;
      width: 100%;
      height: 400px;
      border: none;
      border-radius: 5px;
      margin-bottom: 10px;
    }

    p.note {
      font-size: 0.9rem;
      font-weight: bold;
      margin-bottom: 30px;
      color: ${({ theme }) => theme.textColor};
      max-width: 700px;
      width: 100%;
    }

    p.video-desc {
      font-size: 16px;
      margin-bottom: 15px;
      color: ${({ theme }) => theme.textColor};
      max-width: 700px;
      width: 100%;
    }
  }

  @media screen and (max-width: 720px) {
    .videos-navigation-area {
      margin-top: 30px !important;
    }
    iframe {
      height: 200px !important;
    }
  }
`;
