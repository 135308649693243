import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 30px 10px;
  margin: 0 auto;
  background: ${({ theme }) => theme.backgroundColor};

  div.title-area {
    display: flex;
    align-items: center;
    gap: 7px;
    margin-bottom: 30px;

    h2 {
      font-size: 1.75rem;
      font-weight: 500;
    }

    span {
      background: ${({ theme }) => theme.primary};
      height: 4px;
      width: 125px;
      border-radius: 2px;
    }
  }

  .inputArea {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    input {
      max-width: 86%;
      width: 100%;
      background: ${({ theme }) => theme.backgroundGray};
      border: 0;
      border-radius: 3px;
      height: 40px;
      padding: 0 10px;
      font-size: 1rem;
      font-weight: bold;
      color: ${({ theme }) => theme.textColor};
      outline: none;
    }

    button {
      height: 40px;
      max-width: 12%;
      width: 100%;
      border: 0;
      background: ${({ theme }) => theme.primary};
      border-radius: 3px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  div.loadArea {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div.areaCards {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 30px;
    flex-wrap: wrap;
    margin: 30px 0 60px 0;
  }

  div.cardSong {
    max-width: 460px;
    width: 100%;
    background: ${({ theme }) => theme.backgroundGray};
    padding: 20px 10px;
    border-radius: 3px;
  }

  div.lineArea {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  h3 {
    font-size: 1.3rem;
    font-weight: 500;
    border-bottom: 1px solid ${({ theme }) => theme.primary};
    padding-bottom: 5px;
    margin-bottom: 15px;
  }

  a {
    background: ${({ theme }) => theme.primary};
    color: #fff;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 3px;
    text-decoration: none;
  }

  p.status {
    font-weight: bold;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 5px;

    svg {
      color: #fff;
      margin-bottom: 2.5px;
      background: #d8355d;
      width: 20px;
      height: 20px;
      border-radius: 12px;
      padding: 2px;
    }
  }

  p.lastDate {
    font-size: 0.75rem;
    font-weight: 500;
    margin: 10px 0 30px 0;
  }

  h6 {
    font-size: 0.9rem;
    margin: 30px 0 10px 0;
  }

  .lineCardsMedia {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 15px;
  }

  /* USERS */
  .usersContent {
    display: flex;
    flex-direction: column;

    .userRow:nth-child(odd) {
      background-color: ${({ theme }) => theme.backgroundColor};
    }

    .userRow:nth-child(even) {
      background-color: ${({ theme }) => theme.backgroundGray};
    }

    .userRow {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 10px;
      border-radius: 4px;

      .avInfo {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 15px;

        img {
          width: 40px;
          height: 40px;
          border-radius: 20px;
          border: 2px solid ${({ theme }) => theme.primary};
          object-fit: cover;
        }
      }

      p {
        font-weight: bold;
        font-size: 1rem;
      }

      .btnsUser {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;

        a {
          background: none;
          padding: none;
          margin: none;
          color: ${({ theme }) => theme.textColor};
          text-decoration: underline;
          font-size: 0.75rem;
          font-weight: 400;
          cursor: pointer;
          transition: all ease 0.5s;
        }

        a:hover {
          color: ${({ theme }) => theme.primary};
          transition: all ease 0.5s;
        }
      }
    }
  }
`;
