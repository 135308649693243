import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 30px 10px;
  margin: 0 auto;
  background: ${({ theme }) => theme.backgroundColor};

  div.title-area {
    display: flex;
    align-items: center;
    gap: 7px;
    margin-bottom: 30px;

    h2 {
      font-size: 1.75rem;
      font-weight: 500;
    }

    span {
      background: ${({ theme }) => theme.primary};
      height: 4px;
      width: 125px;
      border-radius: 2px;
    }
  }

  .inputArea {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    input {
      max-width: 86%;
      width: 100%;
      background: ${({ theme }) => theme.backgroundGray};
      border: 0;
      border-radius: 3px;
      height: 40px;
      padding: 0 10px;
      font-size: 1rem;
      font-weight: bold;
      color: ${({ theme }) => theme.textColor};
      outline: none;
    }

    button {
      height: 40px;
      max-width: 12%;
      width: 100%;
      border: 0;
      background: ${({ theme }) => theme.primary};
      border-radius: 3px;
      color: ${({ theme }) => theme.textColor};
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  div.loadArea {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div.areaCards {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 30px;
    flex-wrap: wrap;
    margin: 30px 0 60px 0;
  }

  div.avatarCardInfo {
    display: flex;
    flex-direction: column;
    max-width: 760px;
    width: 100%;
    background: ${({ theme }) => theme.backgroundColor};
    box-shadow: 1px 1px 15px ${({ theme }) => theme.backgroundGray};
    border: 5px solid ${({ theme }) => theme.backgroundGray};
    border-radius: 4px;

    div.cardHeader {
      display: flex;
      gap: 10px;

      img {
        width: 150px;
      }
    }

    div.cardHeaderContent {
      background: ${({ theme }) => theme.backgroundGray};
      padding: 10px;
      width: 100%;
    }

    div.cardHeaderContent div.headerContentFirst {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      border-bottom: 1px solid ${({ theme }) => theme.primary};
      padding-bottom: 5px;

      div.headerContentName h3 {
        font-size: 1.1rem;
        font-weight: 500;
      }
      div.headerContentName span {
        font-size: 0.9rem;
        font-weight: bold;
        color: ${({ theme }) => theme.textColor};
      }

      span.idArea {
        background: ${({ theme }) => theme.backgroundColor};
        margin-left: 7px;
        padding: 0 5px;
        font-weight: 500 !important;
      }

      div.headerContentBadges {
        display: flex;
        align-items: center;
        gap: 5px;

        span.ap {
          font-weight: bold;
          color: #fff;
          background: #d8355d;
          border-radius: 3px;
          padding: 1px 5px;
          font-size: 0.7rem;
        }
        span.age {
          font-weight: bold;
          color: #fff;
          background: #0eca6e;
          border-radius: 3px;
          padding: 1px 5px;
          font-size: 0.7rem;
        }
        span.vip {
          font-weight: bold;
          color: #fff;
          background: #f49c14;
          border-radius: 3px;
          padding: 1px 5px;
          font-size: 0.7rem;
        }
        span.host {
          font-weight: bold;
          color: #fff;
          background: #e0491b;
          border-radius: 3px;
          padding: 1px 5px;
          font-size: 0.7rem;
        }
        span.creator {
          font-weight: bold;
          color: #fff;
          background: #a239ff;
          border-radius: 3px;
          padding: 1px 5px;
          font-size: 0.7rem;
        }
        span.nft {
          font-weight: bold;
          color: #fff;
          background: #b07602;
          border-radius: 3px;
          padding: 1px 5px;
          font-size: 0.7rem;
        }
        span.staff {
          font-weight: bold;
          color: #fff;
          background: #009ee3;
          border-radius: 3px;
          padding: 1px 5px;
          font-size: 0.7rem;
        }
        span.greeter {
          font-weight: bold;
          color: #fff;
          background: #009ee3;
          border-radius: 3px;
          padding: 1px 5px;
          font-size: 0.7rem;
        }
        span.marriage {
          font-weight: bold;
          color: #fff;
          background: #8c20ca;
          border-radius: 3px;
          padding: 1px 5px;
          font-size: 0.7rem;
        }
      }
    }

    div.headerContentSecond {
      padding: 10px 0;
    }

    div.headerContentInfo {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;
    }

    div.headerContentInfoStatus {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      p.status {
        font-weight: bold;
        margin-bottom: 10px;
        display: flex;
        gap: 5px;
        align-items: center;

        svg {
          color: #fff;
          margin-bottom: 2.5px;
          background: #d8355d;
          width: 20px;
          height: 20px;
          border-radius: 12px;
          padding: 2px;
        }
      }
    }

    div.dateArea {
      margin: -10px 0 10px 0;

      p {
        font-size: 0.75rem;
        font-weight: 500;
      }
    }

    div.currentRoom {
      display: flex;
      align-items: flex-start;
      gap: 10px;

      img {
        width: 100px;
        border-radius: 2px;
      }

      p {
        font-weight: 500;
        font-size: 1.1rem;
      }

      h5 {
        color: ${({ theme }) => theme.textColor};
        font-size: 0.75rem;
      }

      a {
        background: #f49c14;
        color: #fff;
        font-weight: bold;
        text-decoration: none;
        padding: 2px 7px;
        border-radius: 3px;
        font-size: 0.75rem;
      }
    }

    div.cardBody {
      background: ${({ theme }) => theme.backgroundColor};
      width: 100%;
      border-radius: 2px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;

      div.leftCardBody {
        padding: 20px 10px;
        max-width: 58%;
        width: 100%;
      }

      div.rightCardBody {
        padding: 20px 10px;
        max-width: 38%;
        width: 100%;
        border: 2px solid #f49c14;
        border-radius: 3px;
      }

      h4 {
        color: ${({ theme }) => theme.textColor};
        border-radius: 3px;
        font-size: 1rem;
        margin-bottom: 5px;
      }

      p {
        font-size: 0.9rem;
        color: ${({ theme }) => theme.textColor};
        margin-bottom: 10px;
      }

      ul {
        list-style: none;
      }

      ul li {
        font-size: 0.9rem;
        margin-bottom: 5px;
      }
    }
  }

  div.footerArea {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    border-top: 2px solid ${({ theme }) => theme.backgroundGray};

    p {
      font-weight: 500;
    }

    a {
      background: #f49c14;
      color: #fff;
      font-weight: bold;
      padding: 2px 5px;
      border-radius: 3px;
      font-size: 0.7rem;
      text-decoration: none;
    }
  }

  div.lineDivisor {
    height: 2px;
    width: 100%;
    border-radius: 1px;
    background: ${({ theme }) => theme.backgroundGray};
    margin: 10px 0;
  }

  @media screen and (max-width: 600px) {
    div.cardHeader {
      flex-wrap: wrap !important;
      gap: 10px !important;
      background: ${({ theme }) => theme.backgroundGray};

      img {
        width: 100% !important;
        max-width: 390px !important;
        border-radius: 5px !important;
        margin: 10px auto 0 auto !important;
      }
    }

    div.cardHeaderContent {
      flex-wrap: wrap !important;
      gap: 10px !important;
    }

    div.headerContentFirst {
      flex-wrap: wrap !important;
      gap: 10px !important;
    }

    div.headerContentBadges {
      flex-wrap: wrap !important;
      gap: 10px !important;
    }

    div.cardBody {
      flex-wrap: wrap !important;
      gap: 10px !important;
    }

    div.leftCardBody,
    div.rightCardBody {
      width: 100% !important;
      max-width: 100% !important;
    }
  }
`;
