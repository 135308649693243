import { useContext, useEffect, useState } from 'react';
import { FiLoader } from 'react-icons/fi';

import { Container } from './ProtectionUserStyles';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { api } from '../../services/apiClient';
import { parseCookies } from 'nookies';
import { AuthContext } from '../../contexts/AuthContexts';
import { useNavigate } from 'react-router-dom';

export function ProtectionUser() {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();

  const [load, setLoad] = useState(false);
  const [name, setName] = useState('');
  const [updatedName, setUpdatedName] = useState('');
  const [isDeleted, setIsDeleted] = useState(false);
  const [isPremium, setIsPremium] = useState(false);
  const [isUserProtection, setIsUserProtection] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    verifyPremium();
  }, []);

  async function getCurrentProtection() {
    const { '@searchvu.token': token } = parseCookies();

    const res = await api.get('/getUserProtection', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (res?.data?.users?.username) {
      setName(res?.data?.users?.username);
      setIsUserProtection(true);
    }
  }

  async function verifyPremium() {
    const isPremium = user.subscriptions?.status === 'active' ? true : false;

    if (!isPremium) {
      navigate('/plans', { state: { premiumFunctionality: true } });
      return;
    }

    setIsPremium(isPremium);
    if (isPremium) {
      getCurrentProtection();
    }
  }

  function isValidUsername(username) {
    const regex = /^[a-zA-Z0-9][a-zA-Z0-9._-]{0,29}$/;
    return regex.test(username);
  }

  function handleNameChange(e) {
    const newValue = e.target.value;
    if (newValue === '' || isValidUsername(newValue)) {
      setName(newValue);
    }
  }

  async function handleInsertUserProtection() {
    if (!name) {
      toast.error(t('required_user_id'));
      return;
    }

    const namelowercase = name.toLowerCase();

    if (namelowercase.toLowerCase() === user.name) {
      toast.error(t('hidden_equals_btn'));
      return;
    }

    setLoad(true);

    try {
      const { '@searchvu.token': token } = parseCookies();

      await api
        .post(
          '/createUserProtection',
          {
            username: name,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          setUpdatedName(name);
          toast.success(t('success_update'));
        });
    } catch (error) {
      toast.error(t('hidden_error_btn'));
      // //console.log(error);
    } finally {
      setLoad(false);
    }
  }

  async function handleDeleteUserProtection() {
    if (!name && !updatedName && !isDeleted) return;

    const currentName = name;

    setLoad(true);

    try {
      const { '@searchvu.token': token } = parseCookies();

      await api
        .post(
          '/deleteUserProtection',
          {
            username: updatedName || currentName,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          setName('');
          setUpdatedName('');
          setIsDeleted(true);
          setIsUserProtection(false);
          toast.success(t('success_update'));
        });
    } catch (error) {
      toast.error(t('hidden_error_btn'));
      // //console.log(error);
    } finally {
      setLoad(false);
    }
  }

  if (!isPremium) return <></>;

  return (
    <Container>
      <div className="title-area">
        <h2>{t('hidden_title_card')}</h2>
        <span></span>
      </div>

      <div className="columnArea">
        <div className="inputArea">
          <p id="subtitle_protection">{t('hidden_title_protection')}</p>

          <p id="subtitle_protection">{t('hidden_description_protection')}</p>

          <input
            type="text"
            value={name}
            onChange={handleNameChange}
            placeholder={t('username')}
          />
          {(name || updatedName) && !isDeleted && (
            <>
              <a
                href={`https://avatars.imvu.com/${updatedName || name}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Classic: @{updatedName || name}
              </a>
              <a
                href={`https://go.imvu.com/av/${updatedName || name}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Next: @{updatedName || name}
              </a>
            </>
          )}

          <div className="btnArea">
            <button
              onClick={() => handleInsertUserProtection()}
              disabled={load}
            >
              {load ? <FiLoader size={24} /> : t('hidden_btn_save')}
            </button>
            {(isUserProtection || updatedName) && (
              <button
                onClick={() => handleDeleteUserProtection()}
                disabled={load}
              >
                {load ? <FiLoader size={24} /> : t('hidden_btn_delete')}
              </button>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
}
