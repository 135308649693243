import { useContext, useEffect, useState } from 'react';
import { api } from '../../services/apiClient';
import toast from 'react-hot-toast';
import { FiLoader, FiSearch } from 'react-icons/fi';

import { Container } from './CurrentOutfitStyles';
import { useTranslation } from 'react-i18next';
import { CardItem, CardItemProps } from '../../components/CardItem/CardItem';
import { useNavigate } from 'react-router-dom';
import { parseCookies } from 'nookies';
import { AuthContext } from '../../contexts/AuthContexts';

export function CurrentOutfit() {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);

  const [search, setSearch] = useState('');
  const [load, setLoad] = useState(false);
  const [image, setImage] = useState('');
  const [products, setProducts] = useState<CardItemProps[]>([]);
  const [isPremium, setIsPremium] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    verifyPremium();
  }, []);

  async function verifyPremium() {
    // const { '@searchvu.token': token } = parseCookies();

    // const res = await api.get('/users/check', {
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //   },
    // });

    // const isPremium = res.data?.status === 'active' ? true : false;

    const isPremium = user.subscriptions?.status === 'active' ? true : false;

    if (!isPremium) {
      navigate('/plans', { state: { premiumFunctionality: true } });
    }

    setIsPremium(isPremium);
  }

  async function getUserProfileOutfit() {
    if (!search) {
      toast.error(t('required_user_id'));
      return;
    }

    setProducts([]);

    setLoad(true);

    try {
      const { '@searchvu.token': token } = parseCookies();

      const res = await api.post(
        '/currentOutfit',
        { username: search },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const products = await res.data.products.flatMap((subArray) => subArray);
      setImage(res.data.avatar_image);
      setProducts(products);
    } catch (error) {
      // //console.log('ROOM FURNITURE: ', error);
    } finally {
      setLoad(false);
    }
  }

  if (!isPremium) return <></>;

  return (
    <Container>
      <div className="title-area">
        <h2>{t('service_current_outift_title')}</h2>
        <span></span>
      </div>

      <div className="inputArea">
        <input
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder={t('search_user_id')}
        />
        <button onClick={() => getUserProfileOutfit()} disabled={load}>
          {load ? <FiLoader size={24} /> : <FiSearch size={24} />}
        </button>
      </div>
      {load && (
        <div className="loadArea">
          <FiLoader size={42} color="#f49c14" />
        </div>
      )}
      {products.length >= 1 && (
        <div className="areaProfile">
          {image && <img src={image} alt="profile" className="profile" />}
          <div className="areaCards">
            {products?.map((item, index) => (
              <CardItem key={index} {...item} />
            ))}
          </div>
        </div>
      )}
    </Container>
  );
}
