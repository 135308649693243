import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 30px 10px;
  margin: 0 auto;
  background: ${({ theme }) => theme.backgroundColor};

  div.title-area {
    display: flex;
    align-items: center;
    gap: 7px;
    margin-bottom: 30px;

    h2 {
      font-size: 1.75rem;
      font-weight: 500;
    }

    span {
      background: ${({ theme }) => theme.primary};
      height: 4px;
      width: 125px;
      border-radius: 2px;
    }
  }

  select {
    max-width: 225px;
    width: 100%;
    background: ${({ theme }) => theme.backgroundGray};
    border: 0;
    border-radius: 3px;
    height: 40px;
    padding: 0 10px;
    font-size: 1rem;
    font-weight: bold;
    color: ${({ theme }) => theme.textColor};
    outline: none;
    margin: 10px 0 30px 0;
  }

  h3.alertSubscription {
    font-size: 0.9rem;
    background: ${({ theme }) => theme.alert};
    font-weight: bold;
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    margin-bottom: 15px;
  }

  p.description {
    max-width: 720px;
    margin-top: 5px;
    margin-bottom: 30px;
    font-size: 0.9rem;
  }

  .disclaimer {
    font-size: 0.75rem;
    font-weight: bold;
    color: ${({ theme }) => theme.textColor};
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 875px;
    width: 100%;
    text-align: center;
    margin: 60px auto 30px auto;
  }

  .plansArea {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 30px;
    gap: 30px;

    button {
      height: 40px;
      width: 100%;
      border: 0;
      background: ${({ theme }) => theme.primary};
      border-radius: 3px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      margin-top: 10px;
      font-weight: bold;
    }
    button.changeBtn {
      background: #666;
    }
  }

  div.loadArea {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div.card {
    max-width: 375px;
    /* max-width: 340px; */
    width: 100%;
    border-radius: 5px;
    background: ${({ theme }) => theme.backgroundGray};
    padding: 30px;
    /* padding: 30px 15px; */

    h3 {
      text-align: center;
      font-size: 1.75rem;
      margin-bottom: 20px;
    }

    h3.premium {
      color: ${({ theme }) => theme.primary};
    }

    ul li {
      list-style: none;
      border-bottom: 1px solid ${({ theme }) => theme.backgroundColor};
      padding: 5px 0 10px 0;
      text-transform: capitalize;
    }

    div.priceArea {
      display: flex;
      flex-direction: column;
      margin-top: 15px;
      text-align: center;

      p {
        font-size: 0.9rem;
        font-weight: 500;
        margin-bottom: -15px;

        span {
          color: ${({ theme }) => theme.error};
          text-decoration: line-through;
        }
      }

      span.total {
        font-weight: bold;
        font-size: 2.5rem;
        margin-top: 10px;
        color: ${({ theme }) => theme.success};
      }
    }
  }

  span.obs {
    display: block;
    font-size: 0.7rem;
    font-weight: bold;
    text-transform: initial;
  }
`;
