import { useState } from 'react';
import { FiSearch, FiLoader } from 'react-icons/fi';
import toast from 'react-hot-toast';

import { Container } from './GetRoomStyles';

import { api } from '../../services/apiClient';

import { CardItem, CardItemProps } from '../../components/CardItem/CardItem';
import { useTranslation } from 'react-i18next';
import { parseCookies } from 'nookies';

export function GetRoom() {
  const { t } = useTranslation();

  const [products, setProducts] = useState<CardItemProps[]>([]);
  //
  const [search, setSearch] = useState('');
  const [load, setLoad] = useState(false);

  async function getRoomFurniture() {
    if (!search) {
      toast.error(t('required_room_id'));
      return;
    }

    setProducts([]);

    setLoad(true);

    try {
      let verifiedId = search;

      if (verifiedId.includes('https://go.imvu.com/chat/room-')) {
        verifiedId = verifiedId.split('https://go.imvu.com/chat/room-')[1];
      }

      const { '@searchvu.token': token } = parseCookies();

      const res = await api.post(
        '/productRoom',
        { roomId: verifiedId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setProducts(res.data.room);
      //
    } catch (error) {
      // //console.log('ROOM FURNITURE: ', error);
    } finally {
      setLoad(false);
    }
  }

  return (
    <Container>
      <div className="title-area">
        <h2>{t('service_getroom_title')}</h2>
        <span></span>
      </div>

      <div className="inputArea">
        <input
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder={t('search_room_id')}
        />
        <button onClick={() => getRoomFurniture()} disabled={load}>
          {load ? <FiLoader size={24} /> : <FiSearch size={24} />}
        </button>
      </div>

      {load && (
        <div className="loadArea">
          <FiLoader size={42} color="#f49c14" />
        </div>
      )}
      {products.length >= 1 && (
        <div className="areaCards">
          {products?.map((item, index) => (
            <CardItem key={index} {...item} />
          ))}
        </div>
      )}
    </Container>
  );
}
