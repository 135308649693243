import { useContext, useState } from 'react';
import { FiLoader } from 'react-icons/fi';

import { useTranslation } from 'react-i18next';

import { Container } from './SignInStyles';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContexts';
import toast from 'react-hot-toast';
import { api } from '../../services/apiClient';

export function SignIn() {
  const { signIn, loadingAuth } = useContext(AuthContext);
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  async function sendLoginForm(event) {
    event.preventDefault();
    if (!email || !password) {
      toast.error(t('auth_alert_required'));
      return;
    }

    await signIn({ email: email.trim(), password });
  }

  async function handleForgotPassword() {
    const currentEmail = window.prompt(`${t('forgot_email_input')}:`);

    if (currentEmail) {
      try {
        await api.post('/forgot-password', { email: currentEmail });
        toast.success(t('forgot_email_success'));
      } catch (error) {
        toast.error(t('forgot_email_error'));
      }
    }
  }

  return (
    <Container>
      <div className="title-area">
        <h2>{t('signin_welcome')}</h2>
        <span></span>
      </div>

      <form onSubmit={sendLoginForm} className="inputArea">
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder={t('signin_email')}
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder={t('signin_password')}
        />
        <button
          id="btn"
          // onClick={() => sendLoginForm()}
          type="submit"
          disabled={loadingAuth}
        >
          {loadingAuth ? <FiLoader size={24} /> : t('signin_button_access')}
        </button>
        <div className="btnArea">
          <Link to="/sign-up">{t('signin_create_free_account')}</Link>
          <button id="btnReset" onClick={handleForgotPassword}>
            {t('forget_email_btn')}
          </button>
        </div>
      </form>
    </Container>
  );
}
